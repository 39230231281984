import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopSeasonItem {
	season_type_id: string;
	name: string;
	public_reservation_end: string;
	date_end: string;
	resource_ids: string[];
}

@Injectable()
export class ShopSeasonListService extends EnkoraFetcher<ShopSeasonItem[]> {

	public season_map: { [season_id: string]: ShopSeasonItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ResourceSeasons' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterByResources(resource_ids: string[] = []): Observable<ShopSeasonItem[]>
	{
		return this.getLazy().pipe(
			first(),
			map(seasons => {
				const resource_map = Helpers.arrayToMap(resource_ids);
				const filtered_list: ShopSeasonItem[] = [];

				each(seasons, season => {
					if (!season.resource_ids) return;

					if (!find(season.resource_ids, id => resource_map(id))) return;

					filtered_list.push(season);
				});
				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopSeasonItem[]): ShopSeasonItem[]
	{
		this.season_map = {};
		each(reply, item => {
			this.season_map[item.season_type_id] = item;
		});
		return reply;
	}
}
