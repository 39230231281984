import { Injectable } from '@angular/core';
import { each } from 'lodash';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';

import { AuthenticationService, CallParameter, CallService, Constants, EnkoraFetcher, Helpers, ParameterService } from 'shared';

import { Instructor } from '../../reservation/instructors-edit/instructors-edit.component';
import { ReservationEvent } from '../../reservation/services/interfaces';
import { AccessibilityType, CourseItem } from '../main/courses/course-item/course-item.component';

import { ShopServerTimeService } from './server-time.service';

declare const Enkora: { tt: (a: string) => string };

export interface CourseFareProduct {
	access_right_template_id: number;
	campaign_id: number;
	campaign_name: string;
	customer_group_id: number;
	external_id: string;
	external_id_2: number;
	fare_product_id: number;
	fare_product_type_id: number;
	is_account_created: boolean;
	is_price_per_person: boolean;
	max_age: number;
	min_age: number;
	minimum_number_of_participants: number;
	name: string;
	organization_id: number;
	original_price: number;
	price: number;
	price_comment: string;
	price_type_id: number;
	tag_ids: any[];
	timeslot_duration_minutes: number;
	timetable_id: number;
	vat: number;
}

export interface EventGroup {
	access_area: string;
	access_area_id: number;
	achievements: any;
	capacity: number;
	description: string;
	description_form: string;
	description_long: string;
	end: string;
	event_number: number;
	fare_products: CourseFareProduct[];
	free: number;
	free_queue: number;
	instructors: Instructor[];
	instructors_ids: number[];
	is_campaign_code_exists: boolean;
	is_course: boolean;
	is_dob_required_for_reservation: boolean;
	is_reservable: boolean;
	is_reserve_remaining: boolean;
	location: string;
	location_address: string;
	location_id: number;
	name: string;
	public_reservation_end: string;
	public_reservation_start: string;
	public_visibility_end: string;
	public_visibility_start: string;
	queue: number;
	queue_capacity: number;
	region_id: number;
	region_name: string;
	required_tag_group_ids: number[];
	reservation_event_group_id: number;
	reservation_events: ReservationEvent[];
	reservation_group_id: number;
	reservation_group_name: string;
	reserved: boolean;
	resource: any;
	sale_group_id: number;
	service_id: number;
	start: string;
	tag_group_id: number;
	tag_id: number;
	tag_ids: number | string;
	tag_name: string;
	tags: {
		name: string;
		tag_id: number;
	}[];
	weekdays: {
		days: string;
		times: string;
		weekdays: number[];
	}[];
	is_reservable_error?: boolean;
}

@Injectable()
export class ShopCourseEventGroupsService extends EnkoraFetcher<EventGroup[]> {
	public show_participants = false;
	public hide_free_places_until = 0;
	public show_group_size = false;
	public show_separate_slots = false;
	public event_groups: EventGroup[] = [];

	protected params: CallParameter[] = [{ name : 'reservation2/getR2EventGroups' }];

	constructor(
		call: CallService,
		private param_service: ParameterService,
		public auth: AuthenticationService,
		private serverTimeService: ShopServerTimeService,
	) {
		super(call);

		combineLatest([
			this.param_service.getValue('show participants to all', Constants.NM_EN_RESERVATIONS),
			this.param_service.getValue('hide free places in courses until x free places remain', Constants.NM_EN_RESERVATIONS),
			this.param_service.getValue('show group size to all', Constants.NM_EN_RESERVATIONS),
			this.param_service.getValue('show separate slots for availability', Constants.NM_EN_RESERVATIONS),
			this.auth.get(),
		]).subscribe(res => {
			this.show_participants = !!res[0];
			this.hide_free_places_until = +res[1];
			this.show_group_size = !!res[2];
			this.show_separate_slots = !!res[3];

			if (!this.show_participants && res[4]) {
				this.show_participants = true;
			}
		});
	}

	public processEventGroups(event_groups: EventGroup[]): CourseItem[]
	{
		const result: CourseItem[] = [];
		each(event_groups, event_group => {
			const weekdays_array = event_group.weekdays.map(weekday => weekday.weekdays);
			const weekdays = this.flattenWeekdays(weekdays_array);
			const weekdays_str = this.getWeekdayNamesShort(weekdays).join(', ');

			result.push({
				title                               : event_group.name,
				description                         : event_group.description,
				description_long                    : event_group.description_long,
				location                            : event_group.location,
				location_id                         : event_group.location_id,
				date                                : `${Helpers.removeLeadingZero(moment(event_group.start).format('DD.MM'))}. -
																	${Helpers.removeLeadingZero(moment(event_group.end).format('DD.MM'))}.
																	(${Enkora.tt(weekdays_str)})`,
				time                                : Enkora.tt('at')
																	+ ' ' + moment(event_group.start).format('HH:mm')
																	+ ' - ' + moment(event_group.end).format('HH:mm'),
				price                               : event_group.fare_products[0]?.price,
				original_price                      : event_group.fare_products[0]?.original_price,
				price_range                         : this.getPriceRange(event_group.fare_products),
				start                               : event_group.start,
				end                                 : event_group.end,
				weekdays                            : weekdays,
				accessibility                       : this.getAccessibilityType(event_group),
				public_reservation_start            : event_group.public_reservation_start,
				public_reservation_start_formatted  : `${Helpers.removeLeadingZero(moment(event_group.public_reservation_start).format('DD.MM'))}.
																	${Helpers.removeLeadingZero(moment(event_group.public_reservation_start).format('HH:mm'))}`,
				capacity                            : String(event_group.free) + '/' + String(event_group.capacity),
				free                                : String(event_group.free),
				free_queue                          : String(event_group.free_queue),
				fare_product_id                     : event_group.fare_products[0]?.fare_product_id,
				fare_products                       : event_group.fare_products,
				related_reservation_event_group_id  : event_group.reservation_event_group_id,
				amount                              : 1,
				reservation_events                  : event_group.reservation_events,
				reservations                        : null,
				max_age                             : event_group.fare_products[0]?.max_age,
				min_age                             : event_group.fare_products[0]?.min_age,
				tag_ids                             : event_group.tag_ids
					? String(event_group.tag_ids).split(',')
					: [],
				tags                                : event_group.tags,
			});
		});

		return result;
	}

	private getPriceRange(fare_products: CourseFareProduct[]): number[]
	{
		let min_price = 0;
		let max_price = 0;

		each(fare_products, fp => {
			const price = +fp.price;
			if (!min_price || price < min_price) {
				min_price = price;
			}

			if (price > max_price) {
				max_price = price;
			}
		});

		return [min_price, max_price];
	}

	private flattenWeekdays(weekdays: number[][]): number[]
	{
		const weekday_map: { [key: number]: boolean } = {};
		each(weekdays, weekday_array => {
			each(weekday_array, weekday => {
				weekday_map[weekday] = true;
			});
		});
		return Object.keys(weekday_map).map(weekday => +weekday);
	}

	private getWeekdayNamesShort(weekdays: number[]): string[]
	{
		return weekdays.map(weekday => {
			switch (weekday) {
				case 0: { return 'Mon'; }
				case 1: { return 'Tue'; }
				case 2: { return 'Wed'; }
				case 3: { return 'Thu'; }
				case 4: { return 'Fri'; }
				case 5: { return 'Sat'; }
				case 6: { return 'Sun'; }
			}
		});
	}

	public getAccessibilityType(event_group: EventGroup): AccessibilityType
	{
		const server_time = this.serverTimeService.getServerTime();
		const reservable_in_future = event_group.public_reservation_start
			&& !event_group.is_reservable_error
			&& moment(event_group.public_reservation_start).diff(server_time, 'months') <= 6
			&& (!event_group.public_reservation_end || moment(event_group.public_reservation_end).isAfter(moment()));

		if (!event_group.is_reservable) {
			if (reservable_in_future) {
				return AccessibilityType.ReservableInFuture;
			} else {
				return AccessibilityType.NotReservable;
			}
		} else {
			return this.getAccessibilityLabel(event_group);
		}
	}

	public getAccessibilityLabel(event_group: EventGroup): AccessibilityType
	{
		if (event_group.free) {
			if (this.show_participants && (!this.hide_free_places_until || this.hide_free_places_until >= event_group.free)) {
				if (this.show_group_size) {
					if (this.show_separate_slots) {
						return AccessibilityType.Free;
					} else {
						return AccessibilityType.FreeWithCapacity;
					}
				} else {
					return AccessibilityType.FreePlaces;
				}
			} else {
				if (event_group.free > 5) {
					return AccessibilityType.ManyAvailable;
				} else if (event_group.free > 0) {
					return AccessibilityType.FewAvailable;
				}
			}
		} else {
			if (event_group.free_queue > 0) {
				if (this.show_participants) {
					return AccessibilityType.FreeQueue;
				} else {
					return AccessibilityType.QueueSpace;
				}
			} else {
				return AccessibilityType.Full;
			}
		}
	}
}
