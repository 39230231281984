import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Constants, Logger } from 'shared';

import { ServiceModule } from '../../interfaces';

@Component({
	selector    : 'cta-ta-form-ramismart-modules',
	templateUrl : './ramismart-modules.form.html',
	styleUrls   : ['./ramismart-modules.form.scss']
})
export class RamismartModulesForm implements OnInit {
	public modules: ServiceModule[] = [];
	public modulesForm: FormArray;
	public disabled_access_area_daily_reset = false;
	public previous_ta_module_state = false;
	public readonly TA_MODULE_ID = 1000005;
	@Output() onModulesChange = new EventEmitter();

	constructor()
	{
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this.modulesForm = <FormArray>value.get('modules');
		const tax_reporting_index = this.modulesForm.controls.findIndex(m => m.value.module_id == Constants.NM_CTA_TAX_REPORTING);
		if (tax_reporting_index >= 0) {
			this.modulesForm.controls[tax_reporting_index].patchValue({'name' : 'Tax Reporting'});
			this.modulesForm.controls.push(this.modulesForm.controls.splice(tax_reporting_index, 1)[0]);
		}
		this._inputForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {

			const index = data.modules.findIndex(m => m.module_id == this.TA_MODULE_ID);
			if (index != -1) {
				const isActive = data.modules[index].is_active;
				this.disabled_access_area_daily_reset = !isActive;
				if (!isActive) {
					data.modules[index].is_access_area_daily_reset = false;
				}
				if (!this.previous_ta_module_state && isActive) {
					this.modulesForm.controls[index].patchValue({'is_access_area_daily_reset' : true});
				}
				this.previous_ta_module_state = isActive;
			}

			this.onModulesChange.emit({ status : this._inputForm.status, data });
		});
		this.onModulesChange.emit({ status : this._inputForm.status, data : this._inputForm.value });
		this.previous_ta_module_state = this._inputForm.value.modules.filter(m => m.module_id == this.TA_MODULE_ID)[0]?.is_active;
	}

	ngOnInit(): void
	{
	}

	resetForm()
	{
	}
}
