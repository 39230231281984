<div class="container new-loading-404">
	<h1 class="inline-block">404</h1>
	<h1 class="inline-block"> - </h1>
	<h1 class="inline-block" tt>Not found</h1>
	<p tt>Page was not found. Please try another address/page.</p>
	<button (click)="$event.preventDefault(); back()" tt>Go back</button>
</div>

<div class="container old-loading-404">
	<h1>
		404
		<span tt>Not found</span>
	</h1>
	<p tt>Page was not found. Please try another address/page.</p>
</div>
