import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { first } from 'rxjs/operators';

import { CallService, Constants } from 'shared';

import { Token } from '../../../../shared';
import { TaTokenTypesService } from '../../services/ta-token-types.service';

@Component({
	selector    : 'cta-ta-token-list',
	templateUrl : './list.component.html',
	styleUrls   : ['./list.component.scss']
})
export class TokenListComponent implements OnInit {

	@Input() public can_edit = false;
	@Output() tokensChange = new EventEmitter();

	public types: string[] = [];
	public token_prefixes = Constants.TOKEN_PREFIXES;

	constructor(private call: CallService,
	            token_types: TaTokenTypesService)
	{
		token_types.get().pipe(first()).subscribe(types => this.types = types);
	}

	private _tokens: Token[] = [];

	get tokens(): Token[]
	{
		return this._tokens;
	}

	@Input() set tokens(tokens: Token[])
	{
		this._tokens = tokens;
	}

	ngOnInit(): void
	{
	}

	public deleteToken(token: Token, index: number): void
	{
		const tokens = cloneDeep(this.tokens);

		tokens.splice(index, 1);
		this.tokens = tokens;
		this.tokensChange.emit(tokens);
	}
}
