import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { findIndex } from 'lodash';
import { debounceTime } from 'rxjs/operators';

import { Logger } from 'shared';

import { TaDataHelper, TaFormGroupGeneratorService } from '../../services';
import { TaContact } from '../../interfaces';

@Component({
	selector    : 'cta-ta-form-ramismart-users',
	templateUrl : './ramismart-users.form.html',
	styleUrls   : ['./ramismart-users.form.scss']
})
export class RamismartUsersForm implements OnInit {
	public newContactForm: FormGroup;
	@Output() onSiteDataChange = new EventEmitter();

	constructor(private formGenerator: TaFormGroupGeneratorService)
	{
	}

	private _inputForm: FormArray;

	get inputForm(): FormArray
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormArray)
	{
		this._inputForm = value;

		if (!value) return;

		this._inputForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			Logger.log('Site reactive form update', data);
			this.onSiteDataChange.emit({ status : this._inputForm.status, data });
		});

		this.onSiteDataChange.emit({ status : this._inputForm.status, data : this._inputForm.getRawValue() });

		this.resetNewContactForm();
	}

	ngOnInit(): void
	{
	}

	resetNewContactForm(): void
	{
		this.newContactForm = this.formGenerator.initContact(TaDataHelper.emptyPerson, {
			user_id : { validators : Validators.required }
		});
	}

	addNewContact(): void
	{
		this._inputForm.push(this.newContactForm);

		this.resetNewContactForm();
	}

	removeContact(contact: TaContact): void
	{
		const index = findIndex(this._inputForm.value, (c: any) => contact.account_id == c.account_id);
		this._inputForm.removeAt(index);
	}
}
