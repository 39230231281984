/* eslint-disable */
import { ElementRef, QueryList, SimpleChanges } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { deburr, each, find, isArray, isEqual, isNil, map, remove, sortBy } from 'lodash';
import * as moment from 'moment';
import { Moment, unitOfTime } from 'moment';

import { CalendarLocale } from './calendar-locale.interface';
import { User } from './user.interface';
import { ShopCustomField } from '../../shop/services/shop.interface';
import { DefaultUrlSerializer } from '@angular/router';

declare let window: Window;
declare const Enkora: { tt: (a: string) => string };

export class Helpers {

	public static userFullDateFormat = 'DD.MM.YYYY HH:mm';
	public static userDateFormat = 'DD.MM.YYYY';
	public static timeFormat = 'HH:mm';

	public static serverFullDateFormat = 'YYYY-MM-DD HH:mm';
	public static serverDateFormat = 'YYYY-MM-DD';

	public static now = moment();

	public static safeToString(input: string | null | undefined | number): string
	{
		return ((input || '') + '').trim();
	}

	public static reportLinks(report: string, params: { [key: string]: any }): { pdf: string, xls: string }
	{
		let url = 'clear_values=1';

		each(params, (param, key) => {
			if (isArray(param)) {
				each(param, (item, index) => {
					url += '&values[' + key + '][' + index + ']=' + item;
				});
			} else {
				url += '&' + (key == 'page_length' ? 'params' : 'values') + '[' + key + ']=' + param;
			}
		});

		return {
			pdf : 'reports/' + report + '/pdf/?' + url,
			xls : 'reports/' + report + '/xlsx/?' + url
		};
	}

	public static arrayToSimpleMap<T>(someArray: T[], empty_is_true = false): (any) => boolean
	{
		return this.arrayToMap<T>(someArray, null, false, empty_is_true);
	}

	public static numberToStringArray(numbers: number[]): string[]
	{
		return map(numbers, value => `${value}`);
	}

	public static arrayToMap<T>(someArray: T[], field: keyof T = null, need_value = false, empty_is_true = true): (any) => boolean
	{
		if (!someArray || !someArray.length) return empty_is_true ? () => true : () => false;

		let getId = (value) => value;
		if (field) getId = (value) => value[field];

		let getValue: (any) => boolean = () => true;
		if (need_value) getValue = (value) => value;

		const arrayMap = {};
		each(someArray, el => {
			arrayMap[getId(el)] = getValue(el);
		});

		return (index) => arrayMap[index];
	}

	public static reload(): void
	{
		window.location.reload();
	}

	public static getPluralWord(quantity: number, word: string, suffix: string): string
	{
		if (Math.abs(quantity) === 1) {
			return word;
		}
		return word + suffix;
	}

	// TODO: this is stupid hack that was made by somebody for no reason, should be completely erased from this world!
	// TODO: addressing any element of input field directly is non-angular way and antipattern, for me hard to change atm
	public static areInputsValid(inputs: QueryList<ElementRef>): boolean
	{
		if (!isNil(inputs)) {
			return inputs.toArray().every(input => input.nativeElement.checkValidity());
		}
		return false;
	}

	// TODO: this is was copy paste from source bellow I presume, so should be polished and moved to understandable TS
	public static scrollDataTarget(target: string): void
	{
		// I guess this is source for the code bellow: https://stackoverflow.com/a/10063405/5851804

		// Scroll inner functions for smooth scrolling
		// Rewritten from Codeberry's ES5 to TypeScript

		const element = document.querySelector('[data-scroll-target="' + target + '"]') as HTMLElement;
		if (element) {
			this.smoothScroll(element);
		}
	}

	// TODO: this is was copy paste from source bellow I presume, so should be polished and moved to understandable TS
	public static smoothScroll(o: HTMLElement)
	{
		// I guess this is source for the code bellow: https://stackoverflow.com/a/10063405/5851804

		// Scroll inner functions for smooth scrolling
		// Rewritten from Codeberry's ES5 to TypeScript
		const elementYPosition = (o: HTMLElement) => {
			let t: any;
			let e: any;
			let n: any;

			for (t = o, e = t.offsetTop, n = t; n.offsetParent && n.offsetParent != document.body;)
				n = <HTMLElement>n.offsetParent,
					e += n.offsetTop;
			return e;
		};

		let t: any;
		let a: any;
		let s: any;
		let r: any;
		let e: any;
		let n: any;
		let f: any;
		let i: any;
		let l: any;

		t = self.pageYOffset;
		e = elementYPosition(o);
		n = e > t ? e - t : t - e;
		if (100 > n)
			return void scrollTo(0, e);
		r = Math.round(n / 0.05);
		r >= 20 && (r = 20);
		f = Math.round(n / 25);
		a = e > t ? t + f : t - f;
		s = 0;
		if (e > t)
			for (i = t; e > i; i += f)
				setTimeout('window.scrollTo(0, ' + a + ')', s * r),
					a += f,
				a > e && (a = e),
					s++;
		else
			for (l = t; l > e; l -= f)
				setTimeout('window.scrollTo(0, ' + a + ')', s * r),
					a -= f,
				e > a && (a = e),
					s++;
	}

	public static linebreaker(text: string): string
	{
		return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
	}

	public static ngbDateToString(date: NgbDateStruct): string
	{
		if (!date) return null;

		const d = this.ngbDateToMoment(date);

		return d.isValid() ? d.format(this.userDateFormat) : null;
	}

	public static stringToNgbDate(date: string): NgbDateStruct
	{
		if (!date) return null;

		return this.momentToNgbDate(moment(date, this.userDateFormat));
	}

	public static nativeToNgbDate(date: Date): NgbDateStruct
	{
		return (date && date.getFullYear) ? {
			year  : date.getFullYear() || this.now.year(),
			month : (date.getMonth() || this.now.year()) + 1,
			day   : date.getDate() || this.now.year()
		} : null;
	}

	public static ngbDateToNative(date: NgbDateStruct): Date
	{
		return date ? new Date(date.year, date.month - 1, date.day) : null;
	}

	public static momentToNgbDate(date: moment.Moment): NgbDateStruct
	{
		if (!date || !moment.isMoment(date)) return null;

		const result = {
			year  : date.year(),
			month : date.month() + 1,
			day   : date.date()
		};

		if (isNaN(result.year)) result.year = this.now.year();
		if (isNaN(result.month)) result.month = this.now.month() + 1;
		if (isNaN(result.day)) result.day = this.now.day();

		return result;
	}

	public static ngbDateToMoment(date: NgbDateStruct): moment.Moment
	{
		if (!date) return null;

		return moment({
			year  : date.year,
			month : date.month - 1,
			date  : date.day
		});
	}

	public static getNextBusinessDay(): Moment
	{
		let business_day;

		if (moment().day() == 5) {
			business_day = moment().add(3, 'day');
		} else if (moment().day() == 6) {
			business_day = moment().add(2, 'day');
		} else {
			business_day = moment().add(1, 'day');
		}

		return business_day;
	}

	static ngbTimeToString(time: NgbTimeStruct): string
	{
		return moment().hour(time.hour).minute(time.minute).second(time.second).format('HH:mm:ss');
	}

	static ngbTimeToMoment(time: NgbTimeStruct): moment.Moment
	{
		return moment().hour(time.hour).minute(time.minute).second(time.second);
	}

	static timeStringsToSpan(time_start: string, time_end: string): string
	{
		return moment(time_start, 'HH:mm:ss').format('HH:mm')
			+ '—' + moment(time_end, 'HH:mm:ss').format('HH:mm');
	}

	static timeMomentToSpan(time_start: moment.Moment, time_end: moment.Moment, format: string): string
	{
		let start_str = '';
		let end_str = '';
		let join = '';

		if (time_start && time_start.isValid()) {
			start_str = time_start.format(format);
		}

		if (time_end && time_end.isValid()) {
			end_str = time_end.format(format);
		}

		if (start_str || end_str) join = ' — ';

		return start_str + join + end_str;
	}

	static timeToTimePicker(input: string): NgbTimeStruct
	{
		if (!input) return null;
		const time = moment(input, 'HH:mm:ss');
		if (!time.isValid()) return null;

		return {
			hour   : time.hour(),
			minute : time.minute(),
			second : time.second()
		};
	}

	static grandComparator(a, b)
	{
		if (!a && !b) return true;
	}

	static mergeDateTime(date: moment.Moment, time: moment.Moment, is_strict = false): moment.Moment
	{
		if (!date || is_strict && !time) return null;

		const result = date.clone();
		if (!time) {
			result.hour(0);
			result.minute(0);
			result.second(0);

			return result;
		}

		result.hour(time.hour());
		result.minute(time.minute());
		result.second(time.second());

		return result;
	}

	static reformatTime(timestamp: string)
	{
		if (moment(timestamp, ['MM.YYYY', 'M.YYYY'], true).isValid()) {
			return moment(timestamp, 'MM.YYYY').format('YYYY-MM');
		}

		if (moment(timestamp, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY', 'DD.M.YYYY'], true).isValid()) {
			return moment(timestamp, 'DD.MM.YYYY').format('YYYY-MM-DD');
		}

		return timestamp;
	}

	static checkDaysInside(date: moment.Moment, interval_start, interval_end): boolean
	{
		if (!date) return null;

		return !(interval_end && moment(date).isAfter(interval_end, 'day')
			|| interval_start && moment(date).isBefore(interval_start, 'day'));
	}

	static split(input: string | number, separator = ','): string[]
	{
		return this.safeToString(input).split(separator).map(str => str.trim());
	}

	static splitOne(input: string, separator = ',', index = 0): string
	{
		return this.split(input, separator)[index];
	}

	static timeStringToArray(input: string)
	{
		const times: string[] = input.split(':');
		let hour: number = +times[0] || 0;
		let minute: number = +times[1] || 0;
		let second: number = +times[2] || 0;

		if (hour == 24) {
			hour = 23;
			minute = 59;
			second = 59;
		}

		return { hour, minute, second };
	}

	public static getServerDateFormat(date: moment.Moment): string
	{
		if (!date || !moment.isMoment(date) || (moment.isMoment(date) && !date.isValid())) return null;
		return date.format(this.serverDateFormat);
	}

	public static areMomentDatesSame(moment1: moment.Moment,
	                                 moment2: moment.Moment,
	                                 granularity: unitOfTime.StartOf = 'day'
	): boolean
	{
		if (!moment1) return !moment2;
		return moment1.isSame(moment2, granularity);
	}

	public static areMomentDateInRange(date: moment.Moment, start: moment.Moment, end: moment.Moment): boolean
	{
		return date && !(
			date.isAfter(end.endOf('day')) ||
			date.isBefore(start.startOf('day'))
		);
	}

	public static getCalendarLanguage(): CalendarLocale
	{
		const localeData = moment.localeData();
		return {
			firstDayOfWeek  : localeData.firstDayOfWeek(),
			dayNames        : localeData.weekdays(),
			dayNamesShort   : localeData.weekdaysShort(),
			dayNamesMin     : localeData.weekdaysMin(),
			monthNames      : localeData.months(),
			monthNamesShort : localeData.monthsShort()
		};
	}

	public static checkChangesAreEqual(changes: SimpleChanges): boolean
	{
		let areEqual = true;
		each(changes, change => {
			if (!isEqual(change.previousValue, change.currentValue)) {
				areEqual = false;
				return false;
			}
		});

		return areEqual;
	}

	public static parseRegExFromStr(regex_str: string): string
	{
		if (regex_str.startsWith('/') && regex_str.endsWith('/')) {
			regex_str = regex_str.substring(1, regex_str.length - 1);
		}
		return regex_str;
	}

	public static goToUrl(urls: string[]): void
	{
		window.location.href = urls.join('/');
	}

	public static sortTranslatedCountries(countries : { name: string, id: string }[]) : { name: string, id: string }[]
	{
		if (isNil(countries)) return [];

		each(countries, (value, key) => {
			countries[key].name = Enkora.tt('country-' + value.id);
		});
		countries = sortBy(countries, (country) => {
			return deburr(country.name);
		});

		const finland = find(countries, c => c.id == 'FI');
		const other = find(countries, c => c.id == 'XX');

		remove(countries, c => c.id == 'FI');
		remove(countries, c => c.id == 'XX');

		countries.unshift({
			id   : finland?.id,
			name : Enkora.tt('country-' + finland?.id)
		});
		countries.push({
			id   : other?.id,
			name : Enkora.tt('country-' + other?.id)
		});
		return countries;
	}

	public static getLockerReservationTime(locker: any) {
		const format = 'DD.MM.YY HH:mm';
		let time = moment(locker.time_start).format(format);
		if (locker.paid_until) {
			time += ` - ${moment(locker.paid_until).format(format)}`;
		} else if (moment(locker.time_end).isBefore('01.01.3000')) {
			time += ` - ${moment(locker.time_end).format(format)}`;
		}

		return time;
	}

	public static userHasMissingInfo(user: User, fields: { [key: string]: ShopCustomField }): boolean
	{
		for (const [key, value] of Object.entries(fields)) {
			const field = user[key] || user.account[key];
			if (+value.is_required && +value.is_customer_editable && !field) {
				return true;
			}
		}

		return false;
	}

	public static translateKey(key: string, defaultValue: string, capitalize = false): string {
		const translated = Enkora.tt(key);
		const result = translated && translated !== key ? translated : Enkora.tt(defaultValue);
		return capitalize ? Helpers.capitalizeStr(result) : result;
	}

	public static capitalizeStr(str: string): string {
		return str?.charAt(0).toUpperCase() + str?.substring(1, str.length);
	}

	public static convertUrlToJSONReportData(url: string): any {
		const split = url.split('/').pop();
		if (!split) return null;

		const urlSerializer = new DefaultUrlSerializer();

		const params = urlSerializer.parse(split).queryParams;

		const entry : any = {
			conditions  : {},
			report_name : params.report_name,

			report_label   : params.report_label,
			report_values  : [],
			chart_type     : params.chart_type,
			update_timeout : params.update_timeout,

			data : null
		};

		try {
			entry.chart_type = JSON.parse(params.chart_type);
		} catch (e) {
			entry.chart_type = params.chart_type;
		}

		try {
			entry.report_values = JSON.parse(params.report_values);
		} catch (e) {
			entry.report_values = [params.report_values];
		}

		try {
			entry.conditions = JSON.parse(params.conditions);
		} catch (e) {
			entry.conditions = params.conditions;
		}

		// Update timeout cannot be lower than minute!
		if(entry.update_timeout === "0") entry.update_timeout = "1";

		return entry;
	}

	public static setElementFocus(native_element: HTMLElement, selector: string): void
	{
		const first_element = native_element?.querySelector(selector) as HTMLElement;
		first_element?.focus();
	}

	/**
	 * Removes the leading zeros from a date
	 * @param date Format "dd.mm"
	 */
	public static removeLeadingZero(date: string): string {
		const split = date.split('.');
		if (split.length < 2) { return date; }
		return `${+split[0]}.${+split[1]}`;
	}

	public static calculateAge(birthDate: moment.Moment, currentDate: moment.Moment): number {
		const years = currentDate.diff(birthDate, 'years');
		return years;
	}

	public static setUrlParam(name: string, value: string) {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set(name, value);
		window.history.replaceState({}, '', `${location.pathname}?${searchParams.toString()}`);
	}
}
