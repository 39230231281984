<ng-container *ngIf="inputForm; else no_extra">
	<div [formGroup]="inputForm">
		<div formArrayName="extra_services" class="well well-lg">
			<div class="checkbox"
			     *ngFor="let service of extra_services.controls; let i=index"
			     [formGroupName]="i">
				<input [id]="'extra_services ' + id + i" type="checkbox" formControlName="is_selected">
				<label [for]="'extra_services ' + id + i">
					{{service.get('name').value | tt}}: {{ service.get('price').value | fastcents }}
					<span *ngIf="service.get('is_per_day').value" tt>/ per day</span>
				</label>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #no_extra>
	<div class="mb-4" tt>
		There are no extra services available.
	</div>
</ng-template>
