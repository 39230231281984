<button style="margin-top: 7px;"
        class="pull-right btn btn-small btn-primary"
        [class.active]="show_past"
        (click)="togglePast()"
        *ngIf="past_count> 0 && past_allowed"
        tt>
	Include past items
</button>

<h3 tt>User access right products</h3>

<ng-container *ngIf="!access_rights || !access_rights.length ||
			!show_past && past_count > 0 && past_count == access_rights.length; else has_content">
	<p class="well" tt>No active access rights.</p>
</ng-container>
<ng-template #has_content>
	<table class="user-access-rights table table-striped table-condensed" style="width: 100%;">
		<thead>
		<tr>
			<th tt>User access right product</th>
			<th class="ue-time" tt>Loading Timestamp</th>
			<th tt>Status</th>
		</tr>
		</thead>
		<tbody>
		<ng-container *ngFor="let ar of access_rights">
			<tr *ngIf="!ar.expired_days_ago || show_past">
				<td>{{ ar.template_name }}</td>
				<td class="ue-time">
					{{ar.loading_timestamp | amDateFormat:"DD.MM.YYYY HH:mm"}}
				</td>
				<td *ngIf="!ar.expired_days_ago; else message">{{ ar.status_message }}</td>
				<ng-template #message>
					<td><span class="label">{{ ar.status_message }}</span></td>
				</ng-template>
			</tr>
		</ng-container>
		</tbody>
	</table>
</ng-template>

