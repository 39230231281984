<div class="modal-header">
	<h2 class="no-margin-bottom" tt>
		Edit company tree
	</h2>
	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="activeModal.close();">
		<cta-icon-close></cta-icon-close>
	</button>
</div>
<div class="modal-body">
	<cta-ta-add-employee-progressbar *ngIf="!standalone"
	                                 [currentStep]="3">
	</cta-ta-add-employee-progressbar>

	<p class="mb-5" tt>
		Time and attendance company tree modal description
	</p>

	<h4>
		{{(dataHolder.is_add_new_employee ? 'Add company tree' : 'Edit company tree') | tt}}
	</h4>

	<div class="my-2 font-boing">
		<button [disabled]="!history.length" class="btn btn-outline-primary btn-sm me-1" (click)="undoAll()">
			<i class="fa fa-refresh me-1"></i>
			<span tt>Undo All</span>
		</button>

		<button [disabled]="!history.length" class="btn btn-outline-primary btn-sm" (click)="undo()">
			<i class="fa fa-undo me-1"></i>
			<span tt>Undo</span>
		</button>
	</div>

	<div class="position-relative">
		<div *appLoading="treeService.is_loading || tree_updating"></div>
		<cta-ta-company-tree [tree]="tree"
		                     [subcontractors_max_length]="subcontractors_max_length"
		                     [organizations]="organizations"
		                     [organization_id]="organization_id"
		                     (treeChange)="treeChange($event)">
		</cta-ta-company-tree>
	</div>

	<button class="btn btn-primary my-3"
	        (click)="createNewCompany()"
	        [disabled]="employmentForm" tt>
		Create new company
	</button>

	<div class="bg-white border-primary-1 border-radius-base p-4"
	     *ngIf="employmentForm">

		<cta-ta-form-employment-info [inputForm]="employmentForm"
		                             [show_contract]="false">
		</cta-ta-form-employment-info>

		<button class="btn btn-primary"
		        (click)="saveEmployment()"
		        [disabled]="!is_valid" tt>
			Save company
		</button>
	</div>

	<ul class="list-group font-boing">
		<li *ngFor="let state of history" class="list-group-item">
			<span *ngIf="state.summary.action == 0 && state.summary.next_organization">
				<b class="text-success me-1" tt>cta tree add</b>
				<i class="mx-1">{{state.summary.organization}}</i>
				<span tt>cta tree between</span>
				<i class="mx-1">{{state.summary.prev_organization}}</i>
				<span tt>cta tree and</span>
				<i class="mx-1">{{state.summary.next_organization}}</i>
			</span>
			<span *ngIf="state.summary.action == 0 && !state.summary.next_organization">
				<b class="text-success me-1" tt>cta tree add</b>
				<i class="mx-1">{{state.summary.organization}}</i>
				<span tt>cta tree to</span>
				<i class="mx-1">{{state.summary.prev_organization}}</i>
			</span>
			<span *ngIf="state.summary.action == 1 && state.summary.next_organization">
				<b class="text-danger me-1" tt>cta tree delete</b>
				<i class="mx-1">{{state.summary.organization}}</i>
				<span tt>cta tree between</span>
				<i class="mx-1">{{state.summary.prev_organization}}</i>
				<span tt>cta tree and</span>
				<i class="mx-1">{{state.summary.next_organization}}</i>
			</span>
			<span *ngIf="state.summary.action == 1 && !state.summary.next_organization">
				<b class="text-danger me-1" tt>cta tree delete</b>
				<i class="mx-1">{{state.summary.organization}}</i>
				<span tt>cta tree from</span>
				<i class="mx-1">{{state.summary.prev_organization}}</i>
			</span>
		</li>
	</ul>

</div>

<div class="modal-footer">
	<button type="button" class="btn btn-secondary" *ngIf="!standalone" (click)="back()" tt>Previous</button>

	<div class="spacer"></div>

	<button type="button" class="btn btn-primary-custom" (click)="confirm()" tt>
		{{(standalone ? 'done' : 'continue') | titlecase | tt}}
	</button>
</div>
