import {
	ComponentFactory,
	ComponentFactoryResolver,
	Directive,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { delayWhen } from 'rxjs/operators';

import { LoadingComponent } from '../components';

@Directive({
	selector : '[appLoading]'
})

export class AppLoadingDirective implements OnInit, OnDestroy {
	loadingFactory: ComponentFactory<LoadingComponent>;
	loading$ = new Subject();
	subs: Subscription;

	private _timeout = 0;

	constructor(private templateRef: TemplateRef<any>,
	            private vcRef: ViewContainerRef,
	            private componentFactoryResolver: ComponentFactoryResolver)
	{
		// Create resolver for loading component
		this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
		this.subs = this.loading$.pipe(
			delayWhen(loading => loading ? timer(0) : timer(this._timeout))
		).subscribe(loading => {
			this.vcRef.clear();
			if (loading) {
				// create and embed an instance of the loading component
				this.vcRef.createEmbeddedView(this.templateRef);
				this.vcRef.createComponent(this.loadingFactory);
			} else {
				// embed the contents of the host template
				this.vcRef.createEmbeddedView(this.templateRef);
			}
		});
	}

	@Input()
	set appLoadingTimeout(value: number)
	{
		this._timeout = value;
	}

	@Input()
	set appLoading(loading: boolean)
	{
		this.loading$.next(loading);
	}

	ngOnDestroy(): void
	{
		if (this.subs) this.subs.unsubscribe();
	}

	ngOnInit(): void
	{
	}
}
