import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { mergeWith } from 'lodash';

import { TaDataHelper, TaFindUsersAndContactsService } from '../../services';
import { TaContact } from '../../interfaces';


@Component({
	selector    : 'cta-ta-form-company-representative',
	templateUrl : './company-representative.form.html',
	styleUrls   : ['./company-representative.form.scss']
})
export class CompanyRepresentativeForm implements OnInit {
	@Input() public readonly = false;
	@Input() public company_form = false;
	@Input() public is_foreign_contact = false;

	public editable_contact = false;
	public searchingName = false;
	public searchNameFailed = false;
	public is_location_specific = true;
	public is_selected_contact = false;
	public is_start_edit = true;

	hideName = new Observable(() => () => this.searchingName = false);

	constructor(private findContact: TaFindUsersAndContactsService)
	{
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(form: FormGroup)
	{
		this._inputForm = form;

		if (!form || !form.value) return;

		form.get('is_location_specific').valueChanges.subscribe(value => {
			this.is_location_specific = value;
		});

		this.configureForm(form.value.contact_id);
		form.get('contact_id').valueChanges.subscribe(value => {
			this.configureForm(value);
		});

		this.editable_contact = !form.value.contact_id || this.company_form;
	}

	ngOnInit(): void
	{
	}

	searchFirstName: OperatorFunction<string, readonly TaContact[]> = (text$: Observable<string>): Observable<TaContact[]> =>
		this.searchName(text$, null) as Observable<TaContact[]>;
	searchLastName: OperatorFunction<string, readonly TaContact[]> = (text$: Observable<string>): Observable<TaContact[]> =>
		this.searchName(null, text$) as Observable<TaContact[]>;

	searchName(first_name?: Observable<string>, last_name?: Observable<string>): Observable<unknown>
	{
		if (first_name === null && last_name === null) return;

		const active_name = first_name || last_name;

		return merge(
			active_name.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.searchingName = true),
				switchMap(name => {
					const entered_first_name = first_name == null ? this._inputForm.get('first_name').value : name;
					const entered_last_name = last_name == null ? this._inputForm.get('last_name').value : name;
					return this.findContact.findEmployee(entered_first_name, entered_last_name).pipe(
						tap(() => this.searchNameFailed = false),
						catchError(() => {
							this.searchNameFailed = true;
							return of([] as TaContact[]);
						}));
				}),
				tap(() => this.searchingName = false)
			),
			this.hideName
		);
	}

	onSelect(event: NgbTypeaheadSelectItemEvent): void
	{
		event.preventDefault();
		const contact = mergeWith(
			{}, TaDataHelper.emptyContact, event.item,
			(a, b) => b === null ? a : undefined
		);
		contact.contact_id = null;
		this.inputForm.patchValue(contact);
	}

	public startEdit(): void
	{
		const location_specific_contact = TaDataHelper.emptyContact;
		location_specific_contact.is_location_specific = true;
		this.inputForm.setValue(location_specific_contact);
		this.readonly = false;
		this.is_start_edit = true;
	}

	private configureForm(contact_id: number)
	{
		if (contact_id != null) {
			this.is_start_edit = false;
			this.readonly = true;
			this.is_selected_contact = true;
		} else {
			this.is_start_edit = true;
			this.readonly = false;
			this.is_selected_contact = false;
		}
	}

}
