import { Injectable } from '@angular/core';
import { Constants } from "../../shared/misc/constants";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserConfigService {
	get parameters() : any {
		return this._parameters;
	}

	set parameters(value : any) {
		this._parameters = value;
	}

	get user() : any {
		return this._user;
	}

	set user(value : any) {
		this._user = value;
		if (value && value.parameters) {
			this.parameters = value.parameters;
		}
		this.user$.next(value);
	}

	private _user : any = null;
	private _parameters : any = {};
	public user$ = new BehaviorSubject<any>(null);
	public can_create_lessons: any = null;

	constructor() {
	}

	checkConfig() {
		if (this._user)
			return ( this._user.user_type_id == Constants.UT_ADMINISTRATOR
				|| this._user.user_type_id == Constants.UT_SALES_PERSON
				|| this._user.user_type_id == Constants.UT_MANAGER
				|| this._user.user_type_id == Constants.UT_INSTRUCTOR
				|| this._user.user_type_id == Constants.UT_RESERVATION_MANAGER
			);

		return false;
	};

	checkRole(role : string) {
		if (this._user) {
			switch (role) {
				case 'admin':
					return (this._user.user_type_id == Constants.UT_ADMINISTRATOR);
				case 'sales':
					return (this._user.user_type_id == Constants.UT_SALES_PERSON);
				case 'manager':
					return (this._user.user_type_id == Constants.UT_MANAGER);
				case 'instructor':
					return (this._user.user_type_id == Constants.UT_INSTRUCTOR);
				case 'reservation_manager':
					return (this._user.user_type_id == Constants.UT_RESERVATION_MANAGER);
				case 'user':
					return !(this._user.user_type_id == Constants.UT_ADMINISTRATOR
					|| this._user.user_type_id == Constants.UT_SALES_PERSON
					|| this._user.user_type_id == Constants.UT_MANAGER
					|| this._user.user_type_id == Constants.UT_INSTRUCTOR
					|| this._user.user_type_id == Constants.UT_RESERVATION_MANAGER);
			}
		}

		return false;
	};

	checkTypeId(role : number) {
		return !!this._user && (this._user.user_type_id == role);
	}

	checkInstructor() {
		if (this._user && this._user.user_type_id == Constants.UT_INSTRUCTOR
			&& this._user.account && this._user.account.account_id)
			return this._user;
		return false;
	};

	resetUserType() {
		this._user.user_type_id = 0;
	};
}
