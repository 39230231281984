import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Data, Params } from '@angular/router';
import { concat, Observable, of, Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { each, find } from 'lodash';

import { Constants, Helpers } from 'shared';

import { ShopServiceListService } from './service-list.service';
import { ShopLocationListService } from './location-list.service';
import { ShopSaleGroupListService, ShopSalesGroupItem } from './sale-group-list.service';
import { ShopServiceGroupItem, ShopServiceGroupListService } from './service-group-list.service';
import { ShopResourceListService } from './resource-list.service';
import { ShopResourceTagListService } from './resource-tag-list.service';
import { ShopReservationGroupListService } from './reservation-group-list.service';
import { ShopInstructorListService } from './instructor-list.service';
import { ShopCourseTagListService } from './course-tag-list.service';
import { ShopSeasonListService } from './season-list.service';
import { ShopTargetGroupListService } from './target-group-list.service';
import { ShopTagGroupListService } from './tag-group-list.service';

export enum PathType {
	None = 'None',
	Shop = 'Shop',
	Resource = 'Resource',
	Course = 'Course',
	Hotel = 'Hotel',
	PublicCalendar = 'PublicCalendar',
	ReservationApplication = 'ReservationApplication'
}

export interface StateValues {
	service_id: string;
	location_ids: string[];
	sale_group_id: string;
	service_group_id: string;
	resource_ids: string[];
	resource_tag_ids: string[];
	course_tag_ids: string[];
	reservation_group_ids: string[];
	instructor_ids: string[];
	season_ids: string[];
	target_group_ids: string[];
	tag_group_ids: string[];
}

export interface SelectorsState extends StateValues {
	path: string;
	type: PathType;

	mode: string;
	user_id: string;
	is_initialized: boolean;
	do_payment_result: string;
	course_id: string;
	fare_product_id: string;
	description: string;
}

@Injectable()
export class ShopSelectorsStateService {

	static is_initialized = false;
	private state$ = new Subject<SelectorsState>();

	constructor(private location: Location,
	            private service_list: ShopServiceListService,
	            private location_list: ShopLocationListService,
	            private resource_list: ShopResourceListService,
	            private resource_tag_list: ShopResourceTagListService,
	            private season_list: ShopSeasonListService,
	            private sale_group_list: ShopSaleGroupListService,
	            private service_group_list: ShopServiceGroupListService,
	            private reservation_group_list: ShopReservationGroupListService,
	            private instructor_list: ShopInstructorListService,
	            private course_tag_list: ShopCourseTagListService,
	            private target_group_list: ShopTargetGroupListService,
	            private tag_group_list: ShopTagGroupListService,
	) {
		this.state$.pipe(debounceTime(100)).subscribe(() => this.updateUrl());
	}

	static get emptyValues(): StateValues
	{
		return {
			service_id            : '',
			location_ids          : [],
			sale_group_id         : '',
			service_group_id      : '',
			resource_ids          : [],
			resource_tag_ids      : [],
			course_tag_ids        : [],
			reservation_group_ids : [],
			instructor_ids        : [],
			season_ids            : [],
			target_group_ids      : [],
			tag_group_ids         : [],
		};
	}

	static get emptyState(): SelectorsState
	{
		return {
			...this.emptyValues,
			type              : PathType.None,
			path              : 'reservations',
			mode              : '',
			user_id           : '',
			is_initialized    : this.is_initialized,
			do_payment_result : '',
			course_id         : '',
			fare_product_id   : '',
			description       : null
		};
	}

	private _state: SelectorsState = ShopSelectorsStateService.emptyState;

	get state(): SelectorsState
	{
		return this._state;
	}

	set state(value: SelectorsState)
	{
		this._state = value;
		this.state$.next(value);
	}

	public setState(value: Partial<SelectorsState>): void
	{
		this.state = {
			...this.state, ...value
		};
	}

	get(): Observable<SelectorsState>
	{
		if (!this.state.is_initialized) return this.state$.pipe(debounceTime(100));
		else return concat(of(this.state), this.state$).pipe(debounceTime(100));
	}

	public initSelectors(params: { route: Params, data: Data, queryParams: Params }): void
	{
		this.service_list.get().pipe(first()).subscribe(service_list => {
			params = params || { route : {}, data : {}, queryParams : {} };
			const data = params.data || {};
			const state = ShopSelectorsStateService.emptyState;

			state.path = data.path || 'reservations';
			if (params.route.service_id) {

				state.service_id = params.route.service_id == 'shop' ? '-1' : params.route.service_id;
				const service = find(service_list, s => s.service_id == state.service_id);

				if (service) {
					state.description = service.description;
					if (params.route.service_id == 'shop') {
						state.type = PathType.Shop;
						state.sale_group_id = this.stringToId(params.route.location_ids);
						state.service_group_id = this.stringToId(params.route.group_ids);
						state.fare_product_id = this.stringToId(params.route.instructor_ids);
					} else if (params.route.service_id == 'public-calendar') {
						state.type = PathType.PublicCalendar;
					} else if (params.route.service_id == 'reservation-application') {
						state.type = PathType.ReservationApplication;
					} else {
						if (service.is_resource_reservation) {
							if (service.service_type_id == Constants.ST_HOTEL_RESERVATIONS) {
								state.type = PathType.Hotel;
								state.location_ids = this.stringsToIds(params.route.location_ids);
								state.resource_ids = this.stringsToIds(params.route.group_ids);
							} else {
								state.type = PathType.Resource;
								state.location_ids = this.stringsToIds(params.route.location_ids);
								state.resource_ids = this.stringsToIds(params.route.group_ids);
							}
						} else {
							state.type = PathType.Course;
							state.location_ids = this.stringsToIds(params.route.location_ids);
							state.reservation_group_ids = this.stringsToIds(params.route.group_ids);
							state.instructor_ids = this.stringsToIds(params.route.instructor_ids);
							state.target_group_ids = this.stringsToIds(params.route.target_group_ids);
							state.tag_group_ids = this.stringsToIds(params.route.tag_group_ids);
							state.course_id = this.stringToId(params.route.course_id);
						}
					}
				}
			}

			state.do_payment_result = params.queryParams && params.queryParams.doPayment;
			state.user_id = params.route.user_id || '';

			ShopSelectorsStateService.is_initialized = true;
			state.is_initialized = true;

			this.state = state;

			this.setup();
		});
	}

	public setService(service_id: string): void
	{
		const service = find(this.service_list.value, s => s.service_id == service_id);
		if (!service) return;

		let type: PathType;
		if (+service_id == Constants.WEBSHOP_SHOP) {
			type = PathType.Shop;
		} else if (service.is_resource_reservation) {
			if (service.service_type_id == Constants.ST_HOTEL_RESERVATIONS) {
				type = PathType.Hotel;
			} else {
				type = PathType.Resource;
			}
		} else {
			type = PathType.Course;
		}

		this.setState({
			path        : 'reservations',
			type,
			service_id,
			description : service.description
		});

		this.setup();
	}

	public setSaleGroup(sale_group: ShopSalesGroupItem): void
	{
		if (find(this.sale_group_list.value, sg => sg.sale_group_id == sale_group.sale_group_id)) {
			this.setState({
				description   : sale_group.description,
				sale_group_id : sale_group.sale_group_id
			});
		} else {
			this.setState({
				description : null
			});
		}
	}

	public setServiceGroup(service_group: ShopServiceGroupItem): void
	{
		if (find(this.service_group_list.value, sg => sg.service_group_id == service_group.service_group_id)) {
			this.setState({
				description      : this.state.description || service_group.description,
				service_group_id : service_group.service_group_id
			});
		} else {
			this.setState({
				description : null
			});
		}
	}

	public setLocations(location_ids: string[]): void
	{
		const new_location_ids: string[] = [];
		const location_map = Helpers.arrayToSimpleMap(location_ids);

		each(this.location_list.value, location => {
			if (location_map(location.location_id)) {
				new_location_ids.push(location.location_id);
			}
		});

		this.setState({
			location_ids : new_location_ids
		});
	}

	public setTargetGroups(target_group_ids: string[]): void
	{
		const new_target_group_ids: string[] = [];
		const target_group_map = Helpers.arrayToSimpleMap(target_group_ids);

		each(this.target_group_list.value, target_group => {
			if (target_group_map(target_group.tag_id)) {
				new_target_group_ids.push(String(target_group.tag_id));
			}
		});

		this.setState({
			target_group_ids : new_target_group_ids
		});
	}

	public setTagGroups(tag_group_ids: string[]): void
	{
		const new_tag_group_ids: string[] = [];
		const tag_group_map = Helpers.arrayToSimpleMap(tag_group_ids);

		each(this.tag_group_list.value, tag_group => {
			if (tag_group_map(tag_group.tag_id)) {
				new_tag_group_ids.push(String(tag_group.tag_id));
			}
		});

		this.setState({
			tag_group_ids : new_tag_group_ids
		});
	}

	public setReservationGroups(reservation_group_ids: string[]): void
	{
		const new_reservation_group_ids: string[] = [];
		const group_map = Helpers.arrayToSimpleMap(reservation_group_ids);

		each(this.reservation_group_list.value, reservation_group => {
			if (group_map(reservation_group.reservation_group_id)) {
				new_reservation_group_ids.push(reservation_group.reservation_group_id);
			}
		});

		this.setState({
			reservation_group_ids : new_reservation_group_ids
		});
	}

	public setInstructors(instructor_ids: string[]): void
	{
		const new_instructor_ids: string[] = [];
		const instructor_map = Helpers.arrayToSimpleMap(instructor_ids);

		each(this.instructor_list.value, instructor => {
			if (instructor_map(instructor.account_id)) {
				new_instructor_ids.push(instructor.account_id);
			}
		});

		this.setState({
			instructor_ids : new_instructor_ids
		});
	}

	public setCourseTags(course_tag_ids: string[]): void
	{
		const new_course_tag_ids: string[] = [];
		const course_tag_map = Helpers.arrayToSimpleMap(course_tag_ids);

		each(this.course_tag_list.value, course_tag => {
			if (course_tag_map(course_tag.tag_id)) {
				new_course_tag_ids.push(course_tag.tag_id);
			}
		});

		this.setState({
			course_tag_ids : new_course_tag_ids
		});
	}

	public setResources(resource_ids: string[]): void
	{
		const new_resource_ids: string[] = [];
		const resource_map = Helpers.arrayToSimpleMap(resource_ids);

		each(this.resource_list.value, resource => {
			if (resource_map(resource.resource_id)) {
				new_resource_ids.push(resource.resource_id);
			}
		});

		this.setState({
			resource_ids : new_resource_ids
		});
	}

	public setResourceTags(tag_ids: string[]): void
	{
		const new_tag_ids: string[] = [];
		const tag_map = Helpers.arrayToSimpleMap(tag_ids);

		each(this.resource_tag_list.value, tag => {
			if (tag_map(tag.tag_id)) new_tag_ids.push(tag.tag_id);
		});

		this.setState({
			resource_tag_ids : new_tag_ids
		});
	}

	public setSeasons(season_ids: string[]): void
	{
		const new_season_ids: string[] = [];
		const season_map = Helpers.arrayToSimpleMap(season_ids);

		each(this.season_list.value, season => {
			if (season_map(season.season_type_id)) new_season_ids.push(season.season_type_id);
		});

		this.setState({
			season_ids : new_season_ids
		});
	}

	public goHome(): void
	{
		const state = ShopSelectorsStateService.emptyState;
		state.path = 'home';

		this.state = state;
	}

	public goReservations(): void
	{
		const state = ShopSelectorsStateService.emptyState;
		state.path = 'reservations';

		this.state = state;
	}

	public goUserProfile(user_id: string = null): void
	{
		const state = ShopSelectorsStateService.emptyState;
		state.path = 'user';
		state.user_id = user_id;

		this.state = state;
	}

	public updateUrl(): void
	{
		let url = 'shop';

		if (this.state.path == 'home') {
			url += '/home';
		} else if (this.state.path == 'user') {
			url += '/user';
			url += this.state.user_id ? '/' + this.state.user_id : '';
		} else if (this.state.path == 'reservations') {
			url += '/reservations';

			switch (this.state.type) {
				case PathType.Shop:
					url += '/shop';
					url += '/' + (this.state.sale_group_id || '-');
					url += '/' + (this.state.service_group_id || '-');
					url += '/' + (this.state.fare_product_id || '-');
					break;
				case PathType.Resource:
					url += '/' + this.state.service_id;
					url += '/' + (this.state.location_ids.join(',') || '-');
					url += '/' + (this.state.resource_ids.join(',') || '-');
					url += '/-';
					break;
				case PathType.Course:
					url += '/' + this.state.service_id;
					url += '/' + (this.state.location_ids.join(',') || '-');
					url += '/' + (this.state.reservation_group_ids.join(',') || '-');
					url += '/' + (this.state.instructor_ids.join(',') || '-');
					url += '/-';
					url += '/' + (this.state.target_group_ids.join(',') || '-');
					url += '/' + (this.state.course_id || '-');
					url += '/' + (this.state.tag_group_ids.join(',') || '-');
					break;
				case PathType.Hotel:
					url += '/' + this.state.service_id;
					url += '/' + (this.state.location_ids.join(',') || '-');
					url += '/' + (this.state.resource_ids.join(',') || '-');
					url += '/-';
					break;
				case PathType.PublicCalendar:
					url += '/shop/public-calendar';
					break;
				case PathType.ReservationApplication:
					url += '/shop/reservation-application';
					break;
			}
		} else if (this.state.path == 'public-calendar') {
			url += '/public-calendar';
		} else if (this.state.path == 'reservation-application') {
			url += '/reservation-application';
		}

		this.location.go(url);
	}

	private stringsToIds = (input: string = undefined): string[] => {
		const str = Helpers.safeToString(input);

		if (str == '-') return [];
		return str.split(',');
	};

	private stringToId = (input: string = undefined): string => {
		const str = Helpers.safeToString(input);

		if (str == '-') return '';

		return str;
	};

	private setup()
	{
		switch (this.state.type) {
			case PathType.Shop:
				this.setupShop();
				break;
			case PathType.Course:
				this.setupCourse();
				break;
			case PathType.Resource:
				this.setupResource();
				break;
			case PathType.Hotel:
				this.setupHotel();
				break;
		}
	}

	private setupShop()
	{
		this.sale_group_list.get().pipe(first()).subscribe(list => {
			if (list && list.length == 1) {
				this.setSaleGroup(this.sale_group_list.value[0]);
			} else {
				const item = find(list, sg => sg.sale_group_id == this.state.sale_group_id);
				if (item) {
					this.setSaleGroup(item);
				}
			}
		});
	}

	private setupHotel()
	{
	}

	private setupResource()
	{
		// if (this.location_list.filtered_list.length == 1) {
		// 	this.setLocations([this.location_list.filtered_list[0].location_id]);
		// } else {
		// 	this.setLocations(this.state.location_ids);
		// }
	}

	private setupCourse()
	{
	}

	public onlyServiceSelected(): boolean
	{
		return !this.state.location_ids.length
			&& !this.state.resource_ids.length
			&& !this.state.resource_tag_ids.length
			&& !this.state.course_tag_ids.length
			&& !this.state.reservation_group_ids.length
			&& !this.state.instructor_ids.length
			&& !this.state.season_ids.length
			&& !this.state.target_group_ids.length
			&& !this.state.tag_group_ids.length
			&& !this.state.sale_group_id
			&& !this.state.service_group_id;
	}
}
