<div class="modal-header">
	<span class="aria-focus" tabindex="0"></span>
	<h3 class="modal-title" tabindex="0">
		<span *ngIf="phase === course_participants_phase.ParticipantAmount" tt>Number of participants</span>
		<span *ngIf="phase === course_participants_phase.ParticipantInfo" tt>Course participants</span>
		<span *ngIf="phase === course_participants_phase.ParticipantList" tt>Course participants</span>
		<span *ngIf="phase === course_participants_phase.Confirmation" tt>Confirmation</span>
		<span *ngIf="phase === course_participants_phase.Cancel" tt>Cancel pre-reservation</span>
	</h3>
	<button *ngIf="phase !== course_participants_phase.Cancel && !cancelling_pre_reservation"
	        type="button" class="close"
	        aria-label="Close"
	        (click)="openCancelConfirmation()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<form [formGroup]="form">
		<ng-container [ngSwitch]="phase">
			<ng-container *ngSwitchCase="course_participants_phase.ParticipantAmount">
				<div class="row p-2 participantsRow">
					<div class="col-4 title" tt>
						Number of participants
					</div>
					<div class="col-2 field">
						<input formControlName="participants" id="participants" type="number" tabindex="0"
						       [attr.aria-label]="'Number of participants' | tt">
					</div>
					<div class="col-6 buttons">
						<button (click)="removeParticipant()" type="submit" class="btn btn-info" tabindex="0"
						        [attr.aria-label]="('remove' | tt) + ' ' + ('participant' | tt)">
							<i class="fa fa-minus"></i>
						</button>
						<button (click)="addParticipant()" type="submit" class="btn btn-info" tabindex="0"
						        [attr.aria-label]="('add' | tt) + ' ' + ('participant' | tt)">
							<i class="fa fa-plus"></i>
						</button>
					</div>
				</div>
			</ng-container>

			<ng-container *ngSwitchCase="course_participants_phase.ParticipantInfo">
				<div *ngIf="pre_reservation_seconds > 0" class="timer">
					<span class="label label-secondary" tabindex="0">
						<span tt>Expires in</span> {{ pre_reservation_seconds * 1000 | date:'mm:ss' }}
					</span>
				</div>
				<shop-course-item [course]="course" [text_only]="true" [hide_label]="true" class="col-sm-6"></shop-course-item>
				<ng-container *ngIf="!save_participants_failed">
					<div *ngIf="hide_participants_amount_dialog" class="extra-information">
						<h4 tabindex="0" tt>Extra information</h4>
						<div class="title pb-2" tabindex="0" tt>Select participant</div>
						<label class="radio" tabindex="0">
							<input aria-hidden="true" formControlName="for_child" type="radio" [value]="false">
							<span aria-hidden="true" tt>Book course for myself</span>: {{ user?.name }}
							<span role="button" [attr.aria-label]="'Book course for myself' | tt"></span>
						</label>
						<label class="radio" tabindex="0">
							<input aria-hidden="true" formControlName="for_child" type="radio" [value]="true">
							<span aria-hidden="true" *ngIf="!show_payer_and_participants" tt>Book course for my child</span>
							<span aria-hidden="true" *ngIf="show_payer_and_participants" tt>Book course for another person</span>
							<span role="button" [attr.aria-label]="'Book course for another person' | tt"></span>
						</label>
						<div *ngIf="form.get('for_child').value && !show_payer_and_participants"
						     class="children p-2">
							<label *ngFor="let child of user?.linked_accounts" class="radio">
								<ng-container>
									<input formControlName="child" type="radio" [value]="child.account_id"
									       [attr.aria-label]="child.name">
									<span aria-hidden="true" tt>{{child.name}}</span>
								</ng-container>
							</label>
							<div class="add-new-child mt-4">
								<div class="title pb-2" tabindex="0" tt>Or enter a name</div>
								<div class="row p-2">
									<div class="col-sm-6">
										<span tt>First name</span><br>
										<input tabindex="0" [attr.aria-label]="'First name' | tt"
										       formControlName="first_name" id="first_name" type="text">
									</div>
									<div class="col-sm-6">
										<span tt>Last name</span><br>
										<input tabindex="0" [attr.aria-label]="'Last name' | tt"
										       formControlName="last_name" id="last_name" type="text">
									</div>
								</div>
								<div class="add-child">
									<button (click)="addNewChild()"
									        [disabled]="!form.get('first_name').value || !form.get('last_name').value"
									        type="submit"
									        class="btn btn-info" tt>
										Add
									</button>
								</div>
							</div>
						</div>
					</div>
					<ng-container *ngIf="show_payer_and_participants">
						<div *ngIf="payer_name_warning_label && form.get('for_child').value"
						     class="bg-warning p-2">
							{{ payer_name_warning_label }}
						</div>
						<hr *ngIf="hide_participants_amount_dialog">
						<div class="payer">
							<h4>
								<span tabindex="0" tt>Payer's info</span>
								<span *ngIf="!form.get('for_child').value &&
											(pre_reservation_response?.filtered_reservations[0]?.accessibility == constants.RES_UNCONFIRMED_QUEUE
											|| pre_reservation_response?.filtered_reservations[0]?.accessibility == constants.RES_CONFIRMED_QUEUE)"
								      class="label label-warning queueLabel ms-2" tt>Queue</span>
							</h4>
							<div class="rows p-2">
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tabindex="0" tt>
											<span aria-hidden="true">SSN</span>
											<span [attr.aria-label]="('SSN' | tt) + ' ' + form?.get('payer_ssn')?.value"></span>
										</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input [title]="'SSN' | tt" formControlName="payer_ssn" id="payer_ssn" type="text">
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>First name</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_first_name"
										       (blur)="setErrorVisible('payer_first_name')"
										       id="payer_first_name"
										       type="text"
										       [attr.aria-label]="('First name' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_first_name').valid
												&& !form.get('payer_first_name').pristine
												&& form_errors['payer_first_name']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>The field is required
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>Last name</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_last_name"
										       (blur)="setErrorVisible('payer_last_name')"
										       id="payer_last_name"
										       type="text"
										       [attr.aria-label]="('Last name' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_last_name').valid
												&& !form.get('payer_last_name').pristine
												&& form_errors['payer_last_name']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>The field is required
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>Street address</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_street"
										       (blur)="setErrorVisible('payer_street')"
										       id="payer_street"
										       type="text"
										       [attr.aria-label]="('Street address' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_street').valid
												&& !form.get('payer_street').pristine
												&& form_errors['payer_street']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>The field is required
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>Postal code</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_postal_code"
										       (blur)="setErrorVisible('payer_postal_code')"
										       id="payer_postal_code"
										       type="text"
										       [attr.aria-label]="('Postal code' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_postal_code').valid
												&& !form.get('payer_postal_code').pristine
												&& form_errors['payer_postal_code']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>Invalid postal code
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>City</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_city"
										       (blur)="setErrorVisible('payer_city')"
										       id="payer_city"
										       type="text"
										       [attr.aria-label]="('City' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_city').valid
												&& !form.get('payer_city').pristine
												&& form_errors['payer_city']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>The field is required
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>Phone</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_phone"
										       (blur)="setErrorVisible('payer_phone')"
										       id="payer_phone"
										       type="text"
										       [attr.aria-label]="('Phone' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_phone').valid
												&& !form.get('payer_phone').pristine
												&& form_errors['payer_phone']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>Invalid phone number
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>Email</span>*
									</div>
									<div class="col-sm-9 rowField">
										<input formControlName="payer_email"
										       (blur)="setErrorVisible('payer_email')"
										       id="payer_email"
										       type="text"
										       [attr.aria-label]="('Email' | tt) + ' ' + ('The field is required' | tt)">
										<div *ngIf="!form.get('payer_email').valid
												&& !form.get('payer_email').pristine
												&& form_errors['payer_email']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>Invalid email
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 rowLabel">
										<span tt>Gender</span>*
									</div>
									<div class="col-sm-9 rowField">
										<select [formControlName]="'payer_gender'"
										        (blur)="setErrorVisible('payer_gender')"
										        [name]="'payer_gender'"
										        [id]="'payer_gender'"
										        [attr.aria-label]="('Gender' | tt) + ' ' + ('The field is required' | tt)">
											<option value=""></option>
											<option *ngFor="let gender of genders" [value]="gender.gender_id">
												{{ gender.name | tt }}
											</option>
										</select>
										<div *ngIf="!form.get('payer_gender').pristine
												&& !form.get('payer_gender').value
												&& form_errors['payer_gender']"
										     tabindex="0"
										     class="text-danger errorMsg" tt>The field is required
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr>
						<div *ngIf="form.get('for_child').value" class="participants">
							<div *ngFor="let pre_reservation of pre_reservation_response?.filtered_reservations; let i = index" class="rows">
								<ng-container>
									<h4>
										<span tabindex="0" tt>
											<span aria-hidden="true">Participant</span>
											<span [attr.aria-label]="('Participant' | tt) + ' ' + (i + 1)"></span>
										</span> {{ i + 1 }}
										<span *ngIf="pre_reservation.accessibility == constants.RES_UNCONFIRMED_QUEUE
											|| pre_reservation.accessibility == constants.RES_CONFIRMED_QUEUE"
										      class="label label-warning queueLabel ms-2" tt>Queue</span>
									</h4>
									<div class="p-2">
										<div class="pb-4" *ngIf="!hide_participants_amount_dialog && i === 0">
											<button (click)="usePayersInfo()" type="submit" class="btn btn-info" tt>
												Use payer's information
											</button>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>SSN</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_ssn'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_ssn')"
												       [id]="'participant' + (i + 1) + '_ssn'"
												       type="text"
												       [attr.aria-label]="('SSN' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_ssn').valid
														&& !form.get('participant' + (i + 1) + '_ssn').pristine
														&& form_errors['participant' + (i + 1) + '_ssn']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													Invalid SSN
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>First name</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_first_name'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_first_name')"
												       [id]="'participant' + (i + 1) + '_first_name'"
												       type="text"
												       [attr.aria-label]="('First name' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_first_name').valid
														&& !form.get('participant' + (i + 1) + '_first_name').pristine
														&& form_errors['participant' + (i + 1) + '_first_name']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													The field is required
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>Last name</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_last_name'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_last_name')"
												       [id]="'participant' + (i + 1) + '_last_name'"
												       type="text"
												       [attr.aria-label]="('Last name' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_last_name').valid
														&& !form.get('participant' + (i + 1) + '_last_name').pristine
														&& form_errors['participant' + (i + 1) + '_last_name']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													The field is required
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>Street address</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_street'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_street')"
												       [id]="'participant' + (i + 1) + '_street'"
												       type="text"
												       [attr.aria-label]="('Street address' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_street').valid
														&& !form.get('participant' + (i + 1) + '_street').pristine
														&& form_errors['participant' + (i + 1) + '_street']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													The field is required
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>Postal code</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_postal_code'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_postal_code')"
												       [id]="'participant' + (i + 1) + '_postal_code'"
												       type="text"
												       [attr.aria-label]="('Postal code' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_postal_code').valid
														&& !form.get('participant' + (i + 1) + '_postal_code').pristine
														&& form_errors['participant' + (i + 1) + '_postal_code']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													Invalid postal code
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>City</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_city'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_city')"
												       [id]="'participant' + (i + 1) + '_city'"
												       type="text"
												       [attr.aria-label]="('City' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_city').valid
														&& !form.get('participant' + (i + 1) + '_city').pristine
														&& form_errors['participant' + (i + 1) + '_city']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													The field is required
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>Phone</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_phone'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_phone')"
												       [id]="'participant' + (i + 1) + '_phone'"
												       type="text"
												       [attr.aria-label]="('Phone' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_phone').valid
														&& !form.get('participant' + (i + 1) + '_phone').pristine
														&& form_errors['participant' + (i + 1) + '_phone']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													Invalid phone number
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>Email</span>*
											</div>
											<div class="col-sm-9 rowField">
												<input [formControlName]="'participant' + (i + 1) + '_email'"
												       (blur)="setErrorVisible('participant' + (i + 1) + '_email')"
												       [id]="'participant' + (i + 1) + '_email'"
												       type="text"
												       [attr.aria-label]="('Email' | tt) + ' ' + ('The field is required' | tt)">
												<div *ngIf="!form.get('participant' + (i + 1) + '_email').valid
														&& !form.get('participant' + (i + 1) + '_email').pristine
														&& form_errors['participant' + (i + 1) + '_email']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													Invalid email
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-3 rowLabel">
												<span tt>Gender</span>*
											</div>
											<div class="col-sm-9 rowField">
												<select [formControlName]="'participant' + (i + 1) + '_gender'"
												        (blur)="setErrorVisible('participant' + (i + 1) + '_gender')"
												        [name]="'participant' + (i + 1) + '_gender'"
												        [id]="'participant' + (i + 1) + '_gender'"
												        [attr.aria-label]="('Gender' | tt) + ' ' + ('The field is required' | tt)">
													<option value=""></option>
													<option *ngFor="let gender of genders" [value]="gender.gender_id">
														{{ gender.name | tt }}
													</option>
												</select>
												<div *ngIf="!form.get('participant' + (i + 1) + '_gender').value
												      && !form.get('participant' + (i + 1) + '_gender').pristine
														&& form_errors['participant' + (i + 1) + '_gender']"
												      tabindex="0"
												      class="text-danger errorMsg" tt>
													The field is required
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngSwitchCase="course_participants_phase.ParticipantList">
				<h4 role="heading" aria-level="1" tabindex="0" tt>Summary</h4>
				<div class="payerSummary">
					<div class="row" tabindex="0">
						<div class="col-sm-3 rowLabel">
							<span class="font-weight-bold" tt>Payer</span>:
						</div>
						<div class="col-sm-9 rowField" role="heading" aria-level="2">
							{{ save_participants_response.user.first_name }} {{ save_participants_response.user.last_name }}
						</div>
					</div>
					<div class="row" tabindex="0">
						<div class="col-sm-3 rowLabel">
							<span tt>Phone</span>:
						</div>
						<div class="col-sm-9 rowField">
							{{ save_participants_response.user.phone_number }}
						</div>
					</div>
					<div class="row" tabindex="0">
						<div class="col-sm-3 rowLabel">
							<span tt>Address</span>:
						</div>
						<div class="col-sm-9 rowField">
							{{ save_participants_response.user.address.street }}
							{{ save_participants_response.user.address.postcode }}
							{{ save_participants_response.user.address.city }}
						</div>
					</div>
					<div class="row" tabindex="0">
						<div class="col-sm-3 rowLabel">
							<span tt>Social security number</span>:
						</div>
						<div class="col-sm-9 rowField">
							{{ save_participants_response.user.social_security_number }}
						</div>
					</div>
					<div class="row" tabindex="0">
						<div class="col-sm-3 rowLabel">
							<span tt>Email</span>:
						</div>
						<div class="col-sm-9 rowField">
							{{ save_participants_response.user.email }}
						</div>
					</div>
				</div>
				<hr>
				<div class="participantSummary">
					<div *ngFor="let participant of save_participants_response.participants; let i = index" class="rows participant">
						<div class="row">
							<div class="col-sm-3 rowLabel" tabindex="0">
								<span class="font-weight-bold" aria-hidden="true"><span tt>Participant</span> {{ i + 1 }}</span>:
								<span role="heading" aria-level="2" [attr.aria-label]="('Participant' | tt) + ' ' + i + 1 + ' ' +
								      participant.first_name + ' ' + participant.last_name"></span>
							</div>
							<div class="col-sm-9 rowField">
								{{ participant.first_name }} {{ participant.last_name }}
								<span *ngIf="participant.reservation_status_id == constants.RES_UNCONFIRMED_QUEUE
									|| participant.reservation_status_id == constants.RES_CONFIRMED_QUEUE"
								      class="label label-warning queueLabel ms-2" tt>Queue</span>
							</div>
						</div>
						<div class="row" tabindex="0">
							<div class="col-sm-3 rowLabel">
								<span tt>Phone</span>:
							</div>
							<div class="col-sm-9 rowField">
								{{ participant.phone_number }}
							</div>
						</div>
						<div class="row" tabindex="0">
							<div class="col-sm-3 rowLabel">
								<span tt>Address</span>:
							</div>
							<div class="col-sm-9 rowField">
								{{ participant.address.street }}
								{{ participant.address.postcode }}
								{{ participant.address.city }}
							</div>
						</div>
						<div class="row" tabindex="0">
							<div class="col-sm-3 rowLabel">
								<span tt>Social security number</span>:
							</div>
							<div class="col-sm-9 rowField">
								{{ participant.social_security_number }}
							</div>
						</div>
						<div class="row" tabindex="0">
							<div class="col-sm-3 rowLabel">
								<span tt>Email</span>:
							</div>
							<div class="col-sm-9 rowField">
								{{ participant.email }}
							</div>
						</div>
						<hr>
					</div>
				</div>
				<div class="price">
					<h4 tabindex="0" tt>Price</h4>
					<ng-container *ngFor="let participant of save_participants_response.participants; let i = index"
					              class="participant">
						<div class="title ps-0" tabindex="0">
							<span tt>Participant</span> {{ i + 1 }}: {{ participant.first_name }} {{ participant.last_name }}
						</div>
						<ng-container
							*ngFor="let fp of save_participants_response.campaign_fare_products[participant.account_id]; let u = index">
							<div *ngIf="participant.age >= fp.min_age && participant.age <= fp.max_age">
								<div class="fareProducts">
									<div class="row" tabindex="0">
										<div class="col-2 rowLabel ps-4">
											<input [title]="'Fare product' | tt"
											       [formControlName]="'participant' + (i + 1) + '_fare_product'"
											       type="radio"
											       [id]="i"
											       [value]="fp.fare_product_id">
										</div>
										<div class="col-10 rowField ps-4">
											<span class="fareProductName">{{ fp.name }}</span>
											<span class="label label-success ms-2">{{ fp.campaign_name }}</span>
											<br><span tt>Price</span>: {{ fp.price | centstoeuros }}€
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						<hr>
					</ng-container>
				</div>
			</ng-container>

			<ng-container *ngSwitchCase="course_participants_phase.Confirmation">
				<ng-container *ngIf="!queue_confirm_error">
					<div *ngIf="queue_places_confirmed">
						<span class="queue-confirm text-success" tabindex="0" tt>
							Your queue places have been confirmed
						</span>
					</div>
					<div *ngIf="reservation_type === course_participants_reservation_type.MixedReservation">
						<span class="queue-confirm text-success" tabindex="0">
							<span tt>You still have unpaid reservations</span>!
						</span>
					</div>
				</ng-container>
				<div *ngIf="queue_confirm_error">
					<span class="queue-confirm text-danger" tabindex="0" tt>
						{{ queue_confirm_error }}
					</span>
				</div>
			</ng-container>

			<ng-container *ngSwitchCase="course_participants_phase.Cancel">
				<span class="query" tabindex="0" tt>Are you sure you want to cancel the pre-reservation</span>?
			</ng-container>

			<ng-template #loading>
				<div style="width: 100%; text-align: center">
					<h2 tt>Loading</h2>
					<div class="spinner-border" role="status">
						<span class="sr-only"></span>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</form>
	<ng-container *ngIf="phase !== course_participants_phase.Cancel">
		<shop-alert-message *ngIf="!age_check?.failed_participants.length" [alert]="alert"></shop-alert-message>
		<ng-container *ngFor="let failed_participant of age_check?.failed_participants">
			<shop-alert-message [alert]="failed_participant.alert"></shop-alert-message>
		</ng-container>
	</ng-container>
</div>

<div class="modal-footer">
	<button *ngIf="phase !== course_participants_phase.Cancel && !save_participants_failed"
		     class="btn"
	        id="cancelButton"
	        (click)="openCancelConfirmation()">
		<span *ngIf="!queue_places_confirmed" tt>Cancel</span>
		<span *ngIf="queue_places_confirmed" tt>Close</span>
	</button>

	<ng-container [ngSwitch]="phase">
		<ng-container *ngSwitchCase="course_participants_phase.ParticipantAmount">
			<button [disabled]="loading_pre_reservation || !user || alert
					  || !form.get('participants').valid || cancelling_pre_reservation"
			        (click)="makePreReservation()"
			        type="submit" class="btn btn-success">
				<span *ngIf="!loading_pre_reservation && user && !cancelling_pre_reservation" tt>Next</span>
				<span *ngIf="loading_pre_reservation || !user || cancelling_pre_reservation" tt>Loading</span>
			</button>
		</ng-container>

		<ng-container *ngSwitchCase="course_participants_phase.ParticipantInfo">
			<button [disabled]="loading_pre_reservation
					  || loading_save_participants
					  || save_participants_failed"
			        (click)="save_participants_failed ? activeModal.dismiss() : goToParticipantList()"
			        type="submit" class="btn"
			        [ngClass]="{ 'btn-success': !save_participants_failed, 'btn-danger': save_participants_failed }">
				<span>
					<ng-container *ngIf="!save_participants_failed">
						<span *ngIf="!loading_pre_reservation" tt>Next</span>
						<span *ngIf="loading_pre_reservation" tt>Loading</span>
					</ng-container>
					<ng-container *ngIf="save_participants_failed">
						<span tt>Cancel prereservation</span>
					</ng-container>
				</span>
			</button>
		</ng-container>

		<ng-container *ngSwitchCase="course_participants_phase.ParticipantList">
			<ng-container *ngIf="!queue_places_confirmed">
				<button *ngIf="reservation_type === course_participants_reservation_type.NormalReservation"
				        [disabled]="!form.valid || alert || loading_add_to_cart" (click)="tryAddProduct()"
				        type="submit" class="btn btn-success" tt>
					Add to cart
				</button>
				<button *ngIf="reservation_type === course_participants_reservation_type.QueueReservation"
				        [disabled]="!form.valid || alert || loading_confirm_queue"
				        (click)="confirmQueue()"
				        type="submit" class="btn btn-warning">
					{{ (unConfirmedQueueSpots > 1 ? 'Confirm queue spots' : 'Confirm queue spot') | tt }}
				</button>
				<button *ngIf="reservation_type === course_participants_reservation_type.MixedReservation"
				        [disabled]="!form.valid || alert || loading_confirm_queue"
				        (click)="confirmQueue(true)"
				        type="submit" class="btn btn-warning">
					{{ (unConfirmedQueueSpots > 1
						? 'Confirm queue spots and proceed to payment'
						: 'Confirm queue spot and proceed to payment') | tt
					}}
				</button>
			</ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="course_participants_phase.Confirmation">
			<ng-container [ngSwitch]="reservation_type">
				<ng-container *ngSwitchCase="course_participants_reservation_type.QueueReservation">
					<button (click)="activeModal.dismiss()"
					        type="submit" class="btn btn-info" tt>
						Ok
					</button>
				</ng-container>
				<ng-container *ngSwitchCase="course_participants_reservation_type.MixedReservation">
					<button *ngIf="!queue_confirm_error" (click)="activeModal.dismiss()"
					        type="submit" class="btn btn-info" tt>
						Open shopping cart
					</button>
				</ng-container>
			</ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="course_participants_phase.Cancel">
			<button (click)="returnToPreviousPhase()"
			        type="submit" class="btn btn-info" tt>
				No
			</button>
			<button (click)="activeModal.dismiss()"
			        type="submit" class="btn btn-danger" tt>
				Yes
			</button>
		</ng-container>
	</ng-container>
</div>
