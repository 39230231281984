import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import {
	AutoSubs,
	AutoUnsubscribe,
} from 'shared';

import { CourseItem } from '../../main/courses/course-item/course-item.component';
import {
	ShopParametersService,
} from '../../services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@AutoUnsubscribe()
@Component({
	templateUrl   : './course-info.modal.html',
	encapsulation : ViewEncapsulation.None,
	styleUrls     : ['./course-info.modal.scss']
})
export class CourseInfoModal implements OnInit, OnDestroy {
	@AutoSubs() public subs;
	public course: CourseItem;

	@Input() set input({ course }: {
		course: CourseItem
	})
	{
		this.course = course;
	}

	constructor(
		public activeModal: NgbActiveModal,
	   public params: ShopParametersService,
	) {

	}

	ngOnInit(): void
	{

	}

	ngOnDestroy(): void
	{

	}
}
