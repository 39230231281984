import { Component, Input, OnInit } from '@angular/core';
import { each } from 'lodash';
import { combineLatest } from 'rxjs';

import { AuthenticationService, CallService, Constants, ModalOpenerService } from 'shared';

import {
	EventGroup,
	ReservationEventGroup, ShopCourseEventGroupsService,
	ShopReservationEventGroupsService
} from '../../services';
import { CourseInfoModal } from '../../modals';

@Component({
	selector    : 'shop-user-reservation-event-groups',
	templateUrl : './reservation-event-groups.component.html',
	styleUrls   : ['./reservation-event-groups.component.scss']
})
export class UserReservationEventGroupsComponent implements OnInit {

	@Input() past_allowed = true;
	public constants = Constants;
	public show_past = false;
	public past_count = 0;
	public reservation_events: ReservationEventGroup[] = [];
	private all_reservation_event_groups: EventGroup[] = [];
	private _reservation_event_groups: ReservationEventGroup[] = [];

	get reservation_event_groups(): ReservationEventGroup[]
	{
		return this._reservation_event_groups;
	}

	set reservation_event_groups(event_groups: ReservationEventGroup[]) {
		this._reservation_event_groups = event_groups;
	}

	constructor(
		public auth: AuthenticationService,
		public reservationEventGroupsService: ShopReservationEventGroupsService,
		private modalHelper: ModalOpenerService,
		private call: CallService,
		private eventGroupService: ShopCourseEventGroupsService,
	) {

		combineLatest([
			this.reservationEventGroupsService.get(),
			this.call.make('reservation2/getR2EventGroups'),
		]).subscribe(([event_groups, all_event_groups]) => {
			this.reservation_events = event_groups.filter(eg =>
				eg.reserving_account_id == this.auth.value.account_id || eg.reservation_account_id == this.auth.value.account_id);

			this.all_reservation_event_groups = all_event_groups;

			this.loadReservationEventsGroups(this.reservation_events);
		});
	}

	ngOnInit(): void
	{
	}

	togglePast(): void
	{
		this.show_past = !this.show_past;
	}

	private loadReservationEventsGroups(value: ReservationEventGroup[])
	{
		this._reservation_event_groups = value;

		let past_count = 0;
		each(value, (reg: ReservationEventGroup) => {
			if (reg.is_past) past_count++;
		});

		this.past_count = past_count;
	}

	openCourse(course_index: number): void
	{
		const selected = this._reservation_event_groups[course_index];
		const reservation_event_group = this.all_reservation_event_groups.find(
			reg => +reg.reservation_event_group_id == +selected.reservation_event_group_id);

		const modalRef = this.modalHelper.openStaticShopModal(
			CourseInfoModal,
			{ keyboard : false }
		);

		const course_item = this.eventGroupService.processEventGroups([reservation_event_group])[0];

		modalRef.componentInstance.input = {
			course    : course_item
		};
	}
}
