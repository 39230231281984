import { cloneDeep, each, keyBy, times, values } from 'lodash';
import * as moment from 'moment';

import {
	AccessArea,
	AccessAreaPermissionRelations,
	MachinePermissionRelations,
	PermissionGroupModel,
	Schedule,
	ScheduleAccessRight
} from '../../interfaces';
import { TaTimetableListService } from '../../../protected-modules/time-attendance/services';

export interface RawPermissionGroupRow {
	access_right_template_group_id: string;
	access_right_template_group_name: string;

	access_right_template_id: string;
	access_right_template_name: string;

	valid_from_date: string;
	valid_until_date: string;
	access_right_expiration: string;

	service_at_area_id: string;
	service_group_id: string;

	timetable_id: string;
	is_machine: number;
	service_id: string;

	access_area_id: string;
	access_area_name: string;

	employee_count: number;
	is_active: boolean;
}

export class TaPermissionHelper {

	public static rescaleMachinePermissionRelations(old_relation: MachinePermissionRelations,
	                                                selectedMachines: AccessArea[] = null): MachinePermissionRelations
	{
		if (!selectedMachines) {
			return old_relation; // nothing can be done
		}

		const result: MachinePermissionRelations = {
			machines     : cloneDeep(selectedMachines),
			is_permitted : []
		};
		result.is_permitted = times(result.machines.length, () => false);

		const machines_map: any = keyBy(selectedMachines, 'service_at_area_id');
		each(result.machines, (aa, i) => machines_map[aa.service_at_area_id].index = i);

		const getIndex = (el: any) => el ? el.index : null;
		if (old_relation) {
			for (let i = 0; i < old_relation.machines.length; ++i) {
				if (old_relation.is_permitted[i]) {
					const row = getIndex(machines_map[old_relation.machines[i].service_at_area_id]);

					if (row !== null) {
						result.is_permitted[row] = true;
					}
				}
			}
		}

		return result;
	}

	public static rescaleAccessAreaPermissionRelations(old_relation: AccessAreaPermissionRelations,
	                                                   selectedTimetables: ScheduleAccessRight[] = null,
	                                                   selectedAccessAreas: AccessArea[] = null): AccessAreaPermissionRelations
	{
		if (!selectedTimetables || !selectedAccessAreas) {
			return old_relation; // nothing can be done
		}

		const timetable_map: any = keyBy(selectedTimetables, 'timetable_id');
		const access_area_map: any = keyBy(selectedAccessAreas, 'access_area_id');

		const result: AccessAreaPermissionRelations = {
			access_areas : cloneDeep(selectedAccessAreas),
			timetables   : cloneDeep(selectedTimetables),
			is_permitted : []
		};

		each(result.access_areas, (aa, i) => access_area_map[aa.access_area_id].index = i);
		each(result.timetables, (tt, i) => timetable_map[tt.timetable_id].index = i);

		result.is_permitted = times(result.access_areas.length, () => times(result.timetables.length, () => false));

		const getIndex = (el: any) => el ? el.index : null;
		if (old_relation) {
			for (let i = 0; i < old_relation.access_areas.length; ++i) {
				for (let j = 0; j < old_relation.timetables.length; ++j) {
					if (old_relation.is_permitted[i][j]) {
						const row = getIndex(access_area_map[old_relation.access_areas[i].access_area_id]);
						const col = getIndex(timetable_map[old_relation.timetables[j].timetable_id]);

						if (row !== null && col !== null) {
							result.is_permitted[row][col] = true;
						}
					}
				}
			}
		}

		return result;
	}

	public static joinPermissionGroups(groups: PermissionGroupModel[])
	{
		let timetable_map: { [key: string]: ScheduleAccessRight } = {};
		let access_area_map: { [key: string]: AccessArea } = {};
		let machine_map: { [key: string]: AccessArea } = {};

		each(groups, group => {
			const aat = group.access_area_table;

			timetable_map = { ...timetable_map, ...keyBy(aat.timetables, 'timetable_id') };
			access_area_map = { ...access_area_map, ...keyBy(aat.access_areas, 'access_area_id') };
			machine_map = { ...machine_map, ...keyBy(group.machine_table.machines, 'service_at_area_id') };
		});

		const timetables = values(timetable_map);
		const access_areas = values(access_area_map);
		const machines = values(machine_map);

		const aa_is_permitted = times(access_areas.length, () => times(timetables.length, () => false));
		const m_is_permitted = times(machines.length, () => false);

		each(groups, group => {
			const access_area_table =
				this.rescaleAccessAreaPermissionRelations(group.access_area_table, timetables, access_areas);
			const machine_table =
				this.rescaleMachinePermissionRelations(group.machine_table, machines);

			for (let i = 0; i < access_areas.length; ++i) {
				for (let j = 0; j < timetables.length; ++j) {

					if (access_area_table.is_permitted[i][j]) aa_is_permitted[i][j] = true;
				}
			}

			for (let i = 0; i < machines.length; ++i) {
				if (machine_table.is_permitted[i]) m_is_permitted[i] = true;
			}
		});

		return {
			id                      : '-1',
			name                    : 'Some test name',
			valid_from_date         : moment(),
			valid_until_date        : moment(),
			access_right_expiration : moment(),
			employee_count          : 0,
			is_active               : false,
			access_area_table       : {
				access_areas,
				timetables,
				is_permitted : aa_is_permitted
			},
			machine_table     : {
				machines,
				is_permitted : m_is_permitted
			}
		};
	}

	public static extractRawPermissionGroups(raw_input: RawPermissionGroupRow[],
	                                         global_timetables_map: { [id: string]: Schedule } = {})
	{
		const group_map: { [id: string]: PermissionGroupModel } = {};

		const group_access_areas_map: { [group_id: string]: { [access_area_id: string]: AccessArea } } = {};
		const group_timetables_map: { [group_id: string]: { [timetable_id: string]: ScheduleAccessRight } } = {};
		const group_aa_tt_table_map: {
			[group_id: string]: { [access_area_id: string]: { [timetable_id: string]: boolean } }
		} = {};

		const group_machines_map: { [id: string]: { [id: string]: AccessArea } } = {};

		each(raw_input, (row: RawPermissionGroupRow) => {

			const id = row.access_right_template_group_id;
			if (!group_map[id]) {

				group_map[id] = {
					id,
					name                    : row.access_right_template_group_name,
					access_right_expiration : row.access_right_expiration ? moment(row.access_right_expiration) : null,
					valid_from_date         : moment(row.valid_from_date),
					valid_until_date        : moment(row.valid_until_date),
					employee_count          : row.employee_count,
					is_active               : row.is_active,
					access_area_table       : {
						access_areas : [],
						timetables   : [],
						is_permitted : []
					},
					machine_table       : {
						machines     : [],
						is_permitted : []
					}
				};

				group_access_areas_map[id] = {};
				group_timetables_map[id] = {};
				group_machines_map[id] = {};
				group_aa_tt_table_map[id] = {};
			}

			if (!row.is_machine) {

				const access_areas_map = group_access_areas_map[id];
				const timetables_map = group_timetables_map[id];
				const aa_tt_table_map = group_aa_tt_table_map[id];

				if (!access_areas_map[row.access_area_id]) {
					access_areas_map[row.access_area_id] = {
						service_at_area_id : row.service_at_area_id,
						access_area_id     : row.access_area_id,
						service_id         : row.service_id,
						is_machine         : false,
						name               : row.access_area_name
					};
				}

				if (!timetables_map[row.timetable_id]) {
					const timetable = global_timetables_map[row.timetable_id] || TaTimetableListService.emptySchedule;

					timetables_map[row.timetable_id] = {
						...timetable,
						access_right_template_id   : row.access_right_template_id,
						access_right_template_name : row.access_right_template_name
					};
				}

				aa_tt_table_map[row.access_area_id] = aa_tt_table_map[row.access_area_id] || {};
				aa_tt_table_map[row.access_area_id][row.timetable_id] = true;
			} else {
				const machines_map = group_machines_map[id];

				if (!machines_map[row.service_at_area_id]) {
					machines_map[row.service_at_area_id] = {
						service_at_area_id       : row.service_at_area_id,
						access_area_id           : row.access_area_id,
						service_id               : row.service_id,
						is_machine               : true,
						access_right_template_id : row.access_right_template_id,
						name                     : row.access_area_name
					};
				}
			}
		});

		each(group_map, (group: PermissionGroupModel, group_id) => {

			const access_areas = values(group_access_areas_map[group_id]);
			const timetables = values(group_timetables_map[group_id]);

			group.access_area_table =
				TaPermissionHelper.rescaleAccessAreaPermissionRelations(null, timetables, access_areas);

			const aa_tt_table_map = group_aa_tt_table_map[group_id];

			each(group.access_area_table.access_areas, (aa, i) => {
				each(group.access_area_table.timetables, (tt, j) => {
					if (aa_tt_table_map[aa.access_area_id] && aa_tt_table_map[aa.access_area_id][tt.timetable_id]) {
						group.access_area_table.is_permitted[i][j] = true;
					}
				});
			});

			group.machine_table =
				TaPermissionHelper.rescaleMachinePermissionRelations(null, values(group_machines_map[group_id]));

			each(group.machine_table.machines, (m, i) => {
				group.machine_table.is_permitted[i] = true;
			});
		});

		return values(group_map);
	}
}
