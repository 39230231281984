import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ExistingProvider, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
	providedIn : 'root'
})

export class PendingInterceptorService implements HttpInterceptor {
	private _pendingRequestsStatus$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _pendingRequests = 0;

	get pendingRequests() : number
	{
		return this._pendingRequests;
	}

	get pendingRequestsStatus$() : Observable<boolean>
	{
		return this._pendingRequestsStatus$.asObservable();
	}

	intercept(req : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>>
	{
		this._pendingRequests++;

		if (1 === this._pendingRequests) {
			this._pendingRequestsStatus$.next(true);
		}

		return next.handle(req).pipe(
				map(event => {
					return event;
				}),
				catchError(error => {
					return throwError(error);
				}),
				finalize(() => {
					this._pendingRequests--;

					if (0 === this._pendingRequests) {
						this._pendingRequestsStatus$.next(false);
					}
				})
		);
	}
}

export const PendingInterceptorServiceInterceptor : ExistingProvider[] = [{
	provide     : HTTP_INTERCEPTORS,
	useExisting : PendingInterceptorService,
	multi       : true
}];
