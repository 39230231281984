/**
 * Returns price without vat
 * @param gross_amount
 * @param vat_percentage
 * @returns {number}
 * @constructor
 */
export function NetAmount(gross_amount: number, vat_percentage: number): number {
    return Math.round(gross_amount / ((vat_percentage / 100) + 1));
}
