import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, each, isEqual, map, some, uniqBy } from 'lodash';
import { debounceTime, first, switchMap } from 'rxjs/operators';

import { AutoSubs, AutoUnsubscribe, CallService, EnkoraMessageService, Helpers, LocationService, Logger } from 'shared';

import {
	TaActiveDataHolderService,
	TaDataHelper,
	TaFormGroupGeneratorService,
	TaQualification
} from '../../../../../shared';
import { TaEmployeeInfoService, TaTaitoQualificationsService } from '../../../services';

declare let Enkora: { tt: (a: string) => string };

@AutoUnsubscribe()
@Component({
	templateUrl : './qualifications.modal.html',
	styleUrls   : ['./qualifications.modal.scss']
})
export class QualificationsModal implements OnInit, OnDestroy {
	@AutoSubs() subs;

	@Input() public standalone = true;
	public is_multiple = false;

	public latest_value: TaQualification[] = null;
	public initial_value: TaQualification[] = null;

	public is_valid = false;
	public no_changes = true;
	public save_message = '';

	public all_employers = [];

	public qualificationsForm: FormArray;
	public default_organization = TaDataHelper.emptyCompanyInfo;
	public msg_updated_from_taito = '';

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService,
	            private formGenerator: TaFormGroupGeneratorService,
	            public dataHolder: TaActiveDataHolderService,
	            private location: LocationService,
	            private employeeInfo: TaEmployeeInfoService,
	            public taitoQualifications: TaTaitoQualificationsService)
	{
	}

	ngOnInit(): void
	{
		if (this.dataHolder.is_add_new_employee) {
			this.dataHolder.qualifications = [];
		}
		this.getQualifications([]);
	}

	ngOnDestroy(): void
	{
	}

	initQualificationsForm(qualifications: TaQualification[]): void
	{
		this.qualificationsForm = this.formGenerator.initQualifications(qualifications);

		this.qualificationsForm.valueChanges.pipe(debounceTime(300)).subscribe((data: TaQualification[]) => {
			this.handleQualificationDataChange({ status : this.qualificationsForm.status, data });
		});

		this.handleQualificationDataChange({
			status : this.qualificationsForm.status,
			data   : this.qualificationsForm.getRawValue()
		});
	}

	back(): void
	{
		this.activeModal.dismiss('back');
	}

	confirm(): void
	{
		if (!this.is_valid) return;

		if (this.no_changes) {
			this.activeModal.close();
			return;
		}

		this.saveQualifications();
	}

	handleQualificationDataChange(params: { status: string, data: TaQualification[] }): void
	{
		this.is_valid = params.status != 'INVALID';
		this.latest_value = params.data;
		if (!this.initial_value) this.initial_value = cloneDeep(params.data);

		this.no_changes = isEqual(this.initial_value, this.latest_value);
		this.save_message = this.no_changes ? '' : 'Save and ';

		Logger.log('Form: Modified qualification data: ', params);
	}

	saveQualifications(): void
	{
		this.location.getLocationId().pipe(first()).subscribe(location_id => {
			const employee_tags = map(this.latest_value, tag => ({
				tag_id         : tag.tag_id,
				employee_id    : tag.employee_id,
				contact_person : tag.contact_person,
				start_date     : Helpers.getServerDateFormat(tag.start_date),
				end_date       : Helpers.getServerDateFormat(tag.end_date),
				value          : tag.value
			}));

			const params = {
				account_id : this.dataHolder.personal_info.account_id,
				location_id,
				employee_tags
			};

			this.call.make('cta2/saveEmployeeTags', [params]).subscribe(() => {
				this.latest_value = uniqBy(this.latest_value, 'name');
				this.activeModal.close(this.latest_value);
			}, error => {
				this.messageService.error(error);
			});
		});
	}

	searchFromTaito(): void
	{
		const params = {
			...this.dataHolder.personal_info,
			employees  : this.dataHolder.employee.employments.employers,
			tax_number : this.dataHolder.personal_info.tax_number
		};

		this.taitoQualifications.getOnce([params]).subscribe(result => {
			const qualifications = this.dataHolder.qualifications;
			const qualifications_to_add = [];
			each(result, qualification => {
				if (!some(qualifications, ['account_tag_id', qualification.account_tag_id])) {
					qualifications_to_add.push(qualification);
				}
			});

			this.msg_updated_from_taito = qualifications_to_add.length
				? `${qualifications_to_add.length} ${Enkora.tt('qualifications have been added')}`
				: Enkora.tt('No qualifications were found for this employee');

			this.getQualifications(qualifications_to_add);
		}, error => {
			this.messageService.error(error);
		});
	}

	getQualifications(qualifications_to_add: TaQualification[]): void
	{
		this.subs = this.dataHolder.qualifications$.pipe(
			switchMap((qualifications) => {
				this.initQualificationsForm([...qualifications, ...qualifications_to_add]);
				return this.location.getLocation();
			}),
			switchMap((location) => {
				this.default_organization = TaDataHelper.extractCompanyInfo(location);
				const employee_params = {
					user_id     : this.dataHolder.personal_info.user_id,
					tax_number  : this.dataHolder.personal_info.tax_number,
					location_id : location.location_id
				};
				return this.employeeInfo.get(true, employee_params);
			})
		).subscribe(employeeInfo => {
			const all_employers = [];
			each(employeeInfo.employments.employers, employer => {
				if (employer.company.company_info.organization_id && employer.is_active) {
					all_employers.push({ employee_id : employer.employee_id, name : employer.company.company_info.name });
				}
			});

			if (!all_employers.length) {
				all_employers.push({
					employee_id : this.default_organization.organization_id,
					name        : this.default_organization.name
				});
			}

			this.all_employers = all_employers;
		});
	}

	toggleFrom(): void
	{
		this.is_multiple = !this.is_multiple;
	}
}
