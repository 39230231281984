import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Logger } from 'shared';

@Injectable()
export class PasswordResetGuard implements CanActivate {

	constructor()
	{
	}

	canActivate(): boolean
	{

		alert('Hashcheck successful from email link - mock.');
		Logger.log('Password reset guard active.');
		return true;

	}
}
