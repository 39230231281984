import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export enum LoginState {
	Close,
	Login,
	Register,
	Reminder,
}

export interface LoginStepsData {
	state: LoginState;
	value?: any;
}

export interface ShopFieldInput {
	id?: string;
	name: string;
	is_required?: boolean;
	is_editable?: boolean;
	ui_order?: number;
	description?: string;
	possible_values?: any[];
	type?: string;
	subtype?: string;
	value?: any;
	field_group?: string;
	validation_regex?: string;
	min_date?: NgbDateStruct;
	max_date?: NgbDateStruct;
}

export interface ShopCustomField {
	data_type: string;
	description: string;
	field_name: string;
	is_customer_editable: number;
	is_required: number;
	ui_order: number;
	validation_regex: string;
}

export interface ShopField extends ShopFieldInput {
	rootForm: FormGroup;
	form: FormControl;
}

export interface ShopFields {
	rootForm: FormGroup;
	fields: ShopField[];
}
