import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { clone, forEach, upperFirst, values } from 'lodash';

import { CallService, LoaderModalService } from '../../shared';


@Injectable()
export class SearchAgreementContactsService {
	constructor(private call : CallService,
	            private loader : LoaderModalService)
	{
	}

	private _last_search : any = [];

	get last_search() : any
	{
		return this._last_search;
	}

	set last_search(value : any)
	{
		this._last_search = value;
	}

	findAgreements(search_string, is_resource_reservation_allowed : number = 1, is_search_by_organization_id : number = 0)
	{
		let search : string = clone(search_string);
		let searchUpper = upperFirst(search);

		this.loader.start();
		return this.call.make('agreement/findcontact',
			[{
				search                          : search_string,
				is_resource_reservation_allowed : is_resource_reservation_allowed,
				is_search_by_organization_id    : is_search_by_organization_id,
				limit                           : 100
			}]
		).pipe(map(contacts => {
			forEach(contacts, (contact : any) => {
				let agreement_name = search;
				if (contact.name) {
					agreement_name = contact.name.replace(search, `<strong style="text-decoration: underline;">${search}</strong>`);
				} else contact.name = searchUpper;
				agreement_name = agreement_name.replace(searchUpper, `<strong>${searchUpper}</strong>`);
				contact.html_name = agreement_name;
			});

			this.last_search = contacts;

			this.loader.stop();
			return values(contacts);
		}));
	}
}
