import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { each } from 'lodash';

import { Helpers } from '../../../../../shared';
import {
	DeliveryDetails,
	OrderDetails,
	OrderInfo,
	ProductDetails,
	ProductExtraServices,
	TaAddress,
	TaAgreement,
	TaCompany,
	TaCompanyInfo,
	TaContact,
	TaContactSupport,
	TaContract,
	TaCountryAddress,
	TaEmployment,
	TaLocation,
	TaLocationTag,
	TaModule,
	TaPersonalInfo,
	TaQualification,
	TaSite,
	TaWorkType
} from '../../interfaces';

import { TaDataHelper } from './ta-data-helper.class';

@Injectable()
export class TaFormGroupGeneratorService {

	constructor(private fb: FormBuilder)
	{
	}

	public updateFormGroupValidators(fg: FormGroup | FormArray | FormControl | AbstractControl,
	                                 validation_map: any = null): any
	{
		if (!validation_map) return fg;

		if (fg instanceof FormControl) return fg;
		else if (fg instanceof FormGroup) {
			each(fg.controls, (c, index) => {
				if (validation_map[index]) {
					if (validation_map[index].validators) {
						c.setValidators(validation_map[index].validators);
						c.updateValueAndValidity();
					} else if (validation_map[index].validators !== undefined) {
						c.disable();
						c.updateValueAndValidity();
					}

					this.updateFormGroupValidators(c, validation_map[index]);
				}
			});
		} else if (fg instanceof FormArray) {
			for (const c of fg.controls) this.updateFormGroupValidators(c, validation_map);
		}
		return fg;
	}

	public initAddress(data: TaAddress = TaDataHelper.emptyAddress): FormGroup
	{
		return this.fb.group({
			co       : data.co,
			po_box   : data.po_box,
			street   : data.street,
			postcode : data.postcode,
			city     : data.city
		});
	}

	public initCountry(data: TaCountryAddress = TaDataHelper.emptyCountryAddress, requiredAddress = true): FormGroup
	{
		if (requiredAddress) {
			return this.fb.group({
				country_code : data.country_code,
				country_name : data.country_name,
				address      : this.initAddress(data.address)
			});
		}
		return this.fb.group({
			country_code : data.country_code,
			country_name : data.country_name
		});
	}

	public initPersonalInfo(data: TaPersonalInfo = TaDataHelper.emptyPersonalInfo, validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			user_id          : data.user_id,
			account_id       : data.account_id,
			picture_filename : data.picture_filename,
			tax_number       : data.tax_number,
			first_name       : data.first_name,
			last_name        : data.last_name,
			title            : data.title,
			date_of_birth    : data.date_of_birth,
			phone_number     : data.phone_number,
			email            : data.email,

			country      : this.initCountry(data.country),
			home_country : this.initCountry(data.home_country),

			emergency_name         : data.emergency_name,
			emergency_phone_number : data.emergency_phone_number
		}), validation_map);
	}

	public initCompanyInfo(data: TaCompanyInfo = TaDataHelper.emptyCompanyInfo,
	                       validation_map = null,
	                       requiredAddress = true): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			name                     : data.name,
			organization_id          : data.organization_id,
			business_id              : data.business_id,
			foreign_business_id      : data.foreign_business_id,
			foreign_business_id_type : data.foreign_business_id_type,
			info                     : data.info,
			country                  : this.initCountry(data.country, requiredAddress),
			employer_role_id         : data.employer_role_id
		}), validation_map);
	}

	public initContact(data: TaContact = TaDataHelper.emptyContact, validation_map = null): FormGroup
	{
		const contact = data || TaDataHelper.emptyContact;
		return this.updateFormGroupValidators(this.fb.group({
			is_location_specific : contact.is_location_specific,
			contact_id           : contact.contact_id,
			contact_type_id      : contact.contact_type_id,
			user_id              : contact.user_id,

			name             : contact.name,
			username         : contact.username,
			password         : contact.password,
			send_password    : contact.send_password,
			account_id       : contact.account_id,
			picture_filename : contact.picture_filename,
			first_name       : contact.first_name,
			last_name        : contact.last_name,
			title            : contact.title,
			date_of_birth    : contact.date_of_birth,
			phone_number     : contact.phone_number,
			email            : contact.email
		}), validation_map);
	}

	public initAgreement(data: TaAgreement = TaDataHelper.emptyAgreement): FormGroup
	{
		return this.fb.group({
			agreement_id       : data.agreement_id,
			start              : data.start,
			end                : data.end,
			personal_id_length : data.personal_id_length,
			has_employees      : data.has_employees,
		});
	}

	public initWorkType(data: TaWorkType = TaDataHelper.emptyWorkType): FormGroup
	{
		return this.fb.group({
			is_visitor : data.is_visitor,
			code       : data.code,
			name       : data.name
		});
	}

	public initContract(data: TaContract = TaDataHelper.emptyContract): FormGroup
	{
		return this.fb.group({
			start                     : data.start,
			end                       : data.end,
			work_type                 : this.initWorkType(data.work_type),
			employee_group_id         : data.employee_group_id,
			default_employee_group_id : data.default_employee_group_id,
			title_id                  : data.title_id,
			commissioner              : this.initCompanyInfo(data.commissioner, null, false)
		});
	}

	public initLocation(data: TaLocation = TaDataHelper.emptyLocation)
	{
		return this.fb.group({
			name         : data.name,
			code         : data.code,
			location_id  : data.location_id,
			external_id  : data.external_id,
			auth_site_id : data.auth_site_id
		});
	}

	public initCompany(data: TaCompany = TaDataHelper.emptyCompany,
	                   validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			company_info    : this.initCompanyInfo(data.company_info),
			contact         : this.initContact(data.contact),
			foreign_contact : this.initContact(data.foreign_contact),
			location        : this.initLocation(data.location)
		}), validation_map);
	}

	public initEmployment(data: TaEmployment = TaDataHelper.emptyEmployment,
	                      need_contract = false,
	                      validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			user_id     : data.user_id,
			employee_id : data.employee_id,
			company     : this.initCompany(data.company),
			contract    : need_contract ? this.initContract(data.contract) : null

		}), validation_map);
	}

	public initQualification(qualification: TaQualification = TaDataHelper.emptyQualification,
	                         validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			account_tag_id : qualification.account_tag_id,
			name           : qualification.name,
			contact_person : qualification.contact_person,
			value          : qualification.value,
			start_date     : qualification.start_date,
			end_date       : qualification.end_date,
			tag_id         : qualification.tag_id,
			employee_id    : qualification.employee_id,
			employee_name  : qualification.employee_name,
			external_id    : qualification.external_id,
			is_expired     : qualification.is_expired
		}), validation_map);
	}

	public initQualifications(qualifications: TaQualification[] = [], validation_map = null): FormArray
	{
		const qualification_controls = [];

		each(qualifications, qualification => {
			qualification_controls.push(this.initQualification(qualification));
		});

		return this.updateFormGroupValidators(this.fb.array(qualification_controls), validation_map);
	}

	public initSite(data: TaSite = TaDataHelper.emptySite, validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			name         : data.name,
			external_id  : data.external_id,
			auth_site_id : data.auth_site_id,
			location_id  : data.location_id,
			code         : data.code,
			address      : this.initAddress(data.address),
			agreement    : this.initAgreement(data.agreement),
			company_info : this.initCompanyInfo(data.company_info),
			contact      : this.initContact(data.contact)
		}), validation_map);
	}

	public initRamiContactTypes(all_contact_types: any[], contact_types: any[] = [], validation_map = null): FormGroup
	{
		const contact_type_map: any = Helpers.arrayToMap(contact_types, 'contact_type_id', true);
		const control_contact_types = [];

		for (const contact_type of all_contact_types) {
			const value = {
				contact_type_id   : contact_type.contact_type_id,
				contact_type_name : contact_type.contact_type_name,
				name              : '',
				user_id           : null
			};

			const found_contact = contact_type_map(value.contact_type_id);
			if (found_contact) {
				value.name = found_contact.name;
				value.user_id = found_contact.user_id;
			}

			control_contact_types.push(this.fb.group(value));
		}

		return this.updateFormGroupValidators(this.fb.group({
			contact_types : this.fb.array(control_contact_types)
		}), validation_map);
	}

	public initRamiModules(modules: TaModule[] = []): FormGroup
	{
		const service_modules = [];
		for (const module of modules) {
			service_modules.push(this.fb.group({
				module_id                  : module.module_id,
				name                       : module.name,
				is_active                  : !!module.is_active,
				is_access_area_daily_reset : !!module.is_access_area_daily_reset
			}));
		}

		return this.fb.group({
			modules : this.fb.array(service_modules)
		});
	}

	public initRamiContacts(contacts: TaContact[] = [], validation_map = null): FormArray
	{
		const contact_list = [];

		for (const contact of contacts) {
			contact_list.push(this.initContact(contact));
		}

		return this.updateFormGroupValidators(this.fb.array(contact_list), validation_map);
	}

	public initLocationTag(tag: TaLocationTag = TaDataHelper.emptyLocationTag): FormGroup
	{
		return this.fb.group({
			name        : tag.name,
			description : tag.description,
			// Note: do not remove array brackets as it won't work otherwise; it should be array inside array or array of null
			possible_values : [tag.possible_values],
			tag_group_id    : tag.tag_group_id,
			tag_id          : tag.tag_id,
			type            : tag.type,
			ui_order        : tag.ui_order,
			value           : tag.value
		});
	}

	public initLocationTags(tags: TaLocationTag[] = [], validation_map = null): FormArray
	{
		const contact_list = [];

		for (const tag of tags) {
			contact_list.push(this.initLocationTag(tag));
		}

		return this.updateFormGroupValidators(this.fb.array(contact_list), validation_map);
	}

	public initProductExtraService(extra_service: ProductExtraServices = TaDataHelper.emptyProductExtraService,
	                               validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			name        : extra_service.name,
			service_id  : extra_service.service_id,
			is_per_day  : extra_service.is_per_day,
			is_selected : extra_service.is_selected,
			price       : extra_service.price
		}), validation_map);
	}

	public initProductExtraServices(extra_services: ProductExtraServices[] = [], validation_map = null): FormGroup
	{
		const extras = [];

		for (const extra of extra_services) {
			extras.push(this.initProductExtraService(extra));
		}

		return this.updateFormGroupValidators(this.fb.array(extras), validation_map);
	}

	public initProductDetails(details: ProductDetails = TaDataHelper.emptyProductDetails,
	                          validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			product_id     : details.product_id,
			quantity       : details.quantity,
			cost_center_id : details.cost_center_id,
			comment        : details.comment,
			extra_services : this.initProductExtraServices(details.extra_services)
		}), validation_map);
	}

	public initOrderInfo(order_info: OrderInfo = TaDataHelper.emptyOrderInfo, validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			order_date            : order_info.order_date,
			cost_center_id        : order_info.cost_center_id,
			buyer_name            : order_info.buyer_name,
			buyer_user_id         : order_info.buyer_user_id,
			email                 : order_info.email,
			delivery_confirmation : order_info.delivery_confirmation,
			phone_number          : order_info.phone_number
		}), validation_map);
	}

	public initDeliveryDetails(deliver_details: DeliveryDetails = TaDataHelper.emptyDeliveryDetails,
	                           validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			site_name            : deliver_details.site_name,
			site_address         : deliver_details.site_address,
			delivery_address     : deliver_details.delivery_address,
			requested_date       : deliver_details.requested_date,
			pickup_time          : deliver_details.pickup_time,
			expected_return_date : deliver_details.expected_return_date,

			recipient_name : deliver_details.recipient_name,
			phone_number   : deliver_details.phone_number,
			info           : deliver_details.info
		}), validation_map);
	}

	public initOrderDetails(order_details: OrderDetails = TaDataHelper.emptyOrderDetails,
	                        validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			order_id         : order_details.order_id,
			order_info       : this.initOrderInfo(order_details.order_info),
			company          : this.initCompany(order_details.company),
			delivery_details : this.initDeliveryDetails(order_details.delivery_details)
		}), validation_map);
	}


	public initContactSupportInfo(data: TaContactSupport = TaDataHelper.emptyContactSupportInfo,
	                              validation_map = null): FormGroup
	{
		return this.updateFormGroupValidators(this.fb.group({
			name         : data.name,
			email        : data.email,
			phone_number : data.phone_number,
			text         : data.text
		}), validation_map);
	}
}
