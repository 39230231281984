import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { EnkoraDataInjector, TagValue } from '../../shared';

import { ShopFieldInput } from './shop.interface';

export interface TagInput {
	name: string;
	is_required?: boolean;
	description?: string;
	possible_values?: any[];
	tag_id?: string;
	type?: string;
}

export interface TagSetInput {
	tag_group: {
		name: string;
		tag_group_id: string;
		description: string;
	};
	tags: TagInput[];
}

export interface TagGroupsInput {
	[tag_group_id: string]: TagSetInput
}

@Injectable()
export class ShopTagGroupsService {

	public tag_sets: TagGroupsInput = {};

	constructor()
	{
		this.tag_sets = (EnkoraDataInjector.get('public_tag_groups') || {}) as TagGroupsInput;
	}

	getTagFields(tag_group_ids: string[], tag_values: TagValue = {}): ShopFieldInput[]
	{
		tag_values = tag_values || {};
		const tag_fields: ShopFieldInput[] = [];

		each(tag_group_ids, (tag_group_id) => {
			const ts = this.tag_sets[tag_group_id];

			if (ts) {
				const checkboxes = [];
				const value = [];

				each(ts.tags, (tag: TagInput) => {
					if (tag.type == 'text')
						tag_fields.push({
							id          : 'tags_' + tag_group_id + '_' + tag.tag_id,
							name        : tag.name,
							type        : 'textarea',
							description : tag.description,
							value       : tag_values[tag.tag_id] && tag_values[tag.tag_id].value
						});
					else {
						checkboxes.push(tag.name);
						value.push(!!tag_values[tag.tag_id]);
					}
				});

				if (checkboxes.length) {
					tag_fields.push({
						id              : 'tags_' + tag_group_id,
						name            : ts.tag_group.name,
						type            : 'checkboxes',
						possible_values : checkboxes,
						description     : ts.tag_group.description,
						value
					});
				}
			}
		});

		return tag_fields;
	}
}
