import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import * as _ from 'lodash';

import { Constants } from '../../shared/misc/constants';

interface SsnResult {
	date_of_birth: string;
	age: number;
	gender: number;
	valid: boolean;
}

@Injectable()
export class SocialSecurityNumberParserService {

	// http://tarkistusmerkit.teppovuori.fi/tarkmerk.htm#hetu1
	// Modified to support Finnish hetu 2023 changes
	public ssnPattern = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-ABCDEFYXWVU])(\d{3})([\dA-Z])$/;
	public checkSymbol = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
		'A', 'B', 'C', 'D', 'E', 'F', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y'];

	constructor() {
	}

	parseSocialSecurityNumber(ssn: string, round_up = false): SsnResult {
		const parsed = this.ssnPattern.exec(ssn);
		const year_1900 = ['-', 'Y', 'X', 'W', 'V', 'U'];
		const year_2000 = ['A', 'B', 'C', 'D', 'E', 'F'];

		if (!parsed) return null;

		const day = parsed[1];
		const month = parsed[2];
		const modifier = parsed[3][2];
		const save_year = parsed[3].substr(0, 2);
		let year = +save_year;

		if (year_1900.includes(modifier)) year += 1900;
		else if (year_2000.includes(modifier)) year += 2000;
		else if (modifier == '+') year += 1800;

		const personal_id = parsed[4];

		const date_of_birth = moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
		const gender = ((+personal_id) % 2 == 1) ? Constants.GENDER_MALE : Constants.GENDER_FEMALE;

		const valid = _.isEqual(
			this.checkSymbol[+('' + day + month + save_year + personal_id) % 31],
			parsed[5]
		);

		const ms = moment().endOf('day').diff(moment(date_of_birth, 'YYYY-MM-DD').startOf('day'));
		return {
			date_of_birth : date_of_birth,
			age           : round_up ? Math.ceil(moment.duration(ms).asYears()) : Math.floor(moment.duration(ms).asYears()),
			gender        : gender, valid : valid
		};
	}
}
