import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopLocationRawInput {
	location_id: string;
	name: string;
	description: string;
	address: string;
	reservation_group_ids: number[];
	service_ids: number[];
	access_area_ids: number[];
}

export interface ShopLocationItem {
	location_id: string;
	name: string;
	description: string;
	address: string;
	reservation_group_ids: string[];
	service_ids: string[];
	access_area_ids: string[];
}

@Injectable()
export class ShopLocationListService extends EnkoraFetcher<ShopLocationItem[]> {

	public location_map: { [location_id: string]: ShopLocationItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ReservationLocations' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterByServiceId(service_id  = ''): Observable<ShopLocationItem[]>
	{
		return this.getLazy().pipe(
			first(),
			map(location_list => {
				const filtered_list: ShopLocationItem[] = [];

				each(location_list, location => {
					if (!location.service_ids) return;

					if (!find(location.service_ids, id => id == service_id)) return;

					filtered_list.push(location);
				});

				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopLocationRawInput[]): ShopLocationItem[]
	{
		const result: ShopLocationItem[] = [];
		this.location_map = {};

		each(reply, row => {
			const item: ShopLocationItem = {
				...row,
				reservation_group_ids : Helpers.numberToStringArray(row.reservation_group_ids),
				access_area_ids       : Helpers.numberToStringArray(row.access_area_ids),
				service_ids           : Helpers.numberToStringArray(row.service_ids)
			};
			result.push(item);
			this.location_map[item.location_id] = item;
		});

		return result;
	}
}
