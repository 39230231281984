import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

@Injectable({
	providedIn : 'root'
})
export class TaEmployeeGroupsService extends EnkoraFetcher<any[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getEmployeeGroups' }];

	constructor(call: CallService)
	{
		super(call);
	}
}
