import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { CallService, Constants, ParameterService } from '../../shared';
import { UserAccountManagerService } from './user-account-manager.service';

import * as _ from 'lodash';
import * as moment from 'moment';

declare let Enkora: { tt: (a: string) => string };

declare function parseDate(str);

export interface ParsedLessons {
	single_lessons : any[],
	course_lessons : any[],
	reservation_event_groups : any[],
	num_old_lessons : number
}

export interface Lesson {
	available_times: string;
	calculated_duration: number;
	cancellation_allowed_before_minutes: number;
	count_agreement_accounts: number;
	event_name: string;
	event_status: string;
	event_status_constant: string;
	is_active: number;
	is_collapsed: boolean,
	is_course: number;
	is_deleted?: boolean,
	is_instructor_account_id: number;
	location: string;
	owner_account_name: string;
	owner_account_id : number;
	reservation_account_id: number;
	reservation_account_name: string;
	reservation_application_id: number;
	reservation_event_group: string;
	reservation_event_group_id: number;
	reservation_event_id: number;
	reservation_event: string;
	reservation_id: number;
	reservation_status_id: number;
	service_id: number;
	time_end: string;
	time_slots: any[];
	time_start: string;
	times: any[];
	user_name: string;
	note: string;
	capacity : number;
	queue : number;
	service_type_id : number;
}

@Injectable()
export class CoursesAndLessonsService {
	public courses$ = new BehaviorSubject<any[]>([]);
	public linked_courses$ = new BehaviorSubject<any[]>([]);
	public lessons$ = new BehaviorSubject<any[]>([]);
	public parsedLessons$ = new BehaviorSubject<ParsedLessons>(null);

	constructor(private call : CallService,
	            private user : UserAccountManagerService,
	            private params : ParameterService)
	{
		this.reset();

		user.account$.subscribe(() => {
			this.reset();

			if (!this.user.account.account_id) return;
			this.reloadCourses();

			if (this.user.linked_accounts && this.user.linked_accounts.length > 0) {
				this.call.make('reservation/geteventgroupsforlinkedaccounts',
						[
							{
								reservation_account_id : this.user.account.account_id
							}
						]
				).subscribe((linked_courses) => {
					this.linked_courses = linked_courses;
				});
			}

			// getting lessons
			if (user.loadReservations) {
				this.params.getNumberValue(
					'load account courses not older than months',
					Constants.NM_EN_RESERVATIONS,
					1
				).subscribe(limit => {
					let params = {
						reservation_account_id : this.user.account.account_id,
						not_older_than_months  : limit,
						is_minimal             : 1,
						no_cancelled_lessons   : 1
					};
					if (user.showCourseReservations) {
						params['is_course'] = 1;
					}
					if (user.showCoursePayerReservations) {
						let payer_params = _.cloneDeep(params);
						payer_params['is_search_as_payer'] = 1;

						combineLatest([
								this.call.make('reservation/getreservations', [params]),
								this.call.make('reservation/getreservations', [payer_params])
						]).subscribe(latestLessons => {
							const [accountLessons, payerLessons] = latestLessons;
							let lessons = [];
							let lessonsMap = {};
							let index = 1;
							_.forEach(accountLessons, (accountLesson) => {
								lessonsMap[accountLesson.reservation_id] = {
									index                  : index,
									reservation_account_id : accountLesson.reservation_account_id
								};
								accountLesson.user_description = Enkora.tt('Participant');
								lessons.push(accountLesson);
								index++;
							});
							_.forEach(payerLessons, (payerLesson) => {
								if (lessonsMap[payerLesson.reservation_id]
										&& lessonsMap[payerLesson.reservation_id].reservation_account_id == payerLesson.reservation_account_id
								) {
									lessons[lessonsMap[payerLesson.reservation_id].index - 1].user_description =
											Enkora.tt('Participant') + '/' + Enkora.tt('Payer');
								} else {
									payerLesson.user_description = Enkora.tt('Payer for') + ': '
											+ payerLesson.reservation_account_id + '. '
											+ payerLesson.user_name;
									lessons.push(payerLesson);
								}
							});
							this.lessons = lessons;
						});
					} else {
						this.call.make('reservation/getreservations',
								[params]
						).subscribe((lessons) => {
							this.lessons = lessons;
						});
					}
				});
			}
		});

		this.lessons$.subscribe(() => {
			this.separateLessons();
		});
	}

	private _courses : any[] = [];

	get courses() : any[]
	{
		return this._courses;
	}

	set courses(value : any[])
	{
		this._courses = value;
		this.courses$.next(value);
	}

	private _linked_courses : any[] = [];

	get linked_courses() : any[]
	{
		return this._linked_courses;
	}

	set linked_courses(value : any[])
	{
		this._linked_courses = value;
		this.linked_courses$.next(value);
	}

	private _lessons : Lesson[] = [];

	get lessons() : Lesson[]
	{
		return this._lessons;
	}

	set lessons(value : Lesson[])
	{
		this._lessons = value;
		this.lessons$.next(value);
	}

	private _parsedLessons : ParsedLessons;

	get parsedLessons() : ParsedLessons
	{
		return this._parsedLessons;
	}

	set parsedLessons(value : ParsedLessons)
	{
		this._parsedLessons = value;
		this.parsedLessons$.next(value);
	}

	reset()
	{
		this.courses = [];
		this.linked_courses = [];
		this.lessons = [];
		this.parsedLessons = {
			single_lessons           : [],
			course_lessons           : [],
			reservation_event_groups : [],
			num_old_lessons          : 0
		};
	}

	public reloadCourses()
	{
		this.params.getNumberValue(
			'load account courses not older than months',
			Constants.NM_EN_RESERVATIONS,
			1
		).subscribe(limit => {
			this.call.make('reservation/geteventgroupsforaccount',
				[
					{
						reservation_account_id : this.user.account.account_id,
						month_limit            : limit
					}
				]
			).subscribe((courses) => {
				this.courses = courses;
			});
		});
	}

	addCourses(new_courses : Array<any>)
	{
		this.courses = _.concat(this.courses, new_courses);
	}

	removeCoursesById(reservation_event_group_id : any)
	{
		this.courses = _.filter(this.courses, (course) => {
			return course.reservation_event_group_id != reservation_event_group_id;
		});
	}

	addLessons(lessons : Array<any>, time_start : any)
	{
		let stdate = parseDate(time_start);

		var stval = '';
		stval = new (<any>Date)(stdate.year, stdate.month - 1, stdate.day, stdate.hour, stdate.minute, stdate.second);
		var prevstval = stval;

		let new_lessons : Array<any> = _.cloneDeep(this.lessons);

		if (new_lessons.length == 0) {
			new_lessons.splice(0, 0, lessons);
		} else {
			var last_time = parseDate(new_lessons[new_lessons.length - 1].time_start);
			var last_date = new (<any>Date)(last_time.year, last_time.month - 1, last_time.day, last_time.hour, last_time.minute, last_time.second);
			if (stval.valueOf() > last_date.valueOf()) {
				new_lessons.splice(new_lessons.length, 0, lessons);
			} else
				for (var j = 0; j < new_lessons.length; j++) {
					let reservation_event = new_lessons[j];
					if (!reservation_event) continue;
					stdate = parseDate(reservation_event.time_start);
					var stval1 = new (<any>Date)(stdate.year, stdate.month - 1, stdate.day, stdate.hour, stdate.minute, stdate.second);


					if (stval.valueOf() >= prevstval.valueOf() && stval.valueOf() <= stval1.valueOf()) {
						new_lessons.splice(j, 0, lessons);
						break;
					} else if (stval.valueOf() < prevstval.valueOf()) {
						new_lessons.splice(j - 1, 0, lessons);
						break;
					}
					prevstval = stval1;
				}
		}

		this.lessons = new_lessons;

	}

	updateLessons(lessons : any)
	{
		let ids : any = {};
		_.each(lessons, (lesson : any) => {
			ids[lesson.reservation_event_id] = lesson;
		});

		let new_lessons = _.cloneDeep(this.lessons);
		_.each(new_lessons, (new_lesson) => {
			if (ids[new_lesson.reservation_event_id]) {
				_.assign(new_lesson, ids[new_lesson.reservation_event_id]);
			}
		});

		this.lessons = new_lessons;
	}

	updateLessonStatusesByIds(lessons : Array<any>, new_status : number)
	{
		let ids : any = {};
		_.each(lessons, (lesson) => {
			ids[lesson.reservation_event_id] = true;
		});

		let new_lessons = _.cloneDeep(this.lessons);
		_.each(new_lessons, (new_lesson) => {
			if (ids[new_lesson.reservation_event_id])
				new_lesson.reservation_status_id = new_status;
		});

		this.lessons = new_lessons;
	}

	deleteCourse(reservation_event_group_id)
	{
		_.remove(this.courses, {reservation_event_group_id : reservation_event_group_id});
	};

	separateLessons()
	{
		let now = moment();

		let single_lessons : any[] = [];
		let course_lessons : any[] = [];
		let num_old_lessons = 0;

		// Loop through normal reservations
		_.each(this.lessons, (input_lesson : any) => {
			if (input_lesson.is_course || !input_lesson.time_start) return;

			let lesson : any = _.cloneDeep(input_lesson);

			let time_start = moment(lesson.time_start);
			let time_end = moment(lesson.time_end);

			if (now.isAfter(time_start, 'day')) {
				lesson.is_old = true;
				num_old_lessons++;
			}

			lesson.is_deleted = false;
			lesson.html_title = (lesson.event_name ? lesson.event_name : lesson.reservation_event_group);

			single_lessons.push(lesson);
		});

		let skip = {};

		// Loop through course reservations
		_.each(this.lessons, (input_course) => {
			if (input_course.is_course != 1 || skip[input_course.reservation_id]) return;

			if (input_course.reservation_status_id != Constants.RES_QUEUE &&
					input_course.reservation_status_id != Constants.RES_RESERVED &&
					input_course.reservation_status_id != Constants.RES_CANCELLED) return;

			let course : any = _.cloneDeep(input_course);
			course.times = [];

			let same_course_lessons = this.lessons.filter(item => {
				return (item.reservation_event_group_id == course.reservation_event_group_id
						&& item.reservation_account_id == course.reservation_account_id);
			});
			_.each(same_course_lessons, (course_lesson) => {
				if (course_lesson.reservation_status_id == Constants.RES_QUEUE ||
						course_lesson.reservation_status_id == Constants.RES_RESERVED ||
						course_lesson.reservation_status_id == Constants.RES_CANCELLED) {

					course.times.push({time_start : course_lesson.time_start, time_end : course_lesson.time_end});
				}
				skip[course_lesson.reservation_id] = true;
			});

			course.is_collapsed = true;
			course.is_deleted = false;

			course_lessons.push(course);
		});

		this.parsedLessons.single_lessons = single_lessons;
		this.parsedLessons.course_lessons = course_lessons;
		this.parsedLessons.num_old_lessons = num_old_lessons;
	}
}
