export enum MessageType {
	email_exists = 'email_exists',
	generic = 'generic'
}

export interface FieldMessageGuard {
	type: MessageType;
}

export interface GenericFieldMessage extends FieldMessageGuard {
	type: MessageType.generic;
	data: any;
}

export interface EmailFieldMessage extends FieldMessageGuard {
	type: MessageType.email_exists;
	user_id: string;
	email: string;
}

export type FieldMessage = GenericFieldMessage | EmailFieldMessage;

export function isEmailFieldMessage(message: FieldMessage): message is EmailFieldMessage
{
	return message.type === MessageType.email_exists;
}
