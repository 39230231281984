<ng-container *ngIf="!reservation_list.is_loading && reservation_list.value">
	<button style="margin-top: 7px;"
	        *ngIf="past_count > 0 && past_allowed"
	        class="pull-right btn btn-small btn-primary"
	        (click)="togglePast()"
	        [class.active]="show_past">
		{{ (show_past? 'Hide past items' : 'Include past items') | tt}}
	</button>

	<h3 tt>Reservations</h3>
	<!-- Check if there are only past events && past display mode is not set-->

	<ng-container *ngIf="!reservation_views || !reservation_views.length ||
		!show_past && past_count > 0 && past_count == reservation_views.length; else has_content">
		<p class="well" tt>No active or upcoming lessons.</p>
	</ng-container>

	<ng-template #has_content>
		<div class="alert alert-warning" *ngIf="max_reserve && future_count >= max_reserve">
			{{ 'Reservation limit (%s) reached. You can not make new lesson reservations.' | tt:{vars: [max_reserve]} }}
		</div>

		<table class="user-events table table-striped table-condensed" style="width: 100%;">
			<thead>
			<tr>
				<th tt>Date</th>
				<th tt>Name</th>
				<th class="ue-location" tt>Location</th>
				<th class="ue-instructor" *ngIf="!hide_instructors" tt>Instructor</th>
				<th class="ue-instructor" *ngIf="!hide_name" tt>Reservation</th>
				<th class="ue-instructor" *ngIf="!hide_owner" tt>Owner</th>
				<th *ngIf="!hide_status" tt>Status</th>
				<th class="right"></th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let reservation of reservation_views; let i=index">
				<tr *ngIf="!reservation.is_past || show_past">
					<td>{{ reservation.time_start | amDateFormat:'dd DD.MM.YYYY HH:mm'}}</td>
					<td>{{ reservation.name }}</td>
					<td class="ue-location">{{ reservation.location }}</td>
					<td class="ue-instructor" *ngIf="!hide_instructors">
						{{ reservation.is_resource_reservation ? '' : reservation.instructor }}
					</td>
					<td class="ue-location" *ngIf="!hide_name">{{ reservation.reservation_account_name }}</td>
					<td class="ue-location" *ngIf="!hide_owner">{{ reservation.owner_account_name }}</td>
					<td *ngIf="!hide_status">
						<span class="label" *ngIf="reservation.is_past; else not_past" tt>Finished</span>
						<ng-template #not_past>
					<span class="label"
					      [ngClass]="'label-' + reservation.reservation_status_label">
						{{ reservation.reservation_status_name | tt }}
						<ng-container *ngIf="!reservation.is_self">
							<br> {{ reservation.reservation_account_id }}
						</ng-container>
					</span>
						</ng-template>
					</td>
					<td class="right">
						<button class="btn btn-info btn-mini" (click)="showEvent(reservation)" tt>Show</button>
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>
	</ng-template>
</ng-container>
