import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';
import { TaDataHelper, TaWorkType } from '../../../shared';

export interface ValidationError {
	error_id?: string;
	link: string;
	errors: string;
	value: any;
}

export interface TaxReport {
	access_point_id: string;
	business_id_subcontractor: string;
	commissioner_organization_id_employee: string;
	employee_address: string;
	employee_dob: string;
	employee_email: string;
	employee_end_date: string;
	employee_first_name: string;
	employee_home_country_code: string;
	employee_id: string;
	employee_is_foreign: string;
	employee_last_name: string;
	employee_name_display: string;
	employee_phone_number: string;
	employee_record_creation_timestamp: string;
	employee_scn: string;
	employee_start_date: string;
	employee_tax_number: string;
	employee_work_type_tax_code: string;
	event_id: string;
	foreign_business_id_subcontractor: string;
	foreign_employee_home_address: string;
	is_entrance: string;
	location_id: string;
	location_id_employee: string;
	organization_id: string;
	organization_id_employee: string;
	organization_id_subcontractor: string;
	site_address: string;
	site_code: string;
	site_name: string;
	site_number: string;
	subcontractor: string;
	subcontractor_address: string;
	subcontractor_contact_address: string;
	subcontractor_contact_country_code: string;
	subcontractor_contact_email: string;
	subcontractor_contact_first_name: string;
	subcontractor_contact_home_address: string;
	subcontractor_contact_home_country_code: string;
	subcontractor_contact_last_name: string;
	subcontractor_contact_phone_number: string;
	subcontractor_country_code: string;
	subcontractor_foreign_business_id_type: string;
	subcontractor_is_foreign: string;
	user_id: string;
	valid_from: string;
	valid_until: string;
	custom_link?: string;

	_validation_errors: { [group: string]: { [field: string]: ValidationError } };

	work_type: TaWorkType;
}

@Injectable({
	providedIn : 'root'
})
export class TaReportsTaxService extends EnkoraFetcher<TaxReport[]> {

	protected params: CallParameter[] = [{ name : 'cta/getverottajareport' }];

	constructor(call: CallService)
	{
		super(call);
	}

	protected postProcess(reply: TaxReport[]): TaxReport[]
	{
		each(reply, row => row.work_type = TaDataHelper.extractWorkType(+row.employee_work_type_tax_code));
		return reply;
	}
}
