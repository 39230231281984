import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, merge, Observable, of, OperatorFunction } from 'rxjs';
import { each, filter, mergeWith } from 'lodash';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { TaCompanyInfo, TaRole } from '../../interfaces';
// TODO: reference from abstract to concrete in next line is a HUGE error:
import { TaOrganizationRoleListService } from '../../../protected-modules/time-attendance/services/ta-organization-role-list-service';

import { abstractControlToFormGroup, Constants, ParameterService } from 'shared';

import { CtaStyle, TaCtaStyleService, TaDataHelper, TaFindCompanyService } from '../../services';

@Component({
	selector    : 'cta-ta-form-company-info',
	templateUrl : './company-info.form.html',
	providers   : [TaFindCompanyService],
	styleUrls   : ['./company-info.form.scss']
})
export class CompanyInfoForm implements OnInit {

	@Input() public readonly = false;
	@Input() public modification_forbidden = false;
	@Input() public isCommissioner = false;
	@Output() onOrganizationSelected = new EventEmitter<string>();
	@Output() onCountryCodeSelected = new EventEmitter<string>();

	public employer_roles: TaRole[] = [];

	public organization_id = null;
	public show_foreign_business_id = false;
	public show_business_id = true;
	public can_edit_name = true;
	public searchingName = false;
	public searchingBusinessId = false;
	public searchBusinessIdFailed = false;
	public foreign_business_id_types: { id: string, name: string }[] = [];
	public home_country_name = '';
	hideName = new Observable(() => () => this.searchingName = false);
	hideBusinessId = new Observable(() => () => this.searchingBusinessId = false);
	private refreshingBusinessId = false;
	private refreshingForeignBusinessId = false;
	private ctaStyle: CtaStyle = TaCtaStyleService.emptyStyle;

	constructor(private findCompany: TaFindCompanyService,
	            private ctaStyleService: TaCtaStyleService,
	            private organizationRoles: TaOrganizationRoleListService,
	            private param : ParameterService)
	{
		combineLatest([
			this.ctaStyleService.getFirst(),
			this.param.getValue('home country code', Constants.NM_EN_CTA)
		]).subscribe(reply => {
			const [style, home_country_code] = reply;
			this.ctaStyle = style;
			this.home_country_name = `${this.ctaStyle.map[home_country_code]} `;
		});
		this.foreign_business_id_types = [];
		each(Constants.BUSINESS_ID_TYPES, (name, type) => {
			this.foreign_business_id_types.push({ id : type, name });
		});
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this.organization_id = value.get('organization_id').value;
		this.can_edit_name = !this.organization_id;
		this.show_foreign_business_id = value.get('foreign_business_id').value;

		value.get('organization_id').valueChanges.subscribe(organization_id => {
			this.organization_id = organization_id;
			this.can_edit_name = !this.organization_id;
		});

		value.get('country').valueChanges.subscribe(country => {
			this.onCountryCodeSelected.emit(country.country_code);
			this.show_foreign_business_id = country.country_code != this.ctaStyle.code && !value.get('business_id').value;
			if (!this.show_foreign_business_id) {
				this.show_business_id = true;
				value.patchValue({
					'foreign_business_id'      : TaDataHelper.emptyCompanyInfo.foreign_business_id,
					'foreign_business_id_type' : TaDataHelper.emptyCompanyInfo.foreign_business_id_type
				});
			}
		});

		value.get('foreign_business_id').valueChanges.subscribe(foreign_business_id => {
			if (this.refreshingForeignBusinessId) {
				this.refreshingForeignBusinessId = false;
				return;
			}
			this.show_business_id = !foreign_business_id;
		});

		value.get('business_id').valueChanges.subscribe(() => {
			if (this.refreshingBusinessId) {
				this.refreshingBusinessId = false;
				return;
			}
			this.show_foreign_business_id = value.get('country').value.country_code != this.ctaStyle.code && !value.get('business_id').value;
		});
	}

	searchName: OperatorFunction<string, readonly TaCompanyInfo[]> = (text$: Observable<string>) =>
		merge(
			text$.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.searchingName = true),
				switchMap(name =>
					this.findCompany.get(true, { name }).pipe(
						tap(() => this.searchBusinessIdFailed = false),
						map(companies =>
							filter(companies, company => !!company.business_id || !!company.foreign_business_id)
						),
						catchError(() => {
							this.searchBusinessIdFailed = true;
							return of([] as TaCompanyInfo[]);
						})
					)
				),
				tap(() => this.searchingName = false)
			),
			this.hideName
		) as Observable<TaCompanyInfo[]>;

	searchBusinessId: OperatorFunction<string, readonly TaCompanyInfo[]> = (text$: Observable<string>) =>
		merge(
			text$.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				tap(() => this.searchingBusinessId = true),
				switchMap(business_id =>
					this.findCompany.get(true, { business_id }).pipe(
						tap(() => this.searchBusinessIdFailed = false),
						catchError(() => {
							this.searchBusinessIdFailed = true;
							return of([]);
						}))
				),
				tap(() => this.searchingBusinessId = false)
			),
			this.hideBusinessId
		) as Observable<TaCompanyInfo[]>;

	clear(): void
	{
		this.inputForm.patchValue(TaDataHelper.emptyCompanyInfo);
		this.onOrganizationSelected.emit(null);
	}

	onSelect(event: NgbTypeaheadSelectItemEvent): void
	{
		event.preventDefault();
		const company_info = mergeWith(
			{}, TaDataHelper.emptyCompanyInfo, event.item,
			(a, b) => b === null ? a : undefined
		);
		this.inputForm.patchValue(company_info);

		this.onOrganizationSelected.emit(event.item.organization_id);
	}

	ngOnInit(): void
	{
		if (!this.isCommissioner) {
			this.organizationRoles.get(true).subscribe(roles => this.employer_roles = roles);
		}
		const foreign_business_id = this.inputForm.get('foreign_business_id').value;
		this.show_foreign_business_id = foreign_business_id != null && foreign_business_id.toString().length > 0;
		this.show_business_id = !this.show_foreign_business_id;
	}

	protected readonly abstractControlToFormGroup = abstractControlToFormGroup;
}
