import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LocationResourceDataService {
	get lesson_ids() : any[] {
		return this._lesson_ids;
	}

	public lesson_ids$ = new BehaviorSubject([]);

	set lesson_ids(value : any[]) {
		this._lesson_ids = value;
		this.lesson_ids$.next(value);
	}

	get location_ids() : any[] {
		return this._location_ids;
	}

	public location_ids$ = new BehaviorSubject([]);

	set location_ids(value : any[]) {
		this._location_ids = value;
		this.location_ids$.next(value);
	}

	get region_ids() : any[] {
		return this._region_ids;
	}

	public region_ids$ = new BehaviorSubject([]);

	set region_ids(value : any[]) {
		this._region_ids = value;
		this.region_ids$.next(value);
	}

	get service_id() : any {
		return this._service_id;
	}

	public service_id$ = new BehaviorSubject(-1);

	set service_id(value : any) {
		this._service_id = value;
		this._local_service_id = value;
		this.service_id$.next(value);
	}

	get local_service_id() : any {
		return this._local_service_id;
	}

	set local_service_id(value : any) {
		this._local_service_id = value;
	}

	dropLocalServiceId() {
		this._local_service_id = this._service_id;
	}

	get course() : any {
		return this._course;
	}

	public course$ = new BehaviorSubject(-1);

	set course(value : any) {
		this._course = value;
		this.course$.next(value);
	}

	private _course = null;

	private _service_id = null;

	private _local_service_id = null;
	private _region_ids : any[] = null;
	private _location_ids : any[] = null;
	private _lesson_ids : any[] = null;

	constructor() {
	}

}
