import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, each, find } from 'lodash';

import {
	AuthenticationService,
	AutoSubs,
	AutoUnsubscribe,
	CallService,
	Constants,
	EnkoraMessageService,
	LocationService,
	Logger,
	ParameterService
} from 'shared';

import { TaActiveDataHolderService, TaCompanyInfo, TaDataHelper, Token } from '../../../../../shared';
import { TaEmployeeInfoService } from '../../../services';
import { InputToken, InputTokenStatus } from '../../../tokens';

export interface TokenIds {
	[key: string]: boolean;
}

export interface TokenMessage {
	token_alias: string;
	new_organization_id: string;
	current_account_alias: string;
	current_organization_alias: string;
}

@AutoUnsubscribe()
@Component({
	templateUrl : './tokens.modal.html',
	styleUrls   : ['./tokens.modal.scss']
})
export class TokensModal implements OnInit, OnDestroy {
	@AutoSubs() subs;
	@Input() public standalone = true;

	public tax_number: string;
	public companies: TaCompanyInfo[] = [];

	public tokens: Token[] = [];

	public latest: Token[];
	public initial: Token[];
	public initial_ids: TokenIds = {};

	public is_searching = false;
	public is_valid = true;
	public no_changes = true;
	public save_message = '';
	public prohibit_am_from_adding_tokens = false;

	public InputTokenStatus = InputTokenStatus;
	public tokenStatus: InputToken = { type : InputTokenStatus.None };

	public token_messages: TokenMessage[] = [];
	public user_id = '';
	public rewrite_token = false;
	public location_id = '';
	public default_organization: TaCompanyInfo = TaDataHelper.emptyCompanyInfo;

	constructor(public activeModal: NgbActiveModal,
	            private call: CallService,
	            private messageService: EnkoraMessageService,
	            public dataHolder: TaActiveDataHolderService,
	            public auth: AuthenticationService,
	            public paramService: ParameterService,
	            private location: LocationService,
	            private employeeInfo: TaEmployeeInfoService)
	{

		paramService.getBooleanValue(
			'prohibit access managers from adding tokens',
			Constants.NM_EN_CTA
		).subscribe(res => {
			this.prohibit_am_from_adding_tokens = !(auth.isAdmin() || (auth.isSimplyAccessManager() && !res));
		});
	}

	private static tokenToId(token: Token): string
	{
		return token.type + '_' + token.name + '_' + token.organization_id;
	}

	private static makeTokensIds(tokens: Token[]): TokenIds
	{
		const result: TokenIds = {};
		each(tokens, token => {
			result[this.tokenToId(token)] = true;
		});

		return result;
	}

	ngOnInit(): void
	{
		const companies: TaCompanyInfo[] = [];
		this.tax_number = this.dataHolder.personal_info.tax_number;
		this.user_id = this.dataHolder.personal_info.user_id;

		this.subs = this.location.getLocation().subscribe(location => {
			this.default_organization = TaDataHelper.extractCompanyInfo(location);
			this.location_id = location.location_id;
			const params = {
				user_id     : this.user_id,
				tax_number  : this.tax_number,
				location_id : this.location_id
			};

			this.employeeInfo.getOnce(params).subscribe((employeeInfo) => {
				each(employeeInfo.employments.employers, employer => {
					if (employer.company.company_info.organization_id
						&& (employer.company.company_info.business_id || employer.company.company_info.foreign_business_id)
						&& employer.is_active) {
						companies.push(employer.company.company_info);
					}
				});
				this.addCompaniesAndTokens(companies);
			});
		});
	}

	back(): void
	{
		this.activeModal.dismiss('back');
	}

	confirm(): void
	{
		if (this.no_changes) {
			this.activeModal.close();
			return;
		}
		if (!this.is_valid) return;

		this.saveTokens();
	}

	saveTokens(): void
	{
		const multi_call = [];
		let i = 1;

		const to_delete: Token[] = [];
		const to_add: Token[] = [];

		each(this.tokens, token => {
			if (token.is_new) to_add.push(token);
		});

		const ids = TokensModal.makeTokensIds(this.tokens);

		each(this.initial, token => {
			const id = TokensModal.tokenToId(token);

			if (!ids[id]) to_delete.push(token);
		});

		each(to_delete, token => {
			multi_call.push({
				call : `common.detachToken.${i++}`,
				args : [token.existing]
			});
		});

		each(to_add, token => {
			multi_call.push({
				call : `cta2.updateToken.${i++}`,
				args : [{
					token_type      : token.type,
					token_name      : token.name,
					organization_id : token.organization_id,
					account_id      : this.dataHolder.employee.personal_info.account_id,
					location_id     : this.location_id,
					rewrite_token   : this.rewrite_token,
					is_verified     : token.is_verified
				}]
			});
		});

		this.call.make('common/multicall', [multi_call]).subscribe(
			(reply: [{ result: boolean, details: TokenMessage }]) => {
				this.token_messages = [];
				each(reply, token_response => {
					if (!token_response.result && token_response.details) {
						this.token_messages.push(token_response.details);
					}
				});

				this.call.make('cta2/getTokens', [
					{ account_id : this.dataHolder.employee.personal_info.account_id }
				]).subscribe(tokens => {
					if (this.rewrite_token || (!this.token_messages.length && !this.rewrite_token)) {
						this.activeModal.close(tokens);
					}
				});
			}, (error) => {
				this.messageService.error(error);
			});
	}

	handleTokensChange(tokens: Token[]): void
	{
		this.latest = tokens;
		if (!this.initial) {
			this.initial = cloneDeep(tokens);
			this.initial_ids = TokensModal.makeTokensIds(tokens);
		}

		this.no_changes = true;
		if (this.initial.length != tokens.length) {
			this.no_changes = false;
		} else {
			each(tokens, token => {
				const id = TokensModal.tokenToId(token);

				if (!this.initial_ids[id]) {
					this.no_changes = false;
					return false;
				}
			});
		}

		this.save_message = this.no_changes ? '' : 'Save and ';

		Logger.log('Form: Modified tokens: ', tokens);
	}


	public onTokenAdded(tokenStatus: InputToken): InputToken
	{
		if (tokenStatus.type !== InputTokenStatus.Added) return tokenStatus;
		const token = tokenStatus.token;

		const to_add: Token[] = [];
		each(this.tokens, token => {
			if (token.is_new) to_add.push(token);
		});

		const duplicated_token = find(to_add, {
			name            : token.name,
			type            : token.type,
			organization_id : token.organization_id
		});
		if (duplicated_token) {
			return {
				type    : InputTokenStatus.Error,
				message : 'The token is already added to this employee for the selected organization'
			};
		}

		const tokens = cloneDeep(this.tokens);
		tokens.unshift(token);
		this.tokens = tokens;

		const tokenParams = TaDataHelper.emptyToken;
		if (this.companies.length == 1) {
			tokenParams.organization_id = this.companies[0].organization_id;
		}

		this.handleTokensChange(tokens);

		return tokenStatus;
	}

	public updateToken(tokenStatus: InputToken): void
	{
		this.tokenStatus = this.onTokenAdded(tokenStatus);
	}

	public handleSearching(value: boolean): void
	{
		this.is_searching = value;
	}

	addCompaniesAndTokens(companies: TaCompanyInfo[]): void
	{
		this.companies = companies;
		this.tokens = this.dataHolder.tokens;
		this.handleTokensChange(this.tokens);
	}

	ngOnDestroy(): void
	{
	}
}
