<ng-container *ngIf="inputForm">
	<div class="form-group row mt-3" [formGroup]="inputForm">
		<label for="primary_contact" class="col-md-3 col-form-label text-input-label">{{ title | tt}}</label>
		<div class="col-md-7">
			<ng-select [items]="contactList"
			           labelForId="primary_contact"
			           (change)="changeContact($event)"
			           bindLabel="name"
			           bindValue="user_id"
			           [hideSelected]="true"
			           [searchable]="!readonly"
			           [clearable]="!readonly"
			           [loading]="findService.fetching"
			           [typeahead]="contactInput$"
			           [typeToSearchText]="'Type to search' | tt"
			           [notFoundText]="'No items found' | tt"
			           [loadingText]="'Loading' | tt"
			           formControlName="user_id">
				<ng-template ng-label-tmp let-item="item">
					<div>{{item.name}} <span *ngIf="item.email">({{ item.email }})</span></div>
				</ng-template>
				<ng-template ng-option-tmp let-item="item">
					<div>{{item.name}} <span *ngIf="item.email">({{ item.email }})</span></div>
				</ng-template>
			</ng-select>
		</div>
		<div class="text-end col-md-2">
			<button class="btn btn-secondary btn-sm"
			        [disabled]="readonly || show_contact"
			        (click)="showAddNewContact()" tt>
				Add new
			</button>
		</div>
	</div>

	<div *ngIf="show_contact" class="modal-form-adding pb-4">
		<div class="modal-form-adding__content pt-3">
			<div class="d-flex align-items-end mb-3">
				<h5 class="mb-0" tt> Add new contact person</h5>
				<button type="button" class="btn-modal-close close ms-auto" aria-label="Close"
				        (click)="hideNewContactForm()">
					<cta-icon-close></cta-icon-close>
				</button>
			</div>

			<cta-ta-form-new-contact-person [readonly]="readonly"
			                                [inputForm]="newContactForm">
			</cta-ta-form-new-contact-person>

			<div class="text-end">
				<button class="btn btn-primary-custom btn-sm"
				        [disabled]="readonly || !can_add_new_contact"
				        (click)="addNewContact()" tt>
					Add new contact
				</button>
			</div>
		</div>
	</div>
</ng-container>
