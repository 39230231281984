import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopInstructorItem {
	account_id: string;
	name: string;
	reservation_event_id: string;
	service_ids: string[];
	location_ids: string[];
	group_ids: string[];
}

@Injectable()
export class ShopInstructorListService extends EnkoraFetcher<ShopInstructorItem[]> {

	public instructor_map: { [reservation_group_id: string]: ShopInstructorItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ReservationInstructors' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterByLocations(service_id = '', location_ids: string[] = [], group_ids: string[] = []): Observable<ShopInstructorItem[]>
	{
		return this.getLazy().pipe(
			first(),
			map(instructor_list => {
				const location_map = Helpers.arrayToMap(location_ids);
				const group_map = Helpers.arrayToMap(group_ids);

				const filtered_list: ShopInstructorItem[] = [];

				each(instructor_list, instructor => {
					if (!instructor.service_ids || !instructor.location_ids) return;

					if (!find(instructor.service_ids, id => id == service_id)) return;
					if (!find(instructor.location_ids, id => location_map(id))) return;
					if (!find(instructor.group_ids, id => group_map(id))) return;

					filtered_list.push(instructor);
				});

				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopInstructorItem[]): ShopInstructorItem[]
	{
		this.instructor_map = {};
		each(reply, item => {
			this.instructor_map[item.account_id] = item;
		});
		return reply;
	}
}
