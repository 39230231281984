import { AfterContentInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

import { TranslationsService } from '../services';
import { AutoSubs, AutoUnsubscribe } from '../misc';

@AutoUnsubscribe()
@Directive({
	selector : '[tt]'
})
export class TtDirective implements AfterContentInit, OnDestroy {
	@AutoSubs() subs;

	constructor(private el: ElementRef<HTMLElement>,
	            private renderer: Renderer2,
	            private translate: TranslationsService)
	{
	}

	ngAfterContentInit(): void
	{
		let innerText = this.el.nativeElement.innerText.trim();
		if (!innerText.length) innerText = this.el.nativeElement.innerHTML.trim();
		this.subs = this.translate.getLazy().subscribe(() => {
			const translation = this.translate.tt(innerText);
			const containsHtml = TtDirective.containsHtml(innerText) || TtDirective.containsHtml(translation);
			// TODO: Move tt away from Enkora class
			this.renderer.setProperty(
				this.el.nativeElement,
				containsHtml ? 'innerHTML' : 'innerText',
				translation
			);
			// Debug accidental dynamic interpolation with tt directive which will cause empty textNode
			if (!innerText.length) {
				console.warn(
					'\n\n%c TtDirective warning: Empty string. %c\n\n\nPossible reasons:\n\n1. Interpolation mixed with tt.\n\n2. Empty element.\n\n\nElement: ',
					'background: black; color: #ffcc00; padding: 5px 0; font-weight: bold;', 'color: black;',
					this.el, '\n\n', this.el.nativeElement, '\n\n'
				);
			}
		});
	}

	private static containsHtml(text : string) : boolean
	{
		const htmlRegex = /<[^>]+>/g;
		return htmlRegex.test(text);
	}

	ngOnDestroy(): void
	{
	}
}
