<div class="modal-header">
	<h3 class="modal-title" tt>New Reservation</h3>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body hotel-reservations-modal">
	<form class="hotel-fields" [formGroup]="form" *ngIf="form">
		<div class="form-group row">
			<label class="offset-1 col-3 col-form-label" tt>Resource</label>
			<div class="col-8">
				<div class="input-group input-group-sm">
					<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-home"></i>
							</span>
					</div>
					<input formControlName="resource"
					       class="form-control"
					       type="text"
					       id="inputResource"
					       name="resource"
					       readonly>
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label class="offset-1 col-3 col-form-label" tt>Check-in date</label>
			<div class="col-8">
				<div class="input-group input-group-sm">
					<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-calendar"></i>
							</span>
					</div>

					<input class="form-control"
					       id="checkindatepicker"
					       ngbDatepicker
					       #dp2="ngbDatepicker"
					       readonly
					       formControlName="checkin_time"
					       [placeholder]="'dd.mm.yyyy' | tt">
				</div>
			</div>
		</div>

		<div class="form-group row mb-0">
			<label class="offset-1 col-3 col-form-label" tt>Check-out date</label>
			<div class="col-8">
				<div class="input-group input-group-sm">
					<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-calendar"></i>
							</span>
					</div>
					<ng-template #dateFooter>
						<button class="btn btn" style="float: right; margin: 5px" tt
						        (click)="dp.toggle()">
							Close
						</button>
					</ng-template>
					<input class="form-control"
					       id="checkoutdatepicker"
					       style="cursor: pointer"
					       ngbDatepicker
					       #dp="ngbDatepicker"
					       [displayMonths]="2"
					       readonly
					       navigation="arrows"
					       formControlName="checkout_time"
					       [placeholder]="'dd.mm.yyyy' | tt"
					       [minDate]="min_date"
					       [maxDate]="max_date"
					       [footerTemplate]="dateFooter"
					       (click)="dp.toggle()"
					       (dateSelect)="eventTimeChanged()">
				</div>
			</div>
		</div>

		<div class="form-group row mb-0">
			<label class="offset-1 col-3 col-form-label"></label>
			<div class="col-8">
				<div class="input-group input-group-sm">
					<div class="d-flex flex-row bd-highlight">
						<div class="p-2 bd-highlight first-col"><span tt>Check-in</span>:</div>
						<div class="p-2 bd-highlight second-col">{{form.get('resource_date_start').value}}</div>
						<div class="p-2 bd-highlight third-col"><span tt>At</span>&nbsp;<span tt>{{form.get('resource_time_start').value}}</span></div>
					</div>
				</div>
				<div class="input-group input-group-sm">
					<div class="d-flex flex-row bd-highlight mb-3">
						<div class="p-2 bd-highlight first-col"><span tt>Check-out</span>:</div>
						<div class="p-2 bd-highlight second-col">{{form.get('resource_date_end').value}}</div>
						<div class="p-2 bd-highlight third-col"><span tt>At</span>&nbsp;<span tt>{{form.get('resource_time_end').value}}</span></div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label class="offset-1 col-3 col-form-label" tt>Total</label>
			<div class="col-8">
				<div class="input-group input-group-sm">
					<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-tags"></i>
							</span>
					</div>
					<input [readOnly]="true"
					       formControlName="total_str"
					       class="form-control"
					       type="text"
					       id="total"
					       name="total">
				</div>
			</div>
		</div>
	</form>

	<hr>

	<shop-fields [shop_fields]="fare_product_shop_fields">
	</shop-fields>

	<shop-fields [shop_fields]="resource_shop_fields">
	</shop-fields>

	<shop-alert-message [alert]="alert"></shop-alert-message>
</div>

<div class="modal-footer">
	<button class="btn btn"
	        id="cancelButton" tt
	        (click)="activeModal.dismiss()">
		Cancel
	</button>

	<button type="submit"
	        class="btn btn-primary"
	        id="reserveButton"
	        [disabled]="form?.invalid
	            || resource_fields_form?.invalid
	            || fare_product_fields_form?.invalid
	            || loading_extra_fields"
	        (click)="reserve()">
		<span tt class="ms-1">Reserve</span>
	</button>
</div>
