<div class="modal-header" #modalContent>
	<span class="aria-focus" tabindex="0"></span>
	<h3 class="modal-title" tabindex="0" tt>Login</h3>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<p class="lead" tabindex="0" tt>Login required. Please use your existing account or create a new.</p>

	<shop-alert-message [alert]="alert"></shop-alert-message>

	<div class="form-group row" *ngIf="!hide_login">
		<label class="offset-1 col-3 col-form-label" for="inputEmail" tt>Email</label>

		<div class="col-8">
			<div class="input-group input-group-sm">
				<div class="input-group-prepend">
						<span class="input-group-text">
							<i class="fa fa-envelope"></i>
						</span>
				</div>
				<input class="form-control" type="email" id="inputEmail" name="email" [(ngModel)]="email">
			</div>
		</div>
	</div>

	<div class="form-group row" *ngIf="!hide_login">
		<label class="offset-1 col-3 col-form-label" for="inputPassword" tt>Password</label>

		<div class="col-8">
			<div class="input-group input-group-sm">
				<div class="input-group-prepend">
						<span class="input-group-text">
							<i class="fa fa-key"></i>
						</span>
				</div>
				<input class="form-control" type="password" id="inputPassword" name="password" [(ngModel)]="password">
			</div>
			<a class="forgotPassword" href="javascript:void(0)" (click)="needReminder()" tt>Forgot password?</a>
		</div>
	</div>

	<div class="login_options">
		<div class="row">

			<div [ngClass]="[!hide_login ? 'col-8 offset-4' : 'col-12']">
				<div class="login_options_container" [class.multiply_login_options]="!hide_login" *ngIf="!hide_suomi_login">
					<a class="btn-suomi" [href]="getSuomiLogin() | safeUrl"
					   tabindex="0" [attr.aria-label]="'Login with Suomi.fi' | tt">
						<img class="img-fluid" src="dist/assets/suomifi.png" alt="suomi.fi" aria-hidden="true"/>
						<span class="btn btn-primary" aria-hidden="true" tt>Login with Suomi.fi</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer" *ngIf="!only_login || !hide_login">
	<button class="btn"
	        id="registerButton"
	        [disabled]="is_logging_in"
	        (click)="needRegister()"
	        *ngIf="!only_login" tt>
		No account? Create new
	</button>

	<button type="submit"
	        class="btn btn-primary"
	        [disabled]="is_logging_in"
	        *ngIf="!hide_login"
	        (click)="doLogin()"
	        id="loginButton">

		<i class="fa fa-sign-in"></i>

		<span tt class="ms-1">Login</span>
	</button>

</div>
