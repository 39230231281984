import { Component, HostListener } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';

declare let Enkora : { tt : (a : string, b? : string | number) => string };

declare const window : { language : string };

import { GlobalClickNotifierService } from './shared/services';

@Component({
	selector    : 'app-root',
	templateUrl : './app.component.html',
	styleUrls   : ['./app.component.scss']
})
export class AppComponent {
	@HostListener('document:click', ['$event'])
	onClick(event : MouseEvent)
	{
		this.clickNotifier.notifyClickEvent(event);
	}

	constructor(private clickNotifier : GlobalClickNotifierService,
	            private primengConfig : PrimeNGConfig,
	            private config: NgSelectConfig)
	{
		if (window.language) {
			let res_lang : string = window.language.trim().toLowerCase();
			if (res_lang == 'en') moment.locale('en-gb');
			else moment.locale(res_lang);
		}
		let localeData = moment.localeData();
		const lang = {
			firstDayOfWeek  : 1,
			dayNames        : localeData.weekdays(),
			dayNamesShort   : localeData.weekdaysShort(),
			dayNamesMin     : localeData.weekdaysMin(),
			monthNames      : localeData.months(),
			monthNamesShort : localeData.monthsShort()
		};

		this.primengConfig.setTranslation(lang);

		this.config.notFoundText = Enkora.tt('No results');
	}
}
