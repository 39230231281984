import { Injectable } from '@angular/core';
import { CustomerDataInterface } from "./customer-data.interface";
import {SocialSecurityNumberParserService} from "./social-security-number-parser.service";

import * as _ from "lodash";

@Injectable()
export class CustomerDataService {

  empty_account : CustomerDataInterface = {
    account_id             : null,
    social_security_number : "",
    first_name             : "",
    middle_names           : "",
    last_name              : "",
    email                  : "",
    address                : {
      street   : "",
      postcode : "",
      city     : "",
    },
    phone_number           : ""
  };

  constructor(private ssnParser : SocialSecurityNumberParserService) {
  }

  initAccount(account) {
    let new_account : CustomerDataInterface = _.cloneDeep(this.empty_account);

    new_account.account_id = account.account_id;
    new_account.user_id = account.user_id;
    new_account.name = account.name;
    new_account.social_security_number = account.social_security_number;
    new_account.phone_number = account.phone_number;
    new_account.email = account.email;
    new_account.language_id = account.language_id;
    new_account.tags = account.tags;

    new_account.agreement_id = null;
    if (account.account_agreement_ids && account.account_agreement_ids.length) {
      new_account.agreement_id = account.account_agreement_ids[0];
    }

    new_account.first_name = account.first_name;
    new_account.last_name = account.last_name;
    new_account.middle_names = account.middle_names;

    if (account.need_safety_terms_checkbox) {
        new_account.need_safety_terms_checkbox = true;
    }

    if (account.properties == 'is_safety_terms_accepted: 1') {
        new_account.is_safety_terms_accepted = true;
    }

    if (!new_account.first_name || !new_account.last_name) {
      let words = account.name.split(' ');
      if (words.length > 1) {
        new_account.last_name = words.pop();
        new_account.first_name = words.shift();
        new_account.middle_names = words.join(" ");

      } else if (words.length > 0) {
        new_account.last_name = words[0];
        new_account.first_name = "";
        new_account.middle_names = "";
      }
    }

    if (account.address && !account.address_parts) {
      new_account.address.street = account.address;
    } else if (account.address_parts) {
      new_account.address.postcode = account.address_parts.postcode;
      new_account.address.city = account.address_parts.city;
      new_account.address.street = account.address_parts.street;
    }

    let details = this.ssnParser.parseSocialSecurityNumber(new_account.social_security_number);
    if (details) new_account.ssn_age = details.age;

    return new_account;
  }
}
