import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { each, find } from 'lodash';
import moment, { Moment } from 'moment';
import { interval, Subscription } from 'rxjs';

import {
	AuthenticationService,
	AutoSubs,
	AutoUnsubscribe,
	CallService,
	Constants,
	Helpers,
	EnkoraMessageService,
	ModalOpenerService,
	TickService
} from 'shared';

import { CourseParticipantsModal, CourseTimetableModal, InfoModal, ShopLoginStepsService } from '../../../modals';
import { ReservationEvent } from '../../../../reservation/services/interfaces';
import {
	CartItem,
	CourseFareProduct,
	EventGroupReservation,
	ShopCartListService,
	ShopCourseEventGroupsService,
	ShopParametersService, ShopSelectorsStateService,
	ShopServerTimeService
} from '../../../services';
import { AlertCode, AlertType } from '../../../alerts';
import { CourseError } from '../interfaces/general.interface';
import { PrereservationService } from '../../../services/prereservation.service';

export enum CourseDatePresentation {
	SAME_DAY_RECURRING = 'SAME_DAY_RECURRING',
	MIXED_DAYS_SAME_TIME = 'MIXED_DAYS_SAME_TIME',
	MIXED_DAYS = 'MIXED_DAYS',
	ONE_DAY = 'ONE_DAY'
}

export interface CourseItem {
	title: string;
	description: string;
	description_long?: string;
	location: string;
	location_id: number;
	date: string;
	time: string;
	original_price: number;
	price: number;
	price_range: number[];
	start: string;
	end: string;
	weekdays: number[];
	accessibility: AccessibilityType;
	public_reservation_start: string;
	public_reservation_start_formatted: string;
	capacity: string;
	free: string;
	free_queue: string;
	amount: number;
	fare_product_id: number;
	related_reservation_event_group_id: number;
	reservation_events: ReservationEvent[];
	reservations: EventGroupReservation[];
	max_age: number;
	min_age: number;
	tag_ids: string[];
	fare_products: CourseFareProduct[];
	tags: { tag_id: number, name: string, tag_group_type_id?: number, tag_group_name?: string }[];
}

export enum AccessibilityType {
	ReservableInFuture = 'ReservableInFuture',
	NotReservable = 'NotReservable',
	Free = 'Free',
	FreeWithCapacity = 'FreeWithCapacity',
	FreePlaces = 'FreePlaces',
	ManyAvailable = 'ManyAvailable',
	FewAvailable = 'FewAvailable',
	FreeQueue = 'FreeQueue',
	QueueSpace = 'QueueSpace',
	Full = 'Full',
}

declare const Enkora: { tt: (a: string) => string };

@AutoUnsubscribe()
@Component({
	selector      : 'shop-course-item',
	encapsulation : ViewEncapsulation.None,
	templateUrl   : './course-item.component.html',
	styleUrls     : ['./course-item.component.scss']
})
export class ShopCourseItemComponent implements OnInit, OnDestroy {
	@Input() set course(course: CourseItem) {
		this.course_item = course;
		this.date_presentation = this.getDatePresentation(course);
		this.setTimer();
		this.translateTags(this.course_item);
		this.course_url = this.getUrl(course);

		const event_group = this.eventGroupService.event_groups.find(
			eg => eg.reservation_event_group_id == this.course_item.related_reservation_event_group_id
		);

		if (event_group) {
			this.accessibilityLabel = this.eventGroupService.getAccessibilityLabel(event_group);
		}

		this.description_long_title = Helpers.translateKey('View full description', 'View full description', true);
		this.description_long = Helpers.translateKey(
			`reservation_event_group ${course.related_reservation_event_group_id} description_long`,
			course.description_long,
			true
		);

		this.course_item.description = Helpers.translateKey(
			`reservation_event_group ${course.related_reservation_event_group_id} description`,
			this.course_item.description,
			true
		);

		this.course_item.title = Helpers.translateKey(
			`reservation_event_group ${course.related_reservation_event_group_id} name`,
			this.course_item.title,
			true
		);

		this.course_item.location = Helpers.translateKey(
			`location ${course.location_id} name`,
			this.course_item.location,
			true
		);
	}

	@Input() append_long_description = false;
	@Input() hide_label = false;
	@Input() text_only = false;
	@Input() allow_cancel = false;
	@Input() set disable_buying(value: boolean) {
		this.disable_buy_button = value;
	}

	@Output() cancel = new EventEmitter<CourseItem>();

	@AutoSubs() subs;

	private server_time: Moment;
	private server_time_delay_ms = 0;
	public accessibilityLabel: AccessibilityType = null;
	public description_long_title = '';
	public description_long = '';
	public disable_buy_button = false;
	public course_item: CourseItem;
	public accessibilityType = AccessibilityType;
	public date_presentation: CourseDatePresentation;
	public presentation = CourseDatePresentation;
	public constants = Constants;
	public time_until_start_duration: moment.Duration;
	public timer$: Subscription;
	public disable_reservations_countdown_timer = false;
	public cancel_confirm = false;
	public course_url: string = null;
	public alert: AlertType = {
		code    : null,
		message : '',
		title   : ''
	};

	constructor(
		private call: CallService,
		private modalHelper: ModalOpenerService,
		private auth: AuthenticationService,
		private params: ShopParametersService,
		private loginStepsService: ShopLoginStepsService,
		private cartService: ShopCartListService,
		private tickService: TickService,
		private prereservation: PrereservationService,
		private eventGroupService: ShopCourseEventGroupsService,
		private serverTimeService: ShopServerTimeService,
		private messageService: EnkoraMessageService,
		private selectors: ShopSelectorsStateService,
	) {
		this.subs = this.auth.value$.subscribe(() => {
			this.translateTags(this.course_item);
		});
	}

	ngOnInit(): void
	{
		this.disable_reservations_countdown_timer = this.params.data.disable_reservations_countdown_timer;
	}

	private setTimer()
	{
		if (this.course_item.accessibility === this.accessibilityType.ReservableInFuture
			&& !this.disable_reservations_countdown_timer
		) {
			this.server_time = this.serverTimeService.getServerTime();
			this.server_time_delay_ms = this.server_time.diff(moment());

			this.timer$ = interval(1000)
			.subscribe(() => {
				const current_time = moment().add(this.server_time_delay_ms);
				const time_until_start = this.getTimeUntilPublicReservation(current_time, this.course_item.public_reservation_start);

				if (time_until_start <= 0) {
					const event_group = this.eventGroupService.event_groups.find(
						eg => eg.reservation_event_group_id == this.course_item.related_reservation_event_group_id
					);

					event_group.is_reservable = true;
					this.course_item.accessibility = this.eventGroupService.getAccessibilityType(event_group);
					this.timer$.unsubscribe();
				}

				this.time_until_start_duration = moment.duration(time_until_start);
			});

			this.subs = this.timer$;
		}
	}

	public buy(): void
	{
		if (this.hasConflicts()) { return; }
		if (!this.auth.isAuthenticated() && !this.params.data.allow_cart_before_login) {
			this.loginStepsService.login().subscribe(value => {
				if (value) {
					this.openParticipantsModal();
				}
			});
			return;
		}

		this.tickService.checkTickObservable().subscribe(res => {
			if (res) {
				this.openParticipantsModal();
			} else {
				this.loginStepsService.login().subscribe(value => {
					if (value) {
						this.openParticipantsModal();
					}
				});
			}
		});
	}

	private hasConflicts(): boolean
	{
		if (!this.course_item?.fare_products?.length) { return false; }
		if (!this.cartService.value?.items?.length) { return false; }

		let result = false;
		each(this.course_item.fare_products, fp => {
			if (fp.organization_id != this.cartService.value.items[0].organization_id) {
				this.messageService.error(
					Enkora.tt('Cannot have fare products from multiple organizations in the same cart')
				);

				result = true;
				return false;
			}
		});

		return result;
	}

	private openParticipantsModal(): void
	{
		const modalRef = this.modalHelper.openStaticShopModal(
			CourseParticipantsModal,
			{ keyboard : false }
		);

		modalRef.componentInstance.input = {
			course : this.course_item,
		};
	}

	public openTimetableModal(): void
	{
		const modalRef = this.modalHelper.openShopModal(CourseTimetableModal);
		modalRef.componentInstance.input = {
			course : this.course_item
		};
	}

	private getDatePresentation(course: CourseItem): CourseDatePresentation
	{
		if (course.weekdays.length === 1 && course.reservation_events.length === 1) {
			return CourseDatePresentation.ONE_DAY;
		} else if (course.weekdays.length === 1 && course.reservation_events.length > 1) {
			return CourseDatePresentation.SAME_DAY_RECURRING;
		} else if (course.weekdays.length > 1 && course.reservation_events.length > 1) {
			if (this.getEventTimesEqual(course.reservation_events)) {
				return CourseDatePresentation.MIXED_DAYS_SAME_TIME;
			} else {
				return CourseDatePresentation.MIXED_DAYS;
			}
		} else {
			return CourseDatePresentation.MIXED_DAYS;
		}
	}

	private getEventTimesEqual(reservation_events: ReservationEvent[]): boolean
	{
		const times_map = new Map<string, boolean>();
		each(reservation_events, event => {
			const time = moment(event.time_start).format('HH:mm') + '-' + moment(event.time_end).format('HH:mm');
			times_map.set(time, true);
		});
		return Array.from(times_map.keys()).length === 1;
	}

	public cancelReservation(reservation: EventGroupReservation): void
	{
		if (reservation.reservation_status_id === Constants.RES_UNCONFIRMED) {
			const fare_product_ids = this.course_item.fare_products.map(fp => fp.fare_product_id);
			const cart_item = this.findCartItem(
				fare_product_ids,
				reservation.reservation_event_group_id,
				reservation.reservation_account_id
			);

			if (cart_item) {
				this.cartService.removeItem(cart_item).subscribe((success) => {
					if (success) {
						this.prereservation.removePreReservation(
							String(cart_item.reservation_params.reservation_account_id),
							cart_item.reservation_params.reservation_event_group_id,
							cart_item.fare_product_id
						);
					}
				}, () => {
					this.alert = {
						code    : AlertCode.Error,
						message : Enkora.tt('Cancellation failed'),
						title   : Enkora.tt('Cancel Course Error')
					}
				});
			} else {
				this.call.make('reservation2/deleteR2EventGroupReservations', {
					reservation_id : reservation.reservation_id
				})
				.subscribe(() => {
					this.cancel.emit(this.course_item);
				}, () => {
					this.alert = {
						code    : AlertCode.Error,
						message : Enkora.tt('Cancellation failed'),
						title   : Enkora.tt('Cancel Course Error')
					}
				});
			}
		} else if (
			reservation.reservation_status_id === Constants.RES_QUEUE
			|| !this.course_item.price
			|| this.params.data.allow_course_cancellation_for_customers
		) {
			this.call.make('reservation2/deleteR2EventGroupReservations', {
				reservation_id : reservation.reservation_id
			})
			.subscribe(() => {
				this.cancel.emit(this.course_item);
			}, () => {
				this.alert = {
					code    : AlertCode.Error,
					message : Enkora.tt('Cancellation failed'),
					title   : Enkora.tt('Cancel Course Error')
				}
			});
		} else if (this.params.data.cancelling_instructions) {
			this.alert = {
				code    : AlertCode.Info,
				message : this.params.data.cancelling_instructions,
				title   : Enkora.tt('Cancel Course Instructions')
			};
		} else {
			this.alert = {
				code    : AlertCode.Error,
				message : Enkora.tt('Cancelling the lesson is not allowed'),
				title   : Enkora.tt('Cancel Course Error')
			};
		}
	}

	private findCartItem(fare_product_id: number[], event_group_id: number, account_id: number): CartItem
	{
		return find(
			this.cartService.value.items,
				item => event_group_id == item.reservation_event_group_id
					&& account_id == item.reservation_params?.reservation_account_id
					&& fare_product_id.includes(item.fare_product_id)
		);
	}

	public resetAlert(): void
	{
		this.alert = {
			code    : null,
			message : '',
			title   : ''
		};
	}

	private getTimeUntilPublicReservation(server_time: Moment, public_reservation_start: string): number
	{
		const time_now = server_time;
		const start = moment(public_reservation_start, 'YYYY-MM-DD HH:mm:ss');

		if (start.isAfter(time_now)) {
			return start.diff(time_now);
		}

		return 0;
	}

	private parseError(error: CourseError): string
	{
		if (typeof error === 'string') {
			return error;
		} else {
			return error?.user_errors ? error?.user_errors[0] : error?.errors[0];
		}
	}

	public isCancellationAllowed(reservation: EventGroupReservation): boolean
	{
		return this.allow_cancel
			|| (reservation.reservation_status_id === Constants.RES_UNCONFIRMED
				|| reservation.reservation_status_id === Constants.RES_UNCONFIRMED_QUEUE);
	}

	public toggleCancelConfirm(): void
	{
		this.cancel_confirm = !this.cancel_confirm;
	}

	private translateTags(course: CourseItem): void
	{
		each(course.tags, tag => {
			tag.name = Helpers.translateKey(`tag name ${tag.tag_id}`, tag.name, true);
		});
	}

	public goToCourse(course: CourseItem, event: MouseEvent): void
	{
		event.preventDefault();

		this.selectors.setState({
			course_id : String(course.related_reservation_event_group_id)
		});
	}

	private getUrl(course: CourseItem): string
	{
		const url_split = location.href.split('reservations');
		if (url_split.length < 2) { return }

		const params = url_split?.length > 0 ? url_split[1].split('/') : null;

		if (params?.length >= 7) {
			params[params.length - 1] = String(course.related_reservation_event_group_id);
		}

		return url_split[0] + 'reservations' + params.join('/');
	}

	public showLongDescription(): void
	{
		const modalRef = this.modalHelper.openShopModal(InfoModal);
		modalRef.componentInstance.input = {
				type_class : 'text-secondary',
				title      : 'Long description',
				text       : this.description_long
		};
	}

	ngOnDestroy(): void
	{
	}
}
