import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { TaDataHelper, TaFindUsersAndContactsService } from '../../services';
import { TaContact, TaContactType } from '../../interfaces';

@Component({
	selector    : 'cta-ta-form-contact-type-selector',
	templateUrl : './contact-type-selector.form.html',
	providers   : [TaFindUsersAndContactsService],
	styleUrls   : ['./contact-type-selector.form.scss']
})
export class ContactTypeSelectorForm implements OnInit {

	@Input() public readonly = false;

	public contactList: TaContact[] = [];
	public contactInput$ = new Subject<string>();
	contact$ = new Subject<TaContact>();

	constructor(public findService: TaFindUsersAndContactsService)
	{
	}

	public _inputForm: FormGroup;

	public get inputForm()
	{
		return this._inputForm;
	}

	@Input()
	public set inputForm(fg: FormGroup)
	{
		this._inputForm = fg as FormGroup;

		if (!fg) return;

		this.type = {
			contact_type_id   : fg.get('contact_type_id').value,
			contact_type_name : fg.get('contact_type_name').value
		};

		this.contactList = fg && fg.value.user_id ? [fg.value] : [];

		this.contactInput$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(name =>
			this.findService.get(true, { name, contact_type_id : this._type.contact_type_id })
		);

		this.findService.getLazy().subscribe(list => this.contactList = list);

		if (fg) {
			this.contact$.pipe(debounceTime(200)).subscribe(value => {
				if (value) this.inputForm.patchValue(value as any);
			});
		}
	}

	private _type: TaContactType = TaDataHelper.emptyContactType;

	// public selectedContact : TaContact = null;

	@Input() get type(): TaContactType
	{
		return this._type;
	}

	set type(value: TaContactType)
	{
		this._type = value;
	}

	ngOnInit(): void
	{
	}

	changeContact(contact: TaContact)
	{
		this.contact$.next(contact);
	}
}
