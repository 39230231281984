import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from 'lodash';

@Pipe({
	name : 'centstoeuros'
})
export class CentsToEurosPipe implements PipeTransform {

	transform(cents: string | number, sign  = '', locale = 'fi'): string
	{
		if (cents == '') return '';

		cents = toNumber(cents);
		if (!cents) cents = 0;

		let value = ((cents / 100).toFixed(2));
		if (locale) value = toNumber(value).toLocaleString(locale);
		if (sign) value = value + ' ' + sign;

		return value;
	}
}
