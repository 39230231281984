<div class="modal-header">
	<h2 class="no-margin-bottom">
		{{ (dataHolder.is_add_new_employee ? 'Add a new employee' : 'Edit permission') | tt }}
	</h2>
	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="close()">
		<cta-icon-close></cta-icon-close>
	</button>
</div>

<div class="modal-body">
	<cta-ta-add-employee-progressbar *ngIf="!standalone"
	                                 [currentStep]="4">
	</cta-ta-add-employee-progressbar>

	<p class="mb-5" tt>
		Time and attendance permissions modal description
	</p>

	<h4>
		{{ (dataHolder.is_add_new_employee ? 'Add employee permission groups' : 'Edit employee permission groups') | tt }}
	</h4>

	<cta-ta-permission-group [initial]="dataHolder.employee.groups"
	                         [companies]="companies"
	                         [showIndividualEndDate]="show_individual_end_date"
	                         [showHeaderIndividualEndDate]="show_header_individual_end_date"
	                         (dataChange)="handlePermissionChange($event)">
	</cta-ta-permission-group>
</div>

<div class="modal-footer">
	<button type="button" class="btn btn-secondary me-auto" *ngIf="!standalone" (click)="back()" tt>Previous</button>

	<button type="button" class="btn btn-secondary" (click)="close()" tt>Close</button>

	<button type="button"
	        class="btn btn-primary-custom"
	        *ngIf="save_message || !standalone"
	        [disabled]="!is_valid && !no_changes"
	        (click)="confirm()">
		{{save_message + (standalone ? 'close' : 'continue') | titlecase | tt}}
	</button>
</div>
