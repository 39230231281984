import { Injectable } from '@angular/core';
import { CallService } from "../../shared/services/call.service";
import { Observable, of, Subject } from 'rxjs';

import * as _ from "lodash";
import { first } from 'rxjs/operators';

@Injectable()
export class InvoicingPeriodsService {
	get invoicingPeriods() : any[] {
		return this._invoicingPeriods;
	}

	set invoicingPeriods(value : any[]) {
		this._invoicingPeriods = value;
		this.invoicingPeriodMap = {};
		_.forEach(value, (invoicing_period : any) => {
			this.invoicingPeriodMap[invoicing_period.invoicing_period_id] = invoicing_period.name;
		});

		this.invoicingPeriods$.next(value);
	}

    invoicingPeriodMap = {};

	private _requested : boolean = false;
	private _resolved : boolean = false;
	private _invoicingPeriods : any[] = [];

	public invoicingPeriods$ : Subject<any[]> = new Subject();

	constructor(private call : CallService) {
	}

	getInvoicingPeriods() {
		if (!this._requested) {
			this._requested = true;

			this.call.make("invoice/getInvoicingPeriods").subscribe((invoicingPeriods : any[]) => {
				this._resolved = true;
				this.invoicingPeriods = invoicingPeriods;
			}, (error) => {
				this.invoicingPeriods = [];
			});

			return this.invoicingPeriods$.pipe(first());
		}

		if (!this._resolved) return this.invoicingPeriods$;
		return of(this.invoicingPeriods);
	}
}
