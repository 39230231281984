<!-- Company representative -->
<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">
		<ng-template #rt let-value="result" let-t="term">
			<div style="font-size:18px">{{value.name}}</div>
		</ng-template>
		<div class="row mt-5">
			<h5 class="col-md-5 align-self-center">{{
					(is_foreign_contact
						? 'Representative of Foreign Workers'
						: 'Company representative') | tt
				}}</h5>
			<div class="col-md-7">
				<button type="button"
				        class="mb-2 btn btn-primary-custom"
				        *ngIf="is_selected_contact && editable_contact"
				        [disabled]="is_start_edit"
				        (click)="startEdit()"
				        tt>
					Change person
				</button>
			</div>
		</div>
		<div class="form-group row">
			<label for="first_name" class="col-md-3 col-form-label text-input-label" tt>First name</label>
			<div class="col-md-9">
				<input id="first_name"
						 class="form-control"
				       type="text"
				       formControlName="first_name"
				       [ngbTypeahead]="searchFirstName"
				       [readonly]="readonly"
				       [resultTemplate]="rt"
				       (selectItem)="onSelect($event)">
			</div>
		</div>
		<div class="form-group row">
			<label for="last_name" class="col-md-3 col-form-label text-input-label" tt>Last name</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       id="last_name"
				       formControlName="last_name"
				       [ngbTypeahead]="searchLastName"
				       [readonly]="readonly"
				       [resultTemplate]="rt"
				       (selectItem)="onSelect($event)">
			</div>
		</div>
		<div class="form-group row">
			<label for="phone_number" class="col-md-3 col-form-label text-input-label" tt>Phone number</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="phone_number" name="phone_number"
				       formControlName="phone_number"
				       [readonly]="readonly">
				<small class="form-text text-gray" tt>
					In the international format, example: +35401234567
				</small>
			</div>
		</div>
		<div class="form-group row">
			<label for="email" class="col-md-3 col-form-label text-input-label" tt>Email</label>
			<div class="col-md-9">
				<input class="form-control" type="text" id="email" name="email" formControlName="email"
				       [readonly]="readonly">
			</div>
		</div>
		<div class="form-group row">
			<label for="is_location_specific" class="col-md-3 col-form-label text-input-label" tt>
				Is location specific
			</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="checkbox"
				       id="is_location_specific"
				       name="is_location_specific"
				       formControlName="is_location_specific"
				       [attr.disabled]="readonly ? true : null">
			</div>
		</div>
		<div class="alert alert-danger" *ngIf="!is_location_specific && is_start_edit">
			<span>{{
					(is_foreign_contact
						? 'TA foreign representative note'
						: 'TA representative note') | tt
				}}</span>
		</div>
	</div><!-- /Company representative -->
</ng-container>
