import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from '../../shared';

export interface ReservationTag {
	tag_id : string;
	name : string;
}

export interface ReservationTags {
	map : { [tag_id : string] : ReservationTag }
	values : ReservationTag[];
}

@Injectable()
export class CustomerGroupTagService extends EnkoraFetcher<ReservationTags> {

	params : CallParameter[] = [{name : 'reservation/getCustomerGroupTags'}];

	constructor(call : CallService)
	{
		super(call);
	}

	protected postProcess(reply : any[])
	{
		const result : ReservationTags = {
			map    : {},
			values : []
		};
		each(reply, t => {
			const tag : ReservationTag = {
				tag_id : t.tag_id,
				name   : t.name
			};

			result.values.push(tag);
			result.map[tag.tag_id] = tag;
		});

		return result;
	}
}
