import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CallParameter, CallService, EnkoraFetcher, Logger } from 'shared';

import { TaDataHelper, TaEmployee, TaGroup } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaEmployeeInfoService extends EnkoraFetcher<TaEmployee> {

	protected params: CallParameter[] = [{ name : 'cta2/getUserById' }];

	constructor(call: CallService)
	{
		super(call);
	}

	preProcess(options?: any)
	{
		Logger.log('Get user data: options = ', options);

		this.params = [{
			name    : options.user_id ? 'cta2/getUserById' : 'cta2/searchUserData',
			content : [
				{
					user_id     : options.user_id || null,
					employee_id : options.employee_id || null,
					tax_number  : options.tax_number || null,
					card_number : options.card_number || null,
					location_id : options.location_id || null
				}
			]
		}];
	}

	postProcess(data)
	{
		return TaDataHelper.extractEmployee(data);
	}

	getEmployeeGroups(): Observable<TaGroup[]>
	{
		return this.call.make('cta2/getEmployeeGroups', []);
	}

	getEmployerRoles(): Observable<TaGroup[]>
	{
		return this.call.make('cta2/getOrganizationRoles', []).pipe(
			map(reply => {
				return TaDataHelper.extractOrganizationRoles(reply);
			})
		);
	}
}
