import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector    : 'cta-ta-form-new-contact-person',
	templateUrl : './new-contact-person.form.html',
	styleUrls   : ['./new-contact-person.form.scss']
})
export class NewContactPersonForm implements OnInit {
	@Input() public inputForm: FormGroup;
	@Input() public readonly = false;

	public id = '';

	constructor()
	{
		this.id = `address${Math.floor(Math.random() * 1000000)}`;
	}

	ngOnInit(): void
	{
	}

}
