<ng-container *ngIf="inputForm"><!-- Company info -->
	<div [formGroup]="inputForm">
		<div class="form-group row">
			<label for="company_country" class="col-md-3 col-form-label text-input-label" tt>Country</label>
			<div class="col-md-9">
				<ng-select
					type="text"
					id="company_country"
					[readonly]="disableCountry"
					[clearable]="false"
					formControlName="country_code">

					<ng-option value="" ng-model="selected" tt>Select country...</ng-option>
					<ng-option *ngFor="let country of countries" [value]="country.id">
						{{country.name}}
					</ng-option>
				</ng-select>
			</div>
		</div>

		<div *ngIf="inputForm.contains('address')">
			<cta-ta-form-address [inputForm]="abstractControlToFormGroup(inputForm.get('address'))"
			                     [home_country_code]="selectedCountryCode"
			                     [readonly]="readonly">
			</cta-ta-form-address>
		</div>
	</div>
</ng-container><!-- /Company info -->
