import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GuidService, Helpers } from '../../../../../shared';

@Component({
	selector    : 'cta-order-form-delivery-details',
	templateUrl : './delivery-info.form.html',
	styleUrls   : ['./delivery-info.form.scss']
})
export class DeliveryInfoForm implements OnInit {

	@Input() public inputForm: FormGroup;

	public id: string;
	public min_date = null;

	constructor(guid: GuidService)
	{
		this.id = `delivery_info_${guid.Next}`;
		this.min_date = Helpers.momentToNgbDate(Helpers.getNextBusinessDay());
	}

	ngOnInit(): void
	{
	}
}
