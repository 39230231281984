import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';
import { BehaviorSubject } from 'rxjs';

export interface ShopTagGroupItem {
	name: string;
	tag_id: number;
	service_ids: string;
	tag_group_id?: number;
	tag_group_name?: string;
	name_translated?: string;
}

@Injectable()
export class ShopTagGroupListService extends EnkoraFetcher<ShopTagGroupItem[]> {
	public included_tag_ids_subject = new BehaviorSubject<number[]>([]);

	protected params: CallParameter[] = [{ name : 'reservation2/getR2CourseFilterTagGroupTags' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public updateIncludedTagIds(ids: number[])
	{
		this.included_tag_ids_subject.next(ids);
	}
}
