import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FormProductLineDataService {
	get data() : any {
		return this._data;
	}

	set data(value : any) {
		this._data = value;
		this.data$.next(value);
	}

	private _data : {
		id                 ? : any,
		is_closed          ? : any,
		products           ? : any,
		available_products ? : any,
		fare_products      ? : any
	} = {};

	public data$ = new BehaviorSubject<any>(this._data);

	constructor() {
	}
}
