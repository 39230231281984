import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export interface FareProduct {
	date: string;
	day_type_id: number;
	fare_product_id: number;
	fare_product_name?: string;
	is_active: number;
	location_id: number;
	name: string;
	original_timetable_id: number;
	price: number;
	resource_id: number;
	service_group_id: number;
	service_id: number;
	time_end: string;
	time_slot_id: number;
	time_start: string;
	timetable_id: number;
	field_group: string;
	field_group_id: number;
}

export interface HotelSlot {
	name: string;
	reservation_count: number;
	resource_id: number;
	status: string;
	price: number;
	resource_time_end: string;
	resource_time_start: string;
	fare_product: FareProduct[];
	max_consecutive_nights: number;
	is_pay_later: boolean;
}

export interface HotelSlotsMap {
	[id: string]: HotelSlot[];
}

@Injectable()
export class ShopHotelSlotsService extends EnkoraFetcher<HotelSlotsMap> {

	protected params: CallParameter[] = [{ name : 'reservation2/getHotelSlotsAvailability' }];

	constructor(call: CallService)
	{
		super(call);
	}
}
