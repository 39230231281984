import { Component, OnInit } from '@angular/core';

@Component({
  selector    : 'cta-icon-cog',
  templateUrl : './icon-cog.component.html',
  styleUrls   : ['./icon-cog.component.scss']
})
export class IconCogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
