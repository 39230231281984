import { CourseFareProduct } from '../../../services';
import { CourseItem } from '../course-item/course-item.component';

export interface CourseParticipantForm {
	for_child: boolean;
	child: number | null;
	first_name: string;
	last_name: string;
}

export enum CourseParticipantsPhase {
	ParticipantAmount,
	ParticipantInfo,
	ParticipantList,
	Confirmation,
	Cancel
}

export enum CourseParticipantsReservationType {
	NormalReservation,
	QueueReservation,
	MixedReservation
}

export interface PreReservation {
	reservations: { [key: string]: number };
	reservation_event_group_id: number;
	filtered_reservations?: { account_id: string, accessibility: number }[];
	reserved_until: string;
	user: {
		account_id: number;
		address: { street: string; postcode: string; city: string; }
		email: string;
		first_name: string;
		gender_id: number;
		last_name: string;
		middle_names: string;
		phone_number: string;
		social_security_number: string;
		user_id: number;
	}
}

export interface Gender {
	gender_id: number;
	name: string;
}

export interface CourseAddress {
	city: string;
	postcode: string;
	street: string;
}

export interface CourseParticipant {
	address: CourseAddress,
	email: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	social_security_number: string;
	account_id: string;
	gender_id: string;
	user_id?: number;
	reservation_status_id?: number;
	personal_fare_products?: CourseFareProduct[];
	age?: number;
	fare_product_id?: number;
}

export interface CourseUser {
	user_id: string;
	first_name: string;
	middle_names: string;
	last_name: string;
	phone_number: string;
	address: CourseAddress,
	email: string;
	social_security_number: string;
	account_id: string;
	gender_id: string;
}

export interface SaveParticipantsData {
	reservation_event_group_id: number;
	user: CourseUser;
	participants: CourseParticipant[];
}

export interface SaveParticipantsResponse {
	user: CourseUser;
	participants: CourseParticipant[];
	fare_products: CourseFareProduct[];
	campaign_fare_products?: { [key: string]: CourseFareProduct[] };
}

export interface CourseError {
	user_errors?: string[];
	errors?: string[];
}

export interface CourseCartItem {
	fare_product_id: number;
	quantity: number;
	price: number;
	title: string;
	account: number;
	reservation_event_group_id: number;
}
