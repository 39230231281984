import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { clone, forEach, upperFirst, values } from 'lodash';

import { CallService, Constants, LoaderModalService } from '../../shared';

@Injectable()
export class SearchAccountsService {
	constructor(private call : CallService,
	            private loader : LoaderModalService
	)
	{
	}

	private _last_search : any = [];

	get last_search() : any
	{
		return this._last_search;
	}

	set last_search(value : any)
	{
		this._last_search = value;
	}

	findAccounts(
		search_string,
		count : number = 10,
		is_resource_reservation_allowed = 1,
		is_instructors = false,
		with_loader = true,
		service_at_area_ids : number[] = []
	)
	{
		let search : string = clone(search_string);
		let searchUpper = upperFirst(search);

		if (with_loader) this.loader.start();
		let params = {
			user                            : search_string,
			is_resource_reservation_allowed : is_resource_reservation_allowed
		};
		if (is_instructors) {
			params['user_type_id'] = Constants.UT_INSTRUCTOR;
		}
		if (service_at_area_ids.length) {
			params['service_at_area_ids'] = service_at_area_ids;
		}
		return this.call.make('acs/findaccount/',
			[
				params,
				count,
				{split_addresses : true, with_tags : true}
			]
		).pipe(map(data => {
			forEach(data, (customer : any) => {
				let customer_name = search;
				if (customer.name) {
					customer_name = customer.name.replace(search, `<strong style="text-decoration: underline;">${search}</strong>`);
				} else customer.name = searchUpper;
				customer_name = customer_name.replace(searchUpper, `<strong>${searchUpper}</strong>`);
				customer.html_name = customer_name;
			});

			this.last_search = data;

			if (with_loader) this.loader.stop();
			return values(data);
		}));
	}
}
