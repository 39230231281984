<ng-container *ngIf="inputForm; else no_modules">
	<div [formGroup]="inputForm">
		<div formArrayName="modules" class="well well-lg">
			<div class="checkbox checkbox-lg"
			     *ngFor="let module of modulesForm.controls; let i=index"
			     [formGroupName]="i">
				<input [id]="'modules ' + i" type="checkbox" formControlName="is_active">
				<label [for]="'modules ' + i">{{module.get('name').value | tt}}</label>
				<div *ngIf="module.get('module_id').value == TA_MODULE_ID" class="ps-3 form-group">
					<input id="access_area_daily_reset"
					       class="form-control"
					       type="checkbox"
					       [attr.disabled]="disabled_access_area_daily_reset ? true : null"
					       formControlName="is_access_area_daily_reset">
					<label for="access_area_daily_reset" tt>Access Area Daily Reset</label>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #no_modules>
	<div class="mb-4" tt>
		There is no modules available.
	</div>
</ng-template>
