<ng-container *ngIf="token">
	<h4 class="mt-6" tt>Token</h4>

	<div class="form-group row">
		<label [for]="'token_type'" class="col-3 col-form-label text-input-label" tt>Type</label>
		<div class="col-9">
			<ng-select [items]="types"
			           [labelForId]="'token_type'"
			           [(ngModel)]="token.type"
			           (ngModelChange)="update()">
			</ng-select>
		</div>
	</div>

	<div class="form-group row">
		<label for="token_code" class="col-3 col-form-label text-input-label" tt>Code</label>
		<div class="col-9">
			<input id="token_code"
			       type="text"
			       name="token_code"
			       class="form-control"
			       [class.is-invalid]="!isValidPin()"
			       [(ngModel)]="token.name"
			       (ngModelChange)="update()">
		</div>
	</div>

	<div class="form-group row">
		<label class="col-md-3 col-form-label text-input-label" tt>Organization</label>
		<div class="col-md-9">
			<ng-select [items]="companies"
			           [multiple]="false"
			           [closeOnSelect]="true"
			           [hideSelected]="true"
			           name="employee_id"
			           bindLabel="name"
			           bindValue="organization_id"
			           [(ngModel)]="token.organization_id"
			           (ngModelChange)="update()"
			           [placeholder]="'Organization' | tt">
			</ng-select>
		</div>
	</div>

	<div class="form-group row" *ngIf="show_organisation_search">
		<div class="offset-3 col-5">
			<button class="btn btn-secondary"
			        [disabled]="!token.organization_id || !tax_number"
			        (click)="search()" tt>
				Search Valttikortti
			</button>

			<div class="form-text text-danger" *ngIf="!token.organization_id || !tax_number">
				<span *ngIf="!token.organization_id; else tax_number" tt>
					Select company to search. Please notice that only companies with bussiness id's are shown
				</span>
				<ng-template #tax_number>
					<span tt>Tax number is not defined for employee</span>
				</ng-template>
			</div>
		</div>

		<div class="col-4 d-flex justify-content-end align-items-start">
			<button class="btn btn-outline-primary me-1"
			        *ngIf="token.type == 'pin'"
			        (click)="generatePin()" tt>
				Generate PIN
			</button>
			<button class="btn btn-outline-primary"
			        [disabled]="!can_add"
			        (click)="addToken()" tt>
				Add token
			</button>
		</div>
	</div>

</ng-container>
