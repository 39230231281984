import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';
import { TaCompany, TaDataHelper } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaCompanyDetailsService extends EnkoraFetcher<TaCompany> {

	protected params: CallParameter[] = [{ name : 'cta2/getOrganization' }];
	private last_location_id = null;

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options: { organization_id?: string; location_id?: string; } = {}): TaCompany
	{
		const organization_id = options.organization_id;
		const location_id = options.location_id || this.last_location_id;

		if (!location_id || !organization_id) return null;

		Logger.log(`Company details update: organization_id = ${organization_id}`);

		this.params = [{
			name    : 'cta2/getOrganization',
			content : [
				{
					organization_id,
					location_id,
					with_agreements : true,
					with_tree       : true
				}
			]
		}];
	}

	postProcess(reply: {
		agreement: any,
		tree: any,
		organization: any,
		representative: any,
		foreign_representative: any,
		representatives_suggest: any
	}): TaCompany
	{
		return TaDataHelper.extractCompany(reply);
	}
}
