import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { abstractControlToFormGroup, Logger } from 'shared';

import { TaFindUsersAndContactsService } from '../../services';

@Component({
	selector    : 'cta-ta-form-ramismart-info',
	templateUrl : './ramismart-info.form.html',
	providers   : [TaFindUsersAndContactsService],
	styleUrls   : ['./ramismart-info.form.scss']
})
export class RamismartInfoForm implements OnInit {
	public contactTypesForm: FormArray;
	@Output() onSiteDataChange = new EventEmitter();

	constructor()
	{
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		if (!value) return;

		this.contactTypesForm = <FormArray>value.get('contact_types');
		this._inputForm.valueChanges.pipe(debounceTime(300)).subscribe(data => {
			Logger.log('Site reactive form update', data);
			this.onSiteDataChange.emit({ status : this._inputForm.status, data });
		});

		this.onSiteDataChange.emit({ status : this._inputForm.status, data : this._inputForm.value });
	}

	ngOnInit(): void
	{
	}

	protected readonly FormGroup = FormGroup;
	protected readonly abstractControlToFormGroup = abstractControlToFormGroup;
}
