import { Injectable } from '@angular/core';

import { CallParameter, EnkoraFetcher } from '../../misc';
import { CallService } from '../call.service';
import { AuthenticationService } from '../authentication';

export interface SystemMenuItem {
	name: string;
	title: string;
	path: string;
	disabled: boolean;
}

export type SystemMenu = SystemMenuItem[]

@Injectable()
export class SystemMenuService extends EnkoraFetcher<SystemMenu[]> {

	protected params: CallParameter[] = [{ name : 'web/getMenu' }];

	constructor(call: CallService,
	            public auth: AuthenticationService)
	{
		super(call);

		// not a lazy loaded approach
		this.auth.get().subscribe(() => this.get(true));
	}
}
