import { Injectable } from '@angular/core';
import { CallService } from 'shared';
import { of } from 'rxjs';

@Injectable()
export class TranslationCreationAndOverrideService {

	constructor(public call: CallService,
	)
	{
	}

	saveTranslationForLessonName(new_lesson_name_translation: string, new_lesson_name: string)
	{
		return of(this.call.make('common/tt', [new_lesson_name, 1]).subscribe(result => {
			if (!new_lesson_name_translation || result == new_lesson_name_translation) return;
			this.call.make('common/savetranslation/', [new_lesson_name, 1,
				new_lesson_name_translation]).subscribe(() => {
			});
		}));
	}

}
