import { Route } from '@angular/router';

import { UserAuthenticationGuard } from './services';


export const ctaRoutes: Route[] = [
	// RamiSmart
	// Includes login.c, *-password.c — these are unprotected,
	// TODO: Later set-password to own module with hash guard
	{
		path         : 'login',
		loadChildren : () => import('./ramismart/guest-module/cta.guest.module').then(m => m.CtaGuestModule)
	},
	// Protected
	{
		path         : 'dashboard',
		loadChildren : () => import('./ramismart/protected-modules/dashboard/cta.dashboard.module').then(m => m.CtaDashboardModule),
		canLoad      : [UserAuthenticationGuard],
		canActivate  : [UserAuthenticationGuard]
	},
	{
		path         : 'ta',
		loadChildren : () => import('./ramismart/protected-modules/time-attendance/cta.time-attendance.module').then(m => m.CtaTimeAttendanceModule),
		canLoad      : [UserAuthenticationGuard],
		canActivate  : [UserAuthenticationGuard]
	},
	{
		path         : 'mc',
		loadChildren : () => import('./ramismart/protected-modules/machine-management/cta.machine-management.module').then(m => m.CtaMachineManagementModule),
		canLoad      : [UserAuthenticationGuard],
		canActivate  : [UserAuthenticationGuard]
	},
	{
		path         : 'site',
		loadChildren : () => import('./ramismart/protected-modules/site/cta.site.module').then(m => m.CtaSiteModule),
		canLoad      : [UserAuthenticationGuard],
		canActivate  : [UserAuthenticationGuard]
	},
	{
		path         : 'device_transfer',
		loadChildren : () =>	import('./ramismart/protected-modules/device-transfer/cta.device.module').then(m => m.CtaDeviceModule),
		canLoad      : [UserAuthenticationGuard],
		canActivate  : [UserAuthenticationGuard]
	},
	{
		path         : 'customer_identifier',
		loadChildren : () =>	import('./ramismart/protected-modules/customer-identifier-management/cta.customer-identifier.module').then(m => m.CtaCustomerIdentifierModule),
		canLoad      : [UserAuthenticationGuard],
		canActivate  : [UserAuthenticationGuard]
	}
];
