import { Injectable } from '@angular/core';

@Injectable()
export class ContractorService {
	get editUserNewClient() : any {
		return this._editUserNewClient;
	}

	set editUserNewClient(value : any) {
		this._editUserNewClient = value;
	}

	private _editUserNewClient: any = false;

	constructor() {
	}

}
