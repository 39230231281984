import { Injectable } from '@angular/core';
import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';
import { TaDataHelper, TaUserIdentifier } from '../../../shared';

export interface RawUserIdentifier {
	label: string;
	length: number;
	location_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserIdentifierService extends EnkoraFetcher<TaUserIdentifier, {location_id?: string}> {

	protected params: CallParameter[] = [{ name : 'cta2/getUserIdentifierLengths'  }];

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(content: { location_id?: string } = {}): void
	{
		const location_id = content.location_id || this.latest_content?.location_id;

		if (!location_id) return;

		this.params = [{
			name    : 'cta2/getUserIdentifierLengths',
			content : [[location_id]]
		}];
	}

	postProcess(reply: RawUserIdentifier[]): TaUserIdentifier
	{
		return reply.length > 0 && Object.keys(reply[0]).length > 0
			? {label: reply[0].label, length: reply[0].length}
			: TaDataHelper.emptyUserIdentifierLength;
	}
}
