import { Subscription } from 'rxjs';

type AutoUnsubscribeType = 'general' | 'oneTime';

interface AutoUnsubscribeNamedSubs {
	[name: string]: Subscription[];
}

type AutoUnsubscribeStorage = {
	[key in AutoUnsubscribeType]?: AutoUnsubscribeNamedSubs;
};

interface AutoUnsubscribeClass {
	autoSubscriptionStorage: AutoUnsubscribeStorage;
}

/*
 * Unsubscribe all subscriptions from storage
 */
function unsubscribeAll(subscriptions: Subscription[])
{
	subscriptions.forEach(s => {
		if (s && (typeof s.unsubscribe === 'function')) {
			s.unsubscribe();
		} else {
			throw new Error(`Cannot unsubscribe from non-Subscription object.`);
		}
	});
}

/*
 * This function used as annotation for automatic storing of subscription objects in classes
 */
export function AutoSubs(type: AutoUnsubscribeType = 'general'): PropertyDecorator
{
	return (target, name) => {

		const cls = target as AutoUnsubscribeClass;

		cls.autoSubscriptionStorage = cls.autoSubscriptionStorage || {};
		const storage = cls.autoSubscriptionStorage[type] = cls.autoSubscriptionStorage[type] || {};

		const pri_name = '_' + name.toString();
		storage[pri_name] = [];
		Object.defineProperty(target, name, {
			get          : () => {
				return storage[pri_name];
			},
			set          : (value: Subscription) => {
				if (type === 'oneTime') {
					unsubscribeAll(storage[pri_name]);
				}
				storage[pri_name].push(value);
			},
			enumerable   : true,
			configurable : true
		});
	};
}

/*
 * This function used as annotation for class to make automatic unsubscription on deletion
 */
export function AutoUnsubscribe(type_order: AutoUnsubscribeType[] = ['general', 'oneTime']): ClassDecorator
{
	return (constructor) => {
		const original = (constructor.prototype as { ngOnDestroy: () => void }).ngOnDestroy;
		if (!original || typeof original !== 'function') {
			throw new Error('Cannot use AutoUnsubscribe without explicitly defined ngOnDestroy');
		}

		(constructor.prototype as { ngOnDestroy: () => void }).ngOnDestroy = function ngOnDestroy() {
			const autoSubscriptionStorage = (this as { autoSubscriptionStorage: AutoUnsubscribeStorage }).autoSubscriptionStorage;
			if (autoSubscriptionStorage) {
				type_order.forEach(type => {
					const storage = autoSubscriptionStorage[type];
					for (const name in storage) {
						if (storage.hasOwnProperty(name) && storage[name]) {
							unsubscribeAll(storage[name]);
							storage[name] = [];
						}
					}
				});
			}

			original.apply(this);
		};
	};

}
