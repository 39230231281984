<ng-container *ngIf="inputForm">
	<div>

		<ng-container *ngFor="let locationTag of inputForm.controls; let i=index"
		              [formGroup]="abstractControlToFormGroup(locationTag)">

			<div class="form-group row" [ngSwitch]="locationTag.get('type').value">

				<ng-container *ngSwitchCase="'multiple'">
					<label [for]="id + i"
					       class="col-md-3 col-form-label text-input-label">{{ locationTag.get('name').value | tt}}</label>

					<div class="col-md-9">
						<select class="form-control" [id]="id + i" formControlName="value">
							<option *ngFor="let o of locationTag.get('possible_values').value" [ngValue]="o">{{o}}</option>
						</select>
					</div>
				</ng-container>

				<ng-container *ngSwitchDefault>
					<label [for]="id + i" class="col-md-3 col-form-label text-input-label">
						{{ locationTag.get('name').value | tt}}
					</label>
					<div class="col-md-9">
						<input class="form-control"
						       type="text"
						       [id]="id + i"
						       formControlName="value">
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>
</ng-container>
