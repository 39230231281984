import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GuidService } from '../../../../../shared';

@Component({
	selector    : 'cta-order-form-info',
	templateUrl : './order-info.form.html',
	styleUrls   : ['./order-info.form.scss']
})
export class OrderInfoForm implements OnInit {

	@Input() public inputForm: FormGroup;

	public id: string;

	constructor(guid: GuidService)
	{
		this.id = `order_info_${guid.Next}`;
	}

	ngOnInit(): void
	{
	}
}
