<ng-container *ngIf="inputForm; else no_product">

	<div [formGroup]="inputForm" class="row">

		<div class="col-lg-7">

			<div class="form-group row">
				<label [for]="id + '_qty'" class="col-md-3 col-form-label text-input-label" tt>Quantity</label>
				<div class="col-md-9">
					<input class="form-control" type="number" min="0" [id]="id + '_qty'" name="quantity"
					       formControlName="quantity">
				</div>
			</div>

			<div class="form-group row">
				<label [for]="id + '_cost_center'" class="col-md-3 col-form-label text-input-label" tt>Cost center</label>
				<div class="col-md-9">
					<input class="form-control" type="text" [id]="id + '_cost_center'" name="cost_center"
					       formControlName="cost_center_id">
				</div>
			</div>

			<div class="form-group row">
				<label [for]="id + '_comment'" class="col-md-3 col-form-label text-input-label" tt>Comment</label>
				<div class="col-md-9">
					<input class="form-control" type="text" [id]="id + '_comment'" name="comment"
					       formControlName="comment">
				</div>
			</div>
		</div>

		<div class="col-lg-5">
			<cta-order-form-extra-services [inputForm]="inputForm">
			</cta-order-form-extra-services>
		</div>

	</div>
</ng-container>

<ng-template #no_product>
	<div class="mb-4" tt>
		No product details.
	</div>
</ng-template>
