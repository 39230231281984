<div class="modal-header">
	<h2 class="no-margin-bottom">
		{{(dataHolder.is_add_new_employee? 'Add a new qualification': 'Edit qualifications') | tt}}
	</h2>
	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="activeModal.dismiss()">
		<cta-icon-close></cta-icon-close>
	</button>
</div>
<div *appLoading="taitoQualifications.fetching"></div>
<div class="modal-body">
	<cta-ta-add-employee-progressbar *ngIf="!standalone"
	                                 [currentStep]="6">
	</cta-ta-add-employee-progressbar>

	<p class="mb-5" tt>
		Time and attendance qualifications modal description
	</p>

	<h4 tt>Employee qualifications</h4>

	<cta-ta-form-qualifications [inputForm]="qualificationsForm"
	                            [all_employers]="all_employers"
	                            [is_multiple]="is_multiple">
	</cta-ta-form-qualifications>

	<div class="mt-3 text-end">
		<button class="btn btn-primary-custom me-2" (click)="toggleFrom()">
			{{(is_multiple ? 'Add single' : 'Add multiple') | tt}}
		</button>
		<button class="btn btn-primary-custom" (click)="searchFromTaito()" tt>
			Search from Taito
		</button>
	</div>

	<div *ngIf="msg_updated_from_taito" class="alert alert-warning mt-1" role="alert">{{msg_updated_from_taito}}</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary me-auto" *ngIf="!standalone" (click)="back()" tt>Previous</button>

	<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" tt>Close</button>

	<button type="button"
	        class="btn btn-primary-custom"
	        *ngIf="save_message || !standalone"
	        [disabled]="!is_valid"
	        (click)="confirm()">
		{{save_message + (standalone ? 'close' : 'see employee\'s profile') | titlecase | tt}}
	</button>
</div>
