import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
	AnimatedIfDirective,
	ApiurlPipe,
	AppLoadingDirective,
	BootstrapValidityDirective,
	CentsToEurosPipe,
	ConfirmModal,
	DisableControlDirective,
	EmptyOrValuePipe,
	FastCentsPipe,
	FocusDirective,
	HourMinutesPipe,
	LoaderModal,
	LoadingComponent,
	ReplacePipe,
	SafeHtmlPipe,
	SafeUrlPipe,
	ToLocaleStringPipe,
	TtDirective,
	TtPipe
} from './shared';

@NgModule({
	declarations    : [
		TtDirective,
		FocusDirective,
		DisableControlDirective,
		BootstrapValidityDirective,
		AppLoadingDirective,
		LoadingComponent,
		LoaderModal,
		AnimatedIfDirective,
		ConfirmModal,

		TtPipe,
		ApiurlPipe,
		CentsToEurosPipe,
		FastCentsPipe,
		EmptyOrValuePipe,
		SafeHtmlPipe,
		SafeUrlPipe,
		ToLocaleStringPipe,
		ReplacePipe,
		HourMinutesPipe
	],
	imports         : [
		CommonModule,
		FormsModule
	],
	exports         : [
		TtDirective,
		FocusDirective,
		DisableControlDirective,
		BootstrapValidityDirective,
		AppLoadingDirective,
		LoadingComponent,
		LoaderModal,
		AnimatedIfDirective,
		ConfirmModal,

		TtPipe,
		ApiurlPipe,
		CentsToEurosPipe,
		FastCentsPipe,
		EmptyOrValuePipe,
		SafeHtmlPipe,
		SafeUrlPipe,
		ToLocaleStringPipe,
		ReplacePipe,
		HourMinutesPipe
	]
})
export class BaseImportModule {
}
