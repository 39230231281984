import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ErrorType {
	Auth = 'Auth',
	Other = 'Other'
}

interface BaseError {
	type: ErrorType;
	message: string;
}

interface UserError {
	errors: string[];
	user_errors: string[];
}

@Injectable({
	providedIn : 'root'
})
export class ErrorPipeService {

	public error$: Subject<BaseError> = new Subject<BaseError>();

	public addError(errorMessage: string | BaseError): void
	{
		if (errorMessage && typeof errorMessage === 'string') {
			if (errorMessage.startsWith('100: Unauthenticated access')) {
				const authError: BaseError = { message : errorMessage, type : ErrorType.Auth };
				this.error$.next(authError);
			} else {
				const otherError: BaseError = { message : errorMessage, type : ErrorType.Other };
				this.error$.next(otherError);
			}
		} else if (typeof errorMessage === 'object' && errorMessage.type && errorMessage.type in ErrorType) {
			this.error$.next(errorMessage);
		}
	}

	public addUserError(errorMessage: string | UserError): void
	{
		if (typeof errorMessage === 'string') {
			this.addError(errorMessage);
		} else {
			const error = errorMessage as UserError;

			const baseError = {
				type    : ErrorType.Other,
				message : error.user_errors[0]
			}

			this.error$.next(baseError);
		}
	}
}
