import { Injectable, NgZone } from '@angular/core';

@Injectable({
	providedIn : 'root'
})
export class LoaderModalService {

	public is_active = false;

	constructor(private zone: NgZone)
	{
	}

	public start(): void
	{
		this.zone.run(() => {
			if (!this.is_active) {
				this.is_active = true;
			}
		});
	}

	public stop(): void
	{
		if (this.is_active) {
			this.zone.run(() => {
				this.is_active = false;
			});
		}
	}
}
