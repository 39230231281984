import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopReservationGroupItem {
	reservation_group_id: string;
	name: string;
	name_translated: string;
	is_course: number;
	is_reserve_remaining: number;
	location_ids: string[];
	service_ids: string[];
}

@Injectable()
export class ShopReservationGroupListService extends EnkoraFetcher<ShopReservationGroupItem[]> {

	public group_map: { [reservation_group_id: string]: ShopReservationGroupItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2ReservationGroups' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterByLocations(service_id = '', location_ids: string[] = []): Observable<ShopReservationGroupItem[]>
	{
		const location_map = Helpers.arrayToMap(location_ids);

		return this.getLazy().pipe(
			first(),
			map(reservation_groups => {
				const filtered_list: ShopReservationGroupItem[] = [];

				each(reservation_groups, group => {
					if (!group.service_ids || !group.location_ids) return;

					if (!find(group.service_ids, id => id == service_id)) return;
					if (!find(group.location_ids, id => location_map(id))) return;

					filtered_list.push(group);
				});

				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopReservationGroupItem[]): ShopReservationGroupItem[]
	{
		this.group_map = {};
		each(reply, item => {
			this.group_map[item.reservation_group_id] = item;
		});
		return reply;
	}
}
