import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';

import { CallService, Helpers, ModelFormGroup } from '../../../../../shared';
import { CtaStyleCode, TaActiveDataHolderService, TaCtaStyleService } from '../../services';
import { TaAddress, TaCountry, TaCountryAddress } from '../../interfaces';

@Component({
	selector    : 'cta-ta-form-personal-info',
	templateUrl : './personal-info.form.html',
	styleUrls   : ['./personal-info.form.scss']
})
export class PersonalInfoForm implements OnInit {

	@Input() is_visitor = false;
	countries: TaCountry[];
	name: string;

	public id_type: string;
	public countryForm: ModelFormGroup<TaCountryAddress>;
	public homeCountryForm: ModelFormGroup<TaCountryAddress>;
	public picture_filename: string;
	public address_is_required = false;
	public CtaStyleCode = CtaStyleCode;
	public home_country_code: string = null;

	public today = Helpers.momentToNgbDate(moment());
	public past = Helpers.momentToNgbDate(moment().subtract(100, 'years'));

	public show_emergency_contact = false;

	constructor(public cta_style: TaCtaStyleService,
					public dataHolder: TaActiveDataHolderService,
	            public call: CallService)
	{


		this.id_type = this.dataHolder.user_identifier.label;
		this.cta_style.getFirst().subscribe(ret => {
			this.countries = Helpers.sortTranslatedCountries(ret.list);
			this.name = ret.name;

			this.show_emergency_contact = ret.code == CtaStyleCode.SE;

			if (this.homeCountryForm) this.checkCountryRules(this.homeCountryForm.getRawValue());
		});
	}

	private _inputForm: FormGroup;

	get inputForm(): FormGroup
	{
		return this._inputForm;
	}

	@Input() set inputForm(value: FormGroup)
	{
		this._inputForm = value;

		this.picture_filename = value.value.picture_filename;

		this.countryForm = (value.get('country') as FormGroup);
		this.homeCountryForm = (value.get('home_country') as FormGroup);

		this.homeCountryForm.valueChanges.pipe(debounceTime(200)).subscribe((value: TaCountryAddress) => {
			this.checkCountryRules(value);

		});

		this.checkCountryRules(value.value.home_country);
	}

	get countryAddress(): ModelFormGroup<TaAddress>
	{
		return this.countryForm.get('address') as ModelFormGroup<TaAddress>;
	}

	get homeCountryAddress(): ModelFormGroup<TaAddress>
	{
		return this.homeCountryForm.get('address') as ModelFormGroup<TaAddress>;
	}

	ngOnInit(): void
	{
	}

	// to generate image link for profile image preview
	changeProfileImgUrl(event: any): void
	{
		const file = (event.target.files || [])[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				this.picture_filename = event.target.result;
			};
			reader.readAsDataURL(file);
		}

		this._inputForm.controls['profile_photo'].setValue(file ? file.name : '');
	}

	private checkCountryRules(address: TaCountryAddress)
	{
		this.home_country_code = address.country_code;
		if (this.cta_style.value) {
			this.address_is_required = !!address && address.country_code != this.cta_style.value.code;
		}
	}
}
