<!-- Company contract -->
<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">

		<!--TODO: add current company tree implementation-->
		<div class="form-group row">
			<label for="contract_start" class="col-md-3 col-form-label text-input-label" tt>Start date</label>
			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       [minDate]="start_min"
					       id="contract_start"
					       placeholder="{{ 'dd.mm.yyyy' | tt }}"
					       name="contract_start"
					       formControlName="start"
					       ngbDatepicker
					       (click)="dpContractStart.open()"
					       #dpContractStart="ngbDatepicker">

					<button class="input-group-addon btn-icon-calendar"
					        (click)="dpContractStart.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</button>

				</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="contract_end" class="col-md-3 col-form-label text-input-label" tt>End date</label>
			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       [minDate]="end_min"
					       [maxDate]="end_max"
					       id="contract_end"
					       placeholder="{{ 'dd.mm.yyyy' | tt }}"
					       name="contract_end"
					       formControlName="end"
					       ngbDatepicker
					       (click)="dpContractEnd.open()"
					       #dpContractEnd="ngbDatepicker">

					<button class="input-group-addon btn-icon-calendar"
					        (click)="dpContractEnd.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</button>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="personal_id_length" class="col-md-3 col-form-label text-input-label" tt>
				Personal identifier number length
			</label>
			<div class="col-md-9">
				<input id="personal_id_length"
				       class="form-control"
				       name="personal_id_length"
				       formControlName="personal_id_length"
				       (ngModelChange)="checkIdLength($event)"
				>
				<div class="alert alert-danger mt-1" *ngIf="show_alert_change_length" tt>Personal identifier number length change notice</div>
			</div>
		</div>

	</div><!-- /Company contract -->
</ng-container>
