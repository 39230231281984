import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { cloneDeep, each } from 'lodash';
import * as moment from 'moment';

import {
	Account,
	AuthenticationService,
	CallService,
	ConfirmModal,
	ConfirmParams,
	Constants,
	Helpers,
	ModalOpenerService,
	ParameterService,
	User
} from 'shared';

import {
	ShopEventTypeListService,
	ShopReservationEventGroupsService,
	ShopReservationListService,
	ShopSelectorsStateService,
	ShopTagGroupsService
} from '../services';
import { AlertCode, AlertType } from '../alerts';
import { AccessRight } from '../../shared/misc/access_right.interface';
import { ShopLoginStepsService } from '../modals';

import { ShopUserModifyModal } from './modify-modal/modify.modal';

@Component({
	selector    : 'shop-user',
	templateUrl : './user.component.html',
	styleUrls   : ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

	public alert: AlertType = null;
	user: User = null;
	account: Account = null;
	events: any[] = null;
	machine_events: any[] = null;
	subscriptions: any[] = null;

	sub: Subscription = null;
	sub3: Subscription = null;

	is_child_account = false;
	ssn_check = false;
	can_create_child = false;
	balance: number = null;

	hide_reservations = false;
	hide_courses = false;
	hide_events = false;
	hide_cta_events = false;
	hide_subscriptions = false;
	hide_access_rights_products = false;

	public access_rights: AccessRight[] = null;

	constructor(public auth: AuthenticationService,
	            private call: CallService,
	            private params: ParameterService,
	            private tags: ShopTagGroupsService,
	            private modalService: ModalOpenerService,
	            private event_type: ShopEventTypeListService,
	            public reservation_list: ShopReservationListService,
	            public reg_service: ShopReservationEventGroupsService,
	            private login_steps: ShopLoginStepsService,
	            public selectors: ShopSelectorsStateService,)
	{
	}

	ngOnInit(): void
	{
		this.sub = this.auth.get().subscribe((value) => {
			if (!value) {
				this.user = null;
				this.doLogin();
				return;
			}

			if (this.sub3) this.sub3.unsubscribe();
			this.sub3 = this.selectors.get().subscribe(state => {
				if (state.user_id && state.user_id != value.user_id) {
					this.call.make<User>('reservation2/getR2Users', [{ user_id : state.user_id }]).subscribe((user) => {
						if (user) this.setupUser(user);
						else this.selectors.goUserProfile();
					}, () => {
						this.selectors.goUserProfile();
					});
				} else this.setupUser(value);
			});
		});

		this.params.getBooleanValue('webshop user hide reservations', Constants.NM_EN_WEBSHOP)
		.subscribe(
			p => this.hide_reservations = !!p
		);

		this.params.getBooleanValue('webshop user hide courses', Constants.NM_EN_WEBSHOP)
		.subscribe(
			p => this.hide_courses = !!p
		);

		this.params.getBooleanValue('webshop user hide events', Constants.NM_EN_WEBSHOP)
		.subscribe(
			p => this.hide_events = !!p
		);

		this.params.getBooleanValue('webshop user hide cta events', Constants.NM_EN_WEBSHOP)
		.subscribe(
			p => this.hide_cta_events = !!p
		);

		this.params.getBooleanValue('webshop user hide subscriptions', Constants.NM_EN_WEBSHOP)
		.subscribe(
			p => this.hide_subscriptions = !!p
		);

		this.params.getBooleanValue('webshop user hide access rights products', Constants.NM_EN_WEBSHOP)
		.subscribe(
			p => this.hide_access_rights_products = !!p
		);
	}

	setupUser(user: User): void
	{
		this.params.getBooleanValue('webshop check ssn at subscription purchase', Constants.NM_EN_WEBSHOP).subscribe(p => this.ssn_check = !!p);
		this.params.getValue('balance payments enabled', Constants.NM_EN_WEBSHOP).subscribe(need_balance => {
			if (need_balance) {
				this.call.make<{ balance: string | number }>('cart/checkcurrentuserbalance', []).subscribe(data => {
					this.balance = +data.balance;
				}, error => console.log(error));
			}
		});
		this.params.getBooleanValue('user can create child accounts', Constants.NM_EN_RESERVATIONS).subscribe(p => this.can_create_child = !!p);

		this.user = user;
		this.account = user.account;

		this.events = this.parseEvents(this.account?.events);
		this.machine_events = this.parseEvents(user.reserved_machines_events);

		this.is_child_account = user.account && !!user.account.parent_account_id;

		this.access_rights = this.account?.access_rights || [];

		this.call.make('reservation2/getmysubscriptions', []).subscribe(subscriptions => {
			this.subscriptions = subscriptions;
		}, error => console.log(error));
	}

	parseEvents(input_events: { timestamp: string, parsed_date: string, parsed_time: string, event_type_name: string, event_type_id: string }[]): any[]
	{
		const events = cloneDeep(input_events || []);

		let temp_date: string = null;
		each(events, event => {
			const curMoment = moment(event.timestamp);

			event.parsed_date = curMoment.format(Helpers.userDateFormat);
			event.parsed_time = curMoment.format(Helpers.timeFormat);

			if (temp_date == event.parsed_date) event.parsed_date = '';
			else temp_date = event.parsed_date;

			event.event_type_name = this.event_type.map[event.event_type_id];
		});

		return events;
	}

	ngOnDestroy(): void
	{
		if (this.sub) this.sub.unsubscribe();
		if (this.sub3) this.sub3.unsubscribe();
	}

	createAccount(): void
	{
		const modalRef = this.modalService.openLgModal(ConfirmModal);
		const componentInstance: ConfirmModal = modalRef.componentInstance;

		const params = ConfirmModal.default;

		params.title = 'Please enter your child\'s name';
		params.input = {
			label : 'Child account name'
		};
		componentInstance.params = params;

		modalRef.result.then(
			(confirm: ConfirmParams) => {
				const content = [this.account.account_id, { name : confirm.input.value }, { return_user_id : true }];
				this.call.make('account/linknewaccount', content).subscribe(
					() => {
						this.auth.reload();
					},
					(error) => {

						this.alert = {
							code    : AlertCode.Error,
							title   : 'Internal error. Please contact support if this error persists.',
							message : error
						};
					});
			},
			() => {
				this.alert = {
					code  : AlertCode.Info,
					title : 'No name submitted'
				};
			});
	}

	public isAccountEmpty(): boolean
	{
		if (this.reservation_list.is_loading || this.reservation_list.value?.length) return false;
		if (this.reg_service.is_loading || this.reg_service.value?.length) return false;
		if (this.access_rights?.length) return false;

		// if none found
		return true;
	}

	public modifyAccount(): void
	{
		const modalRef = this.modalService.openModalCenter(ShopUserModifyModal);
		const componentInstance: ShopUserModifyModal = modalRef.componentInstance;

		componentInstance.user = this.user;

		modalRef.result.then(() => {
			this.auth.reload();
		}, () => {
		});
	}

	public doLogin(): void
	{
		this.login_steps.login().subscribe(() => this.auth.reload());
	}

	isAccountNotificationVisible() {
		return !this.hide_reservations ||
			!this.hide_courses ||
			(!this.events?.length && !this.hide_events) ||
			(!this.machine_events?.length && !this.hide_cta_events) ||
			(!this.subscriptions?.length && !this.hide_subscriptions) ||
			(!this.access_rights?.length && this.hide_access_rights_products)
	}
}
