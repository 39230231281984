import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { each, find } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

export interface ShopServiceGroupItem {
	service_group_id: string;
	sale_group_ids: string[];
	name: string;
	name_customer: string;
	ui_order: string;
	description: string;
}

export interface ShopServiceGroupItemRaw {
	service_group_id: string | number;
	sale_group_ids: string | number;
	name: string;
	name_customer: string;
	ui_order: string;
	description: string;
}

@Injectable()
export class ShopServiceGroupListService extends EnkoraFetcher<ShopServiceGroupItem[]> {

	protected params: CallParameter[] = [{
		name    : 'reservation2/getR2ServiceGroups',
		content : [false, { customer_names : true }]
	}];

	constructor(call: CallService)
	{
		super(call);
	}

	public filterBySaleGroup(sale_group_id = ''): Observable<ShopServiceGroupItem[]>
	{
		return this.getLazy().pipe(
			first(),
			map(service_group_list => {
				const filtered_list: ShopServiceGroupItem[] = [];

				each(service_group_list, service_group => {
					if (!service_group.sale_group_ids) return;

					if (!find(service_group.sale_group_ids, id => id == sale_group_id)) return;

					filtered_list.push(service_group);
				});
				return filtered_list;
			})
		);
	}

	postProcess(reply: ShopServiceGroupItemRaw[]): ShopServiceGroupItem[]
	{
		const result: ShopServiceGroupItem[] = [];
		each(reply, item => {
			result.push({
				...item,
				sale_group_ids   : Helpers.split(item.sale_group_ids),
				service_group_id : Helpers.safeToString(item.service_group_id)
			});
		});

		return result;
	}
}
