<div class="well pull-right">
	<table>
		<tr>
			<td class='gift-card-title'>
				<h4 tt>Gift card</h4>
				<small tt>Add giftcard barcode number, example 12345678912345678</small>
			</td>
			<td><input class="gift-card-number" type="text" [(ngModel)]="card_number"/></td>
			<td>
				<button class="btn btn-success" (click)="addGiftCard()">+</button>
			</td>
		</tr>
		<ng-container *ngIf="balance || message">
			<tr>
				<td colspan="3" class="gift-cart-balance-range">
					<ng-container *ngIf="balance; else gift_card_msg_tmpl">
						<div class="row-fluid">
							<div class="span2 centered-text">
								<b tt>EUR</b><br>
								<span class="label label-default">{{balance_to_load | fastcents}}</span>
							</div>
							<div class="span4">
								<input class="gift-cart-balance-to-load"
								       type="range" min="0" [max]="balance"
								       step="50"
								       [(ngModel)]="balance_to_load">
							</div>
							<div class="span6 centered-text">
								<button class="btn btn-success load-giftcard-amount" (click)="loadGiftCardAmount()" tt>
									Load to balance
								</button>
							</div>
						</div>
					</ng-container>
					<ng-template #gift_card_msg_tmpl>
						<div class="alert alert-warning">{{message}}</div>
					</ng-template>
				</td>
			</tr>
		</ng-container>
	</table>
</div>
<div class="clearfix"></div>