import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, EnkoraFetcher } from '../misc';

import { CallService } from './call.service';

declare let window: { translations: Record<string, any> };

export interface EnkoraTranslations {
	[key: string]: string
}

@Injectable({ providedIn : 'root' })
export class TranslationsService extends EnkoraFetcher<EnkoraTranslations> {

	protected params: CallParameter[] = [{ name : 'common/getTranslations' }];

	constructor(public call: CallService)
	{
		super(call);
		this.preset(window.translations || []);
	}

	public tt(key: string, ...params: (string | number)[]): string
	{
		if (!key) return '';

		// Check for translation
		let translation = this.value?.[key.toLowerCase()]
			? this.value[key.toLowerCase()] // Translation found!
			: key; // Use the key as translation (e.g. default language e.g. English)

		each(params, param => {
			translation = translation.replace('%s', `${param}` ?? '');
		});

		return translation;
	}

	protected postProcess(reply: EnkoraTranslations): EnkoraTranslations
	{
		const translations = {};
		each(reply, (value, key) => {
			translations[key.toLowerCase()] = value;
		});

		window.translations = translations;
		return translations;
	}
}

