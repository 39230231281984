import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

import { CallService, Constants, LoaderModalService, ParameterService } from '../../shared';

@Injectable()
export class UserAccountManagerService {

	private _account : any = {};
	private _parent_account : any = {};
	private _linked_accounts : any = [];

	public account$ = new Subject();
	public parent_account$ = new Subject();
	public linked_accounts$ = new Subject();

	public loadReservations = true;
	public showCourseReservations = false;
	public showCoursePayerReservations = false;

	constructor(private call : CallService,
	            private loader : LoaderModalService,
	            private params : ParameterService)
	{
		params.getValue('do not fetch reservations in user search', Constants.NM_EN_RESERVATIONS).subscribe((res) => {
			this.loadReservations = !(!!res);
		});
		params.getValue('show course reservations in user search', Constants.NM_EN_RESERVATIONS).subscribe((res) => {
			this.showCourseReservations = !!res;
		});
		params.getValue('show course payer reservations in user search', Constants.NM_EN_RESERVATIONS).subscribe((res) => {
			this.showCoursePayerReservations = !!res;
		});
		this.account$.subscribe((data) => {
			if (!data) this.account = {};
			this.getParentAccount();
			this.getLinkedAccounts();
		});
	}

	get parent_account() : any
	{
		return this._parent_account;
	}

	set parent_account(value : any)
	{
		this._parent_account = value;
		this.parent_account$.next(value);
	}

	get linked_accounts() : any
	{
		return this._linked_accounts;
	}

	set linked_accounts(value : any)
	{
		this._linked_accounts = value;
		this.linked_accounts$.next(value);
	}

	get account() : any
	{
		return this._account;
	}

	set account(value : any)
	{
		this._account = value;
		this.account$.next(value);
	}

	public reset()
	{
		this.account = {};
		this.parent_account = {};
		this.linked_accounts = [];
	}

	public openUserAccount(account_id, callback?: (account: any) => void)
	{
		this.loader.start();
		return this.call.make('acs/findaccount',
			['id' + account_id, 2, {split_addresses : true, with_tags : true}]
		).subscribe((data) => {
			this.loader.stop();
			this.account = _.values(data)[0]; // take only first one
			if (!this.account) this.account = {};
			if (callback) { callback(this.account); }
		});
	}

	public closeUserAccount()
	{
		this.reset();
	}

	public removeLinkedAccount(id)
	{
		this.loader.start();
		this.call.make('account/unlinkaccount', [id]).subscribe((data) => {
			this.loader.stop();
			this.getLinkedAccounts();
		});
	}

	public addLinkedAccount(id)
	{
		this.call.make('account/linkaccounts', [this.account.account_id, id]).subscribe((data) => {
			this.loader.stop();
			//this.linked_account_search = false;
			this.getLinkedAccounts();
		});
	}

	public addParentAccount(id)
	{
		if (!this.account.account_id) return;
		this.loader.start();
		this.account.parent_account_id = id;
		this.call.make('account/linkaccounts', [id, this.account.account_id]).subscribe((data) => {
			this.loader.stop();
			//this.linked_account_search = false;
			//this.parent_account_search = false;
			this.getParentAccount();
		});
	}

	public removeParentAccount()
	{
		this.loader.start();
		this.account.parent_account_id = false;
		this.call.make('account/unlinkaccount', [this.account.account_id]).subscribe((data) => {
			this.loader.stop();
			this.parent_account = {};
			this.getParentAccount();
		});
	}

	private getLinkedAccounts()
	{
		if (!this.account.account_id) {
			if (this.linked_accounts.length > 0)
				this.linked_accounts = [];
			return;
		}

		this.loader.start();
		this.call.make('account/getlinkedaccounts', [this.account.account_id]).subscribe((data) => {
			this.loader.stop();
			if (data) this.linked_accounts = data;
			else this.linked_accounts = [];
		});
	}

	private getParentAccount()
	{
		if (!this.account.parent_account_id) {
			if (this.parent_account.account_id)
				this.parent_account = {};
			return;
		}
		this.loader.start();
		this.call.make('acs/getaccount/', [this.account.parent_account_id]).subscribe((data) => {
			if (!data.user) return;

			this.parent_account = data;
		});
	}

	createNewUser(user_data)
	{
		this.loader.start();
		this.call.make('common/createaccount/', [{
			user_data               : user_data,
			send_user_creation_mail : true
		}]).subscribe((account_id) => {
			this.loader.stop();
			this.openUserAccount(account_id);
		}, (err) => {
			this.loader.stop();
			alert(err);
		});
	};
}
