<div class="modal-header">
	<h2 class="no-margin-bottom"> {{ params.title | tt }}</h2>

	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="modal.dismiss()">
		&times;
	</button>
</div>

<div class="modal-body">
	<ng-container *ngIf="params.inner_message; else string_message">
		<div [innerHTML]="params.inner_message | safeHtml"></div>
	</ng-container>
	<ng-template #string_message>
		{{ params.message | tt }}
	</ng-template>

	<ng-container *ngIf="params.input">
		<div class="form-group row">
			<label class="offset-1 col-3 col-form-label" for="confirm_input">{{ params.input.label | tt }}</label>

			<div class="col-8">
				<div class="input-group input-group-sm">
					<input class="form-control"
					       type="text"
					       id="confirm_input"
					       name="confirm_input"
					       [(ngModel)]="params.input.value">
				</div>
			</div>
		</div>
	</ng-container>
</div>

<div class="modal-footer">
	<button type="button"
	        *ngIf="params.cancel_button"
	        class="btn btn-secondary"
	        (click)="modal.dismiss()">
		{{ params.cancel_button | tt }}
	</button>
	<button type="button" class="btn btn-primary-custom" [disabled]="in_process" (click)="close()">
		{{ params.ok_button | tt }}
	</button>
</div>
