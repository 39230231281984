import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

@Injectable({
	providedIn : 'root'
})
export class TaTokenTypesService extends EnkoraFetcher<string[]> {

	protected params: CallParameter[] = [{ name : 'acs/getavailabletokenprefixes' }];

	constructor(call: CallService)
	{
		super(call);
	}
}
