import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { abstractControlToFormGroup } from 'shared';

@Component({
	selector    : 'cta-order-form-summary',
	templateUrl : './order-summary.form.html',
	styleUrls   : ['./order-summary.form.scss']
})
export class OrderSummaryForm implements OnInit {

	@Input() public inputForm: FormGroup;


	constructor()
	{
	}

	ngOnInit(): void
	{
	}

	protected readonly abstractControlToFormGroup = abstractControlToFormGroup;
}
