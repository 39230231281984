<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">

		<div class="form-group row">
			<label [for]="id + '_site_name'" class="col-md-3 col-form-label text-input-label" tt>Site name</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + '_site_name'"
				       name="site_name"
				       [readonly]="true"
				       formControlName="site_name">
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_site_address'" class="col-md-3 col-form-label text-input-label" tt>Site address</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + '_site_address'"
				       name="site_address"
				       [readonly]="true"
				       formControlName="site_address">
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_delivery_address'" class="col-md-3 col-form-label text-input-label" tt>
				Delivery address
			</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + '_delivery_address'"
				       name="delivery_address"
				       formControlName="delivery_address">
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_requested_date'" class="col-md-3 col-form-label text-input-label" tt>
				Requested delivery date
			</label>
			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       [id]="id + 'requested_date'"
					       placeholder="{{ 'dd.mm.yyyy' | tt }}"
					       name="requested_date"
					       formControlName="requested_date"
					       [minDate]="min_date"
					       ngbDatepicker
					       #dpRequestedDate="ngbDatepicker">

					<button class="input-group-addon btn-icon-calendar"
					        (click)="dpRequestedDate.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</button>
				</div>
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_pickup_time'" class="col-md-3 col-form-label text-input-label" tt>
				Requested delivery time
			</label>
			<div class="col-md-9">
				<select class="form-control"
				        type="text"
				        [id]="id + '_pickup_time'"
				        name="pickup_time"
				        formControlName="pickup_time">

					<option value="" tt>Select delivery time slot...</option>
					<option value="7-10">7-10</option>
					<option value="9-12">9-12</option>
					<option value="11-14">11-14</option>
					<option value="14-17">14-17</option>
				</select>
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_expected_return_date'" class="col-md-3 col-form-label text-input-label" tt>
				Expected return date
			</label>
			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       [id]="id + 'expected_return_date'"
					       placeholder="{{ 'dd.mm.yyyy' | tt }}"
					       name="expected_return_date"
					       formControlName="expected_return_date"
					       ngbDatepicker
					       [minDate]="min_date"
					       #dpExpectedReturnDate="ngbDatepicker">

					<button class="input-group-addon btn-icon-calendar"
					        (click)="dpExpectedReturnDate.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</button>
				</div>
				<span tt>expected return date dummy text</span>
			</div>
		</div>


		<div class="form-group row">
			<label [for]="id + '_recipient_name'" class="col-md-3 col-form-label text-input-label" tt>
				Delivery recipient
			</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + '_recipient_name'"
				       name="recipient_name"
				       formControlName="recipient_name">
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_phone_number'" class="col-md-3 col-form-label text-input-label" tt>
				Recipient's phone number
			</label>
			<div class="col-md-9">
				<input class="form-control"
				       type="text"
				       [id]="id + '_phone_number'"
				       name="phone_number"
				       formControlName="phone_number">
			</div>
		</div>

		<div class="form-group row">
			<label [for]="id + '_info'" class="col-md-3 col-form-label text-input-label" tt>
				Delivery notes
			</label>
			<div class="col-md-9">
				<textarea class="form-control"
				          type="text"
				          [id]="id + '_info'"
				          name="info"
				          formControlName="info">
				</textarea>
			</div>
		</div>
	</div>
</ng-container>
