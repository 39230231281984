import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { clone, each, find, isNil, times } from 'lodash';
import * as moment from 'moment';

import { CallService, ExtraValidators, Helpers } from 'shared';

import { ShopField, ShopFieldInput, ShopFields } from './shop.interface';
import { ShopParametersService } from './shop-parameters.service';

declare let Enkora : { tt : (a : string) => string };

export interface FieldSchema {
	[key : string] : {
		title : string,
		type? : string,
		subtype? : string,
		possible_values? : { label : string, value : string }[]
		validators? : any[],
		async_validators? : any,
		description? : string;
	};
}

@Injectable()
export class FieldFormsGeneratorService {

	schema : FieldSchema = {
		email                  : {
			title      : 'Email',
			subtype    : 'email',
			validators : [Validators.required, Validators.pattern(ExtraValidators.emailPattern)]
		},
		emailNew               : {
			title            : 'Email',
			type             : 'email',
			subtype          : 'emailNew',
			validators       : [Validators.required, Validators.pattern(ExtraValidators.emailPattern)],
			async_validators : [ExtraValidators.uniqueEmail(this.call)]
		},
		emailConfirm           : {
			title      : 'Email Confirmation',
			subtype    : 'email',
			validators : [
				Validators.required,
				Validators.pattern(ExtraValidators.emailPattern),
				ExtraValidators.match('email', 'Emails must match'),
				ExtraValidators.match('emailNew', 'Emails must match')
			]
		},
		name                   : {
			title      : 'First- And Lastname',
			validators : [Validators.required, Validators.pattern(/^.+ .+$/)]
		},
		first_name             : {
			title      : 'First Name',
			validators : [Validators.required]
		},
		last_name              : {
			title      : 'Last Name',
			validators : [Validators.required]
		},
		password               : {
			title      : 'Password',
			type       : 'password',
			subtype    : 'password',
			validators : [Validators.required, Validators.minLength(8)]
		},
		passwordConfirm        : {
			title      : 'Password Confirmation',
			type       : 'password',
			subtype    : 'password',
			validators : [Validators.required,
				ExtraValidators.match('password', 'Passwords must match')]
		},
		phone_number           : {
			title      : 'Phone Number',
			subtype    : 'tel',
			validators : [Validators.required]
		},
		company                : {
			title      : 'Company',
			subtype    : 'text',
			validators : [Validators.required]
		},
		campaign_code          : {
			title   : 'Campaign Code',
			subtype : 'text'
		},
		social_security_number : {
			title      : 'Social security number',
			subtype    : 'text',
			validators : [Validators.required]
		},
		address_street         : {
			title      : 'Street',
			type       : 'text',
			validators : [Validators.required]
		},
		address_postcode       : {
			title      : 'Postcode',
			type       : 'text',
			subtype    : 'tel',
			validators : [Validators.required, Validators.pattern(/^[0-9]{5}$/)]
		},
		address_city           : {
			title      : 'City',
			type       : 'text',
			validators : [Validators.required]
		},
		date_of_birth          : {
			title      : 'Date Of Birth',
			type       : 'date',
			validators : [Validators.required, ExtraValidators.prohibitFutureDate()]
		},
		notes_customer         : {
			title : 'Notes',
			type  : 'textarea'
		},
		is_email_allowed       : {
			title   : 'Marketing allowed',
			subtype : 'checkbox'
		},
		usageterms             : {
			title       : 'Accept usage terms',
			subtype     : 'checkbox',
			description : 'some text',
			validators  : [Validators.requiredTrue]
		},
		sample                 : {
			title      : null,
			type       : null,
			validators : []
		},
		gender_id              : {
			title           : 'Gender',
			type            : 'select',
			possible_values : [{label : 'Male', value : '1'}, {label : 'Female', value : '2'}],
			validators      : []
		},
		gender                 : {
			title           : 'Gender',
			type            : 'mat-radio',
			possible_values : [
				{label : 'Male', value : '1'},
				{label : 'Female', value : '2'},
				{label : 'Don\'t share', value : '0'}],
			validators      : []
		},
		notes                  : {
			title : 'Notes',
			type  : 'text'
		},
		arrival_date           : {
			title : 'Arrival Date',
			type  : 'date'
		},
		departure_date         : {
			title : 'Departure date',
			type  : 'date'
		},
		image_upload           : {
			title      : 'Image Upload',
			type       : 'file',
			validators : [ExtraValidators.checkImageType()]
		}
	};

	constructor(private fb : FormBuilder,
	            private call : CallService,
	            private shopParametersService : ShopParametersService)
	{
	}

	private static addRequiredValidator(sample: any)
	{
		const validators = clone(sample.validators) ?? [];
		if (!validators.includes(Validators.required)) {
			validators.push(Validators.required);
		}
		if (!sample.validators) {
			sample.validators = validators;
		}
	}

	public generateForm(input_fields : ShopFieldInput[]) : ShopFields
	{
		this.prepareTerms();
		const fields = this.prepareFields(input_fields);
		const form_fields : Record<string, any> = {};
		const has_password_confirm = find(fields, field => field.id === 'passwordConfirm');
		const has_departure_date = find(fields, field => field.id === 'departure_date');
		const need_usage_terms = find(fields, field => field.id === 'usageterms');
		const has_image_upload_and_required = find(fields, field => field.id === 'image_upload')?.is_required;

		each(fields, field => {
			if (field.type != 'checkboxes') {
				form_fields[field.id] = [field.value];

				const sample = this.schema[field.id] || this.schema.sample;
				if (field.is_required) {
					FieldFormsGeneratorService.addRequiredValidator(sample);
				}
				(form_fields[field.id] as any[]).push(sample.validators || []);
				(form_fields[field.id] as any[]).push(sample.async_validators || []);

			} else {
				const length = field.possible_values && field.possible_values.length || 0;
				form_fields[field.id] = this.fb.array(field.value || times(length, () => false));
			}
		});

		const validators = [];
		if (has_password_confirm) {
			validators.push(ExtraValidators.matchChildren(
				'password',
				'passwordConfirm',
				'passwords must match')
			);
		}

		if (has_departure_date) {
			validators.push(ExtraValidators.checkArrivalAndDepartureDates(
				'arrival_date',
				'departure_date',
				'The departure date can not be earlier than the arrival date.')
			);
		}

		if (need_usage_terms) {
			validators.push(ExtraValidators.checkChild('usageterms', true, 'Terms of use needs to be accepted to continue'));
		}

		if (has_image_upload_and_required) {
			validators.push(ExtraValidators.checkImageType());
		}

		const root = this.fb.group(form_fields, {validators});

		if (has_password_confirm) {
			root.get('password').valueChanges.subscribe(() => root.controls['passwordConfirm'].updateValueAndValidity());
		}

		const result : ShopField[] = [];
		each(fields, field => {
			const form_field = root.get(field.id) as FormControl;
			if (!isNil(field.is_editable) && !field.is_editable) {
				form_field.disable();
			}

			result.push({
				...field,
				rootForm : root,
				form     : form_field
			});
		});

		return {fields : result, rootForm : root};
	}

	private prepareFields(fields : ShopFieldInput[]) : ShopFieldInput[]
	{
		const new_fields : ShopFieldInput[] = [];

		each(fields, field => {
			const sample = this.schema[field.id] || this.schema.sample;

			const new_field : ShopFieldInput = {
				id               : field.id,
				name             : sample.title || field.name,
				type             : sample.type || field.type || 'text',
				subtype          : sample.subtype,
				description      : field.description || sample.description,
				ui_order         : field.ui_order,
				is_required      : field.is_required,
				is_editable      : field.is_editable,
				possible_values  : field.possible_values || sample.possible_values,
				value            : field.value,
				field_group      : field.field_group,
				validation_regex : field.validation_regex
			};

			if (field.id === 'date_of_birth') {
				if (new_field.value) {
					new_field.value = moment(new_field.value);
				}
				new_field.max_date = Helpers.momentToNgbDate(moment());
			} else if (field.id === 'arrival_date' || field.id === 'departure_date') {
				new_field.min_date = Helpers.momentToNgbDate(moment());
			}
			new_fields.push(new_field);
		});

		return new_fields;
	}

	private prepareTerms()
	{
		const terms : string[] = [];
		each(this.shopParametersService?.data?.terms_and_condition_links, term => {
			const title = Enkora.tt(term.title);
			terms.push(`<a target="_blank" href="${term.link}">${title}</a>`);
		});
		this.schema.usageterms.description = terms.join(', ');
	}
}
