<ng-container *ngIf="inputForm">
	<section [formGroup]="inputForm">
		<!-- Employee contract -->
		<h4 class="mt-5 mb-4" tt>Add employee contract</h4>

		<div class="form-group row">

			<label for="employee_contract_start" class="col-md-3 col-form-label text-input-label" tt>
				Work starts
			</label>

			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       id="employee_contract_start"
					       [placeholder]="'dd.mm.yyyy' | tt"
					       [minDate]="min_start"
					       [maxDate]="max_date"
					       name="employee_contract_start"
					       formControlName="start"
					       ngbDatepicker>
					<input
						class="date-field-hidden"
						[minDate]="min_start_calendar"
						[maxDate]="max_date_calendar"
						ngbDatepicker
						formControlName="start_date_hidden"
						#work_start_hidden="ngbDatepicker">
					<div
						class="input-group-addon btn-icon-calendar"
						(click)="work_start_hidden.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</div>
				</div>
				<div class="alert d-inline-block mt-1 p-1"
				     [ngClass]="{'alert-danger': !removed_start_date_error,'alert-secondary': removed_start_date_error}"
				     *ngIf="!inputForm.get('start')?.valid || removed_start_date_error">
					<span tt>Work starts out of bounds. Are you sure?</span>
					<input type="checkbox"
					       id="set_valid_start_date"
					       class="d-inline-block form-check-input ms-1 me-1 position-relative"
					       formControlName="set_valid_start_date"
					       (click)="removeStartDateError()">
				</div>
			</div>
		</div>
		<div class="form-group row">

			<label for="employee_contract_end" class="col-md-3 col-form-label text-input-label" tt>Work ends</label>

			<div class="col-md-9">
				<div class="input-group">
					<input class="form-control"
					       id="employee_contract_end"
					       [placeholder]="'dd.mm.yyyy' | tt"
					       [minDate]="min_end"
					       [maxDate]="max_date"
					       name="employee_contract_end"
					       formControlName="end"
					       ngbDatepicker>
					<input
						class="date-field-hidden"
						[minDate]="min_end_calendar"
						[maxDate]="max_date_calendar"
						ngbDatepicker
						formControlName="end_date_hidden"
						#work_end_hidden="ngbDatepicker">
					<div class="input-group-addon btn-icon-calendar"
					     (click)="work_end_hidden.toggle()">
						<cta-icon-calendar></cta-icon-calendar>
					</div>
				</div>
				<div class="alert d-inline-block mt-1 p-1"
				     [ngClass]="{'alert-danger': !removed_end_date_error,'alert-secondary': removed_end_date_error}"
				     *ngIf="!inputForm.get('end')?.valid || removed_end_date_error">
					<span tt>Work ends out of bounds. Are you sure?</span>
					<input type="checkbox"
					       id="set_valid_end_date"
					       class="d-inline-block form-check-input ms-1 me-1 position-relative"
					       formControlName="set_valid_end_date"
					       (click)="removeEndDateError()">
				</div>
			</div>
		</div>
		<div class="form-group row" [formGroup]="workTypeForm">

			<label for="work_type" class="col-md-3 col-form-label text-input-label" tt>Work type</label>

			<div class="col-md-9">
				<ng-select id="work_type"
				           [items]="work_types"
				           bindLabel="name"
				           bindValue="code"
				           [placeholder]="'Select work type' | tt"
				           [typeToSearchText]="'Type to search' | tt"
				           [notFoundText]="'No items found' | tt"
				           [loadingText]="'Loading' | tt"
				           formControlName="code">
				</ng-select>
				<div class="alert alert-danger" *ngIf="userUpdateError" tt>Please update required personal information in case of non-visitor. </div>
			</div>
		</div>


		<div class="form-group row">

			<label for="employee_group" class="col-md-3 col-form-label text-input-label" tt>Employee group</label>

			<div class="col-md-9">
				<ng-select id="employee_group"
				           [items]="employee_groups"
				           bindLabel="name"
				           bindValue="id"
				           [placeholder]="'Select employee group' | tt"
				           [typeToSearchText]="'Type to search' | tt"
				           [notFoundText]="'No items found' | tt"
				           [loadingText]="'Loading' | tt"
				           formControlName="employee_group_id">
				</ng-select>
			</div>
		</div>


		<ng-container *ngIf="commissioner_is_required">
			<h5 class="pt-5" tt>Temporary commissioner</h5>
			<cta-ta-form-company-info [inputForm]="commissionerForm"
			                          [isCommissioner]="true">

			</cta-ta-form-company-info>
		</ng-container>
	</section>
</ng-container>
