import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';
import { BehaviorSubject } from 'rxjs';

export interface ShopTargetGroupItem {
	name: string;
	name_translated?: string;
	tag_id: number;
	service_ids: string;
}

@Injectable()
export class ShopTargetGroupListService extends EnkoraFetcher<ShopTargetGroupItem[]> {
	public included_tag_ids_subject = new BehaviorSubject<number[]>([]);

	protected params: CallParameter[] = [{ name : 'asiointi/getCourseTargetGroups' }];

	constructor(call: CallService)
	{
		super(call);
	}

	public updateIncludedTagIds(ids: number[])
	{
		this.included_tag_ids_subject.next(ids);
	}
}
