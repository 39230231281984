import { Injectable } from '@angular/core';
import { each } from 'lodash';
import * as moment from 'moment';

import { CallParameter, CallService, EnkoraFetcher, Helpers } from 'shared';

import { TaQualification } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaTaitoQualificationsService extends EnkoraFetcher<TaQualification[]> {

	protected params: CallParameter[] = [{ name : 'cta2/getEmployeeCompetencesFromTaitorekisteri' }];

	constructor(call: CallService)
	{
		super(call);
	}

	postProcess(reply: any[]): TaQualification[]
	{
		const result: TaQualification[] = [];
		each(reply, row => {
			each(row, q => {
				const qualification: TaQualification = {
					...q,
					start_date : moment(q.start_date, Helpers.serverDateFormat),
					end_date   : moment(q.end_date, Helpers.serverDateFormat)
				};
				qualification.is_expired = qualification.end_date.isBefore(Helpers.now);

				result.push(qualification);
			});
		});

		return result;
	}
}
