import { Injectable } from '@angular/core';
import { combineLatest, concat, Observable, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { each } from 'lodash';

import { Constants, ParameterService } from '../../../../shared';

export interface FieldPattern {
	pattern: string;
	placeholder: string;
}

export enum CtaStyleCode {
	NONE = '',
	FI = 'FI',
	SE = 'SE',
	NO = 'NO',
	GB = 'GB',
	PL = 'PL',
	LV = 'LV',
	NL = 'NL'
}

export interface CtaStyle {
	code: CtaStyleCode;
	name: string;
	is_external_login: boolean;
	list: { id: string, name: string }[];
	map: { [id: string]: string };

	business_id: FieldPattern;
	postcode: FieldPattern;
	phone_number: FieldPattern;
	email: FieldPattern;
}

@Injectable({
	providedIn : 'root'
})
export class TaCtaStyleService {

	public value$: Subject<CtaStyle> = new Subject<CtaStyle>();

	constructor(private params: ParameterService)
	{
	}

	static get emptyStyle(): CtaStyle
	{
		return {
			code              : null,
			name              : null,
			is_external_login : false,
			list              : [],
			map               : {},
			business_id       : {
				pattern     : '^([0-9]{7}\-[0-9])$|^([0-9]{6}[\-\+A]([0][0][2-9]|[0][1-9][0-9]|[1-8][0-9][0-9])[0-9ABCDEFHJKLMNPRSTUVWXY])$',
				placeholder : 'xxxxxxx-y'
			},
			postcode          : {
				pattern     : '^[0-9]{5}',
				placeholder : 'nnnnn'
			},
			phone_number      : {
				pattern     : '(^\\+[0-9]+$)',
				placeholder : '+xyyyyyyyyyy'
			},
			email             : {
				pattern     : '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$)',
				placeholder : 'xxx@xx.xx'
			}
		};
	}

	private _value: CtaStyle = null;

	get value(): CtaStyle
	{
		return this._value;
	}

	set value(value: CtaStyle)
	{
		this._value = value;
		this.value$.next(value);
	}

	static getStyle(code: string): CtaStyle
	{
		const value: CtaStyle = TaCtaStyleService.emptyStyle;
		switch (code) {
			case CtaStyleCode.SE:
				value.business_id = {
					pattern     : '^[0-9]{6}\-?[0-9]{4}$',
					placeholder : 'xxxxxx-yyyy'
				};
				value.postcode = {
					pattern     : '^[0-9]{3}\\s[0-9]{2}',
					placeholder : 'nnn nn'
				};
				break;
			case CtaStyleCode.PL:
				value.postcode = {
					pattern     : '^[0-9]{2}\-[0-9]{3}$',
					placeholder : 'nn-nnn'
				};
				break;
			case CtaStyleCode.LV:
				value.postcode = {
					pattern     : '^[Ll][Vv][- ]{0,1}\\d{4}$',
					placeholder : 'LV-nnnn'
				};
				break;
			case CtaStyleCode.NL:
				value.postcode = {
					pattern     : '^[0-9]{4}\\s[a-zA-Z]{2}$',
					placeholder : 'xxxx yy'
				};
				break;
			case CtaStyleCode.FI:
				value.business_id = {
					pattern     : '^([0-9]{7}\-[0-9])$|^([0-9]{6}[\-\+A]([0][0][2-9]|[0][1-9][0-9]|[1-8][0-9][0-9])[0-9ABCDEFHJKLMNPRSTUVWXY])$',
					placeholder : 'xxxxxxx-y'
				};
				value.postcode = {
					pattern     : '^[0-9]{5}',
					placeholder : 'nnnnn'
				};
				break;
			case CtaStyleCode.NO:
				value.postcode = {
					pattern     : '^[0-9 ]+$',
					placeholder : 'nnnnn'
				};
				value.business_id = {
					pattern     : '^[0-9]{6}\-?[0-9]{4}$',
					placeholder : 'xxxxxx-yyyy'
				};
				break;
			case CtaStyleCode.GB:
				value.postcode = {
					pattern     : '(^[A-Z]{1,2}[0-9R][0-9A-Z]? ?[0-9][ABDEFGHJLNPQRSTUWXYZ]{2}$|'
						+'^BFPO ?[0-9]{1,4}$|^([AC-FHKNPRTV-Y]\d{2}|D6W)? ?[0-9AC-FHKNPRTV-Y]{4}$)',
					placeholder : 'nnnn nnn'
				};
				break;
			default:
				value.business_id = {
					pattern     : '^[a-zA-Z0-9_.+-\\s]+',
					placeholder : ''
				};
				value.postcode = {
					pattern     : '^[0-9 ]+$',
					placeholder : 'nnnnn'
				};
				break;
		}
		return value;
	}

	static getVatNumberRule(code : string) : string
	{
		let pattern = '';
		switch (code) {
			case 'BE':
				pattern = '^BE [0-9]{10}$';
				break;
			case 'BG':
				pattern = '^BG [0-9]{9,10}$';
				break;
			case 'ES':
				pattern = '^(ES [A-Z][0-9]{8})$|^(ES [A-Z][0-9]{7}[A-Z])$|^(ES [0-9]{8}[A-Z])$';
				break;
			case 'NL':
				pattern = '^NL [0-9]{9}B[0-9]{2}$';
				break;
			case 'IE':
				pattern = '^(IE [0-9]S[0-9]{5}L)$|^(IE [0-9]{7}L{2})$';
				break;
			case 'GB':
				pattern = '^(GB [0-9]{9})$|^(GB [0-9]{12})$|^(GB (GD|HA)[0-9]{3})$';
				break;
			case 'IT':
				pattern = '^IT [0-9]{11}$';
				break;
			case 'AT':
				pattern = '^AT U[0-9]{8}$';
				break;
			case 'EL':
				pattern = '^EL [0-9]{9}$';
				break;
			case 'HR':
				pattern = '^HR [0-9]{11}$';
				break;
			case 'CY':
				pattern = '^CY [0-9]{8}L$';
				break;
			case 'LV':
				pattern = '^LV [0-9]{11}$';
				break;
			case 'LT':
				pattern = '(^LT [0-9]{9}$)|(^LT [0-9]{12}$)';
				break;
			case 'LU':
				pattern = '^LU [0-9]{8}$';
				break;
			case 'MT':
				pattern = '^MT [0-9]{8}$';
				break;
			case 'PT':
				pattern = '^PT [0-9]{9}$';
				break;
			case 'PL':
				pattern = '^PL [0-9]{10}$';
				break;
			case 'FR':
				pattern = '^FR XX[0-9]{9}$';
				break;
			case 'RO':
				pattern = '^RO [0-9]{2,10}$';
				break;
			case 'SE':
				pattern = '^SE [0-9]{10}01$';
				break;
			case 'DE':
				pattern = '^DE [0-9]{9}$';
				break;
			case 'SK':
				pattern = '^SK [0-9]{10}$';
				break;
			case 'SI':
				pattern = '^SI [0-9]{8}$';
				break;
			case 'FI':
				pattern = '^FI [0-9]{8}$';
				break;
			case 'DK':
				pattern = '^DK [0-9]{8}$';
				break;
			case 'CZ':
				pattern = '^CZ [0-9]{8,10}$';
				break;
			case 'HU':
				pattern = '^HU [0-9]{8}$';
				break;
			case 'EE':
				pattern = '^EE [0-9]{9}$';
				break;
			default:
				pattern = '';
				break;
		}
		return pattern;
	}

	public get(): Observable<CtaStyle>
	{
		if (this._value === null) {

			combineLatest([
				this.params.getTyped<{ name: string, id: string }[]>('iso_country_codes', Constants.NM_EN_CTA),
				this.params.getValue('cta style', Constants.NM_EN_CTA),
				this.params.getValue('ramismart dashboard redirect url', Constants.NM_EN_CTA)
			])
			.subscribe(reply => {
				const [countries, style, redirect_url_dashboard] = reply;
				let code = CtaStyleCode.FI;

				if (style && style.indexOf('_') > -1) {
					code = <CtaStyleCode>style.split('_')[1].toUpperCase();
				}
				const value = TaCtaStyleService.getStyle(code);
				value.code = code;

				value.list = countries;
				each(value.list, country => {
					value.map[country.id] = country.name;
				});
				value.name = value.map[value.code];

				if (value.code == CtaStyleCode.SE) {
					value.phone_number.placeholder = '';
					value.email.placeholder = '';
				}

				value.is_external_login = !!(code == CtaStyleCode.FI && redirect_url_dashboard);

				this.value = value;

			}, () => {
				this.value = TaCtaStyleService.emptyStyle;
			});

		}

		if (this.value) return concat(of(this.value), this.value$);
		else return this.value$;
	}

	public getFirst(): Observable<CtaStyle>
	{
		return this.get().pipe(first());
	}
}
