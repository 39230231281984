<div class="table-responsive-md">
	<table class="table table-sm tokens-table borderless bg-white border-primary-1 border-radius-base p-4"
	       *ngIf="tokens && tokens.length">
		<thead>
		<tr>
			<th scope="col"><h5 tt>Status</h5></th>
			<th scope="col"><h5 tt>Token</h5></th>
			<th scope="col"><h5 tt>Company</h5></th>
			<th scope="col"><h5 tt>Type</h5></th>
			<th scope="col" *ngIf="can_edit"></th>
			<th scope="col"></th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let token of tokens; let i = index">
			<td>
				<span class="badge bg-success" *ngIf="token.is_new; else old_token" tt>
					New
				</span>

				<ng-template #old_token>
					<span class="badge bg-info" tt>Existing</span>
				</ng-template>
			</td>
			<td>
				<span>{{ token.existing }}</span>
			</td>
			<td>
				<span>{{ token.organization_name }}</span>
			</td>
			<td>
				<span>{{ (token_prefixes[token.type + '_'] || token.type) | tt }}</span>
			</td>
			<td *ngIf="can_edit">
				<button class="btn btn-sm btn-outline-danger" (click)="deleteToken(token, i)">
					{{ (token.is_new ? 'Cancel' : 'Delete') | tt }}
				</button>
			</td>
			<td *ngIf="token.is_verified">
				<span class="bg-verified" tt>
					Valttikortti
				</span>
			</td>
		</tr>
		</tbody>
	</table>
</div>
