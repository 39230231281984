import { Injectable } from '@angular/core';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export type ResourceDataType = 'link' | 'text' | 'long_text' | 'datetime' | 'boolean';

export interface ResourceField {
	data_type: ResourceDataType;
	description: string;
	field_name: string;
	is_required: boolean;
	ui_order: number;
}

@Injectable()
export class ShopResourceFieldsService extends EnkoraFetcher<ResourceField[]> {

	protected params: CallParameter[] = [{ name : 'reservation2/getExtraInfoFieldsForResource' }];

	constructor(call: CallService)
	{
		super(call);
	}

}
