import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { debounceTime, finalize, switchMap } from 'rxjs/operators';
import { each } from 'lodash';

import {
	AuthenticationService,
	AutoSubs,
	AutoUnsubscribe,
	CallService,
	Constants,
	EnkoraLanguage,
	Helpers,
	LocationItem,
	LocationService,
	LocationsList,
	Logger,
	ModalOpenerService,
	ParameterService,
	SystemLanguageService,
	SystemMenu,
	SystemMenuService
} from 'shared';

import {
	CtaStyle,
	ServiceModuleService,
	SessionMonitorService,
	TaActiveDataHolderService,
	TaCtaStyleService,
	TaDataHelper,
	TaFormGroupGeneratorService
} from '../../services';
import { TaContactSupport } from '../../interfaces';
import { ContactSupportModal } from '../../forms';
import { UserIdentifierService } from '../../../protected-modules/time-attendance/services/user-identifier.service';

declare let window: Window;

export interface InstructionLinks {
	target: string;
	name: string;
}

@AutoUnsubscribe()
@Component({
	selector    : 'cta-header-ramismart',
	templateUrl : './header-ramismart.component.html',
	styleUrls   : ['./header-ramismart.component.scss']
})
export class HeaderRamismartComponent implements OnInit, OnDestroy {
	@AutoSubs() subs;

	public loginSuccess: boolean = null;
	public loginLoading: boolean = null;
	public loginError = false;
	public searchLocation = '';
	public mobileMenuOpen = false;

	public systemLocation: LocationsList = null;
	public active_locations: LocationItem[] = [];

	public systemMenu: SystemMenu[] = [];
	public systemLanguage: EnkoraLanguage[] = [];
	public instructions_links: InstructionLinks[] = null;
	public cta_logo_link: string = null;
	public username = '';
	public password = '';
	contactSupportForm: FormGroup = null;
	user: TaContactSupport = TaDataHelper.emptyContactSupportInfo;
	public ctaStyle: CtaStyle = null;
	public redirect_user_types: number[] = [];
	private redirect_required = false;

	constructor(private route: ActivatedRoute,
	            private router: Router,
	            private changeDetectorRef: ChangeDetectorRef,
	            private call: CallService,
	            public auth: AuthenticationService,
	            private serviceModule: ServiceModuleService,
	            private language: SystemLanguageService,
	            private menu: SystemMenuService,
	            private location: LocationService,
					private userIdentifierService: UserIdentifierService,
					private dataHolder: TaActiveDataHolderService,
	            private param: ParameterService,
	            private modalService: ModalOpenerService,
	            private formHelper: TaFormGroupGeneratorService,
	            private ctaStyleService: TaCtaStyleService,
	            private sessionMonitor: SessionMonitorService)
	{
	}

	ngOnInit(): void
	{
		// get user details
		this.auth.get().subscribe(
			user => {
				if (user) {
					this.user = {
						name         : user.name,
						email        : user.email,
						phone_number : user.phone_number,
						text         : ''
					};
					this.sessionMonitor.subscribeToAuthError();
				}
			},
			error => {
				Logger.log('Error, header, common/login', error);
			}
		);

		// Scroll to top on route change
		window.scrollTo(0, 0);

		this.subs = combineLatest([
			this.location.get(),
			this.menu.get(),
			this.language.get(),
			this.param.getValuePairs([
				['TA new UI instructions 1', Constants.NM_EN_CTA],
				['TA new UI instructions 2', Constants.NM_EN_CTA],
				['default cta-logo link', Constants.NM_EN_CTA],
				['ramismart auth redirect users types', Constants.NM_EN_CTA]
			])
		]).pipe(debounceTime(100)).subscribe(reply => {
			const [
				location,
				menu,
				languages,
				[
					instructions_links_1,
					instructions_links_2,
					cta_logo_link,
					redirect_user_types
				]
			] = reply;

			if (!location.current) {
				this.setLocation(location.all[0]);
			}
			this.systemLocation = location;
			this.active_locations = location.all;
			this.systemMenu = menu;
			this.systemLanguage = languages;
			this.instructions_links = this.parseInstructionLinks([instructions_links_1, instructions_links_2]);
			this.cta_logo_link = cta_logo_link || '/dashboard';
			this.redirect_user_types = Helpers.split(redirect_user_types).map(type => +type);
			this.cta_logo_link = this.redirect_user_types.includes(this.auth.value?.user_type_id)
				? '/ta'
				: cta_logo_link || '/dashboard';

			if (this.redirect_required) {
				this.redirect_required = false;
				this.router.navigateByUrl('/ta');
			}
			const current_location = location.current ?? location.all[0];
			this.userIdentifierService.get(false, {'location_id': current_location?.location_id}
			).subscribe(user_identifier => {
				this.dataHolder.user_identifier = user_identifier;
			});
		});
		this.ctaStyleService.getFirst().subscribe(style => this.ctaStyle = style);
		this.doRedirectForTaModule();
	}

	ngOnDestroy(): void
	{
	}

	public setLanguage($event: Event, language_id: string): void
	{
		$event.preventDefault();
		this.language.set(language_id);
	}

	public setLocation(location: LocationItem): void
	{
		const regExpPages = new RegExp('\\/ta\\/(employee|company|permissions|reports)');
		if (regExpPages.test(this.router.url)) {
			this.redirect_required = true;
		}
		this.location.set(location);
	}

	updateLocations(): void
	{
		const searchText = this.searchLocation.toLowerCase();

		this.active_locations = [];
		each(this.systemLocation.all, location => {
			if (location.name.toLowerCase().indexOf(searchText) >= 0) {
				this.active_locations.push(location);
			}
		});
	}

	login(): void
	{
		this.loginLoading = true;
		this.auth.getRedirectLogin(this.username).pipe(
			switchMap((result) => {
				if (!result.redirect) {
					this.auth.login(this.username, this.password).subscribe(() => {
						this.sessionMonitor.subscribeToAuthError();
					}, error => {
						this.loginSuccess = false;
					});
					return of(null);
				}

				if (result.href) {
					window.location.href = result.href;
					return of(null);
				}

				throw new Error('Do not have link for redirect');
			}),
			finalize(() => this.loginLoading = false)
		).subscribe(
			() => {
				this.loginSuccess = true;
			}, error => {
				this.loginSuccess = false;
				Logger.log('Error login: ', error);
			}
		);
	}

	logout(event: Event): void
	{
		event.preventDefault();
		const logout_url = this.isRedirectUserType() ? 'login/logout' : 'login';
		this.auth.logout(true).subscribe((result: boolean) => {
			if (result) {
				this.router.navigateByUrl(logout_url);
				this.sessionMonitor.unsubscribeAuthError();
			}
		});
	}

	openContactSupport(): boolean
	{
		const validators = {
			name         : { validators : Validators.required },
			email        : { validators : [Validators.required, Validators.email] },
			phone_number : { validators : [Validators.required, Validators.pattern(/^\d+$/)] },
			text         : { validators : Validators.required }
		};

		this.contactSupportForm = this.formHelper.initContactSupportInfo(this.user, validators);

		const modalRef = this.modalService.openStaticLgModal(ContactSupportModal);
		modalRef.componentInstance.inputForm = this.contactSupportForm;
		modalRef.componentInstance.user = this.user;

		modalRef.result.then(
			() => {
			},
			() => {
			}
		);

		return false;
	}

	private parseInstructionLinks(instructions_links): InstructionLinks[]
	{
		const links: InstructionLinks[] = [];
		each(Helpers.split(instructions_links), instructions_link => {
			if (instructions_link) {
				const link = Helpers.split(instructions_link, '|');
				links.push({ name : link[0], target : link[1] });
			}
		});

		return links;
	}

	private isRedirectUserType(): boolean
	{
		const is_redirect_user_type = this.redirect_user_types.includes(this.auth.value.user_type_id);
		return this.auth.isSimplyAccessManager() || is_redirect_user_type;
	}

	private doRedirectForTaModule(): void
	{
		this.param.getBooleanValue('enable legacy ui', Constants.NM_EN_CTA).subscribe(is_enable_legacy_ui => {
			if (this.auth.value?.user_type_id == Constants.UT_ACCESS_MANAGER
				&& is_enable_legacy_ui
				&& this.systemLocation.current?.location_id) {
				window.location.href = `../app/cta/status/yard_status?location_id=${this.systemLocation.current.location_id}`;
			}
		});
	}
}
