import { Injectable } from '@angular/core';
import { each, keys, times, values } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher, Logger } from 'shared';

export enum TsTimeUnit {
	day = 'day',
	month = 'month',
	week = 'week'
}

export interface RawTimeShift {
	organization_id: string;
	organization_name: string;
	business_id: string;
	employee_group_id: string;
	employee_group_name: string;

	account_id: string;
	name: string;
	tax_number: string;

	timeslot: string;
	visits: number;
	time: number;
	is_approved: boolean;
}

export interface TsStat {
	visits: number;
	time: number;
	is_approved?: boolean;
}

export interface TsEmployee {
	account_id: string;
	name: string;
	tax_number: string;
	employee_group_id: string;
	employee_group_name: string;

	stats: TsStat[];
	total: TsStat;
}

export interface AttendanceReport {
	companies: TsCompany[];
	timeslots: string[];

	stats: TsStat[];
	total: TsStat;
}

export interface TsCompany {
	organization_id: string;
	organization_name: string;
	business_id: string;

	time_unit: TsTimeUnit;
	stats: TsStat[];
	total: TsStat;
	employees: TsEmployee[];
}

export interface TsReportParams {
	location_id: string;
	group_by: TsTimeUnit;
	report_start?: string;
	report_end?: string;
	is_company_level?: boolean;
	organization_ids?: string[];
	account_ids?: string[];
	employee_group_id?: string[];
	is_events_based_report?: boolean;
}

@Injectable({
	providedIn : 'root'
})
export class TaReportsAttendanceService extends EnkoraFetcher<AttendanceReport> {

	protected params: CallParameter[] = [{ name : 'cta2/getCtaFlextime' }];
	private is_company_level = false;

	constructor(call: CallService)
	{
		super(call);
	}

	preProcess(options?: TsReportParams): AttendanceReport
	{
		if (!options || !options.location_id || !options.group_by) return null;
		this.is_company_level = options.is_company_level;

		if (!options.report_start && !options.report_end) options.group_by = TsTimeUnit.month;

		Logger.log('Getting flextime for: location_id= ' + options.location_id);

		this.params = [{
			name    : options.is_events_based_report ? 'cta/getCtaTimeTrackingByEventsDuration' : 'cta2/getCtaFlextime',
			content : [options]
		}];
	}

	protected postProcess(reply: RawTimeShift[], params?: TsReportParams): AttendanceReport
	{
		const timeslots_map: { [key: string]: number } = {};
		each(reply, row => timeslots_map[this.getLabel(row.timeslot, params.group_by)] = 1);
		const timeslots = keys(timeslots_map).sort();

		each(timeslots, (timeslot, index) => timeslots_map[timeslot] = index);
		const length = timeslots.length;
		const stats = times(length, () => ({ time : 0, visits : 0 }));
		const total = { time : 0, visits : 0 };

		const organization_map: { [organization_id: string]: TsCompany } = {};
		const organization_employee_map: { [organization_id: string]: { [employee_id: string]: TsEmployee } } = {};

		each(reply, row => {
			const organization_id = row.organization_id ? row.organization_id : 0;
			const account_id = row.account_id ? row.account_id : 0;

			if (!organization_map[organization_id]) {
				organization_map[organization_id] = {
					organization_id   : `${organization_id}`,
					organization_name : row.organization_name || 'Visitors',
					business_id       : row.business_id || '',

					employees : [],

					stats     : times(length, () => ({ time : 0, visits : 0, is_approved : true})),
					total     : { time : 0, visits : 0 },
					time_unit : params.group_by || TsTimeUnit.day
				};

				organization_employee_map[organization_id] = {};
			}

			if (!organization_employee_map[organization_id][account_id]) {
				organization_employee_map[organization_id][account_id] = {
					account_id          : `${account_id}`,
					name                : row.name || '',
					tax_number          : row.tax_number || '',
					employee_group_id   : row.employee_group_id || '',
					employee_group_name : row.employee_group_name || '',

					stats : times(length, () => ({time : 0, visits : 0, is_approved : true})),
					total : {time : 0, visits : 0}
				};
			}

			const organization = organization_map[organization_id];
			const employee = organization_employee_map[organization_id][account_id];

			const timeslot_index = timeslots_map[this.getLabel(row.timeslot, params.group_by)];

			this.updateStats(total, row.time, row.visits);
			this.updateStats(stats[timeslot_index], row.time, row.visits);

			this.updateStats(employee.total, row.time, row.visits);
			this.updateStats(organization.total, row.time, row.visits);
			this.updateStats(employee.stats[timeslot_index], row.time, row.visits, row.is_approved);
			this.updateStats(organization.stats[timeslot_index], row.time, row.visits);
		});

		const companies = values(organization_map);
		each(companies, company => {
			company.employees = values(organization_employee_map[company.organization_id]);
		});

		return {
			companies,
			timeslots,
			stats,
			total
		};
	}

	private updateStats = (stat: TsStat, time: number, visits: number, is_approved?:boolean ) => {
		stat.time += time;
		stat.visits += visits;
		stat.is_approved &&= is_approved;
	};

	private getLabel = (timeslot: string | number, group_by: TsTimeUnit): string => {
		let label = `${timeslot}`;
		switch (group_by) {
			case TsTimeUnit.week:
				label = label.substr(0, 4) + '-W' + label.substr(4);
				break;
			case TsTimeUnit.month:
				label = label.substr(0, 4) + '-' + label.substr(4);
				break;
		}

		return label;
	};
}
