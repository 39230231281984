import { Injectable } from '@angular/core';

import { AuthenticationService, ConfirmModal, ModalOpenerService } from 'shared';

import { ShopCartListService } from './cart-list.service';
import { first } from 'rxjs/operators';

declare let Enkora: { tt: (a: string) => string };

@Injectable()
export class PaymentModalsService {

	constructor(private modalService: ModalOpenerService,
	            private cartService: ShopCartListService,
	            private auth: AuthenticationService)
	{
	}

	handlePaymentResult(payment_result: string): void
	{
		this.auth.get(true).pipe(first()).subscribe(() => {
			const params = ConfirmModal.default;
			switch (payment_result) {
				case 'success':
					params.title = `${Enkora.tt('Payment Done!')}`;
					params.inner_message =
						`<p class="dopayment">${Enkora.tt('Thank you for your purchase! A receipt has been sent to your email.')}</p>
						 <p class="dopayment">
							${Enkora.tt('If you reserved a course, the reservation is accepted and confirmation was sent to your email.')}
						 </p>`;
					this.cartService.getReceiptInfo().subscribe(receipt_info => {
						params.inner_message += `<p class="dopayment">${Enkora.tt('Receipt ID')}: ${receipt_info.receipt_id}</p>`;
						if (!this.auth.value) {
							params.inner_message += `<br><p class="dopayment">${Enkora.tt('Notice: you are not logged in.')}</p>`
						}
					});
					break;
				case 'cancel':
					params.title = 'Payment Failed!';
					params.inner_message = `<p class="dopayment">
						${Enkora.tt('If you have any troubles, please contact the customer support.')}</p>`;
					
					if (!this.auth.value) {
						params.inner_message += `<br><p class="dopayment">${Enkora.tt('Notice: you are not logged in.')}</p>`
					}
					break;
				default:
					return;
			}

			const modalRef = this.modalService.openLgModalCenter(ConfirmModal);
			const componentInstance: ConfirmModal = modalRef.componentInstance;
			params.ok_button = 'Close';
			params.cancel_button = null;

			componentInstance.params = params;

			modalRef.result.then(
				() => {
				},
				() => {
				});
		});
	}
}
