import { Component, OnInit } from '@angular/core';

@Component({
  selector    : 'cta-icon-logout',
  templateUrl : './icon-logout.component.html',
  styleUrls   : ['./icon-logout.component.scss']
})
export class IconLogoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
