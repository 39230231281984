import { Injectable } from '@angular/core';
import { each } from 'lodash';

import { CallParameter, CallService, EnkoraFetcher } from 'shared';

export interface ShopCourseTagItem {
	tag_id: string;
	name: string;
	tag_group_id: string;
	tag_group_name: string;
}

@Injectable()
export class ShopCourseTagListService extends EnkoraFetcher<ShopCourseTagItem[]> {

	public tag_map: { [tag_id: string]: ShopCourseTagItem } = {};
	protected params: CallParameter[] = [{ name : 'reservation2/getR2CourseTags' }];

	constructor(call: CallService)
	{
		super(call);
	}

	postProcess(reply: ShopCourseTagItem[]): ShopCourseTagItem[]
	{
		this.tag_map = {};
		each(reply, item => {
			this.tag_map[item.tag_id] = item;
		});
		return reply;
	}
}
