import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { each } from 'lodash';
import { map } from 'rxjs/operators';

import { Constants, Helpers, ParameterService } from 'shared';

declare let Enkora: { tt: (a: string) => string };

export interface WebshopParameters {
	filters_in_dropdown_elements_limit: number;
	invoicing_enabled: boolean;
	get_order_id_instead_of_payment_enabled: boolean;
	show_description_for_all_resources: boolean;
	balance_payments_enabled: boolean;
	printed_giftcard_payments_enabled: boolean;
	allow_cart_before_login: boolean;
	force_narrow_container: boolean;
	can_select_account_for_product: boolean;
	customer_login_allowed: boolean;
	simple_registration_allowed: boolean;
	simple_registration_form_fields: string;
	cancelling_instructions: string;
	allow_course_cancellation_for_customers: boolean;
	disable_reservations_countdown_timer: boolean;
	children_max_age_group_value: number;
	course_reservation_show_payer_and_participant_fields: boolean;
	show_public_calendar_in_services: boolean;
	hide_participant_count_in_reservation_application: boolean;
	hide_admission_fee_in_reservation_application: boolean;
	hide_telecast_in_reservation_application: boolean;
	hide_business_activity_in_reservation_application: boolean;
	hide_single_application: boolean;
	hide_recurring_application: boolean;
	reservation_application_hide_invoicing_address_fields: boolean;
	reservation_application_recurring_reservation_type_id: string;
	allow_only_one_course_reservation_in_cart: boolean;
	payer_name_warning_label: string;
	separate_services_into_sections: string;
	hide_ssn_in_reservation_application: boolean;

	terms_and_condition_links: { title: string, link: string }[];
	hide_payment_types: string[];
	hidden_services: string[];
	ng_shop_service_ids: string[];
	pay_later_service_ids: string[];
}

@Injectable({ providedIn : 'root' })
export class ShopParametersService {
	data: WebshopParameters | null = null;

	constructor(private parameterService: ParameterService)
	{
		this.parameterService.getLazy().pipe(() => this.get()).subscribe();
	}

	public get(reload = false): Observable<WebshopParameters>
	{
		if (!reload && this.data) return of(this.data);

		return combineLatest([
			this.parameterService.getValues(
				[
					'terms and conditions link',
					'use pay later for resources in service ids'
				]
			),
			this.parameterService.getValues(
				[
					'get order id instead of payment enabled',
					'show description for all resources',
					'user can select account for product',
					'children max age group value',
				],
				Constants.NM_EN_RESERVATIONS
			),
			this.parameterService.getValues(
				[
					'show filters in dropdown elements limit',
					'invoicing enabled',
					'printed giftcard payments enabled',
					'hide payment type ids in webshop',
					'balance payments enabled',
					'hidden services in webshop',
					'ng shop service ids',
					'allow cart before login',
					'force narrow container',
					'simple registration allowed',
					'simple registration form fields',
					'no public login',
					'cancelling_instructions',
					'allow course cancellation for customers',
					'disable reservations countdown timer',
					'course reservation show payer and participant fields',
					'show public calendar in service menu',
					'hide participant count in reservation application',
					'hide admission fee in reservation application',
					'hide telecast in reservation application',
					'hide business activity in reservation application',
					'hide single application',
					'hide recurring application',
					'reservation application hide invoicing address fields',
					'reservation application recurring reservation type id',
					'allow only one course reservation in cart',
					'webshop payer name warning label',
					'webshop separate services into sections',
					'hide ssn in reservation application',
				],
				Constants.NM_EN_WEBSHOP
			)
		]).pipe(map(values => {
			const [
				[
					terms_and_condition_links_string,
					pay_later_service_ids
				],
				[
					get_order_id_instead_of_payment_enabled,
					show_description_for_all_resources,
					can_select_account_for_product,
					children_max_age_group_value,
				],
				[
					filters_in_dropdown_elements_limit,
					invoicing_enabled,
					printed_giftcard_payments_enabled,
					hide_payment_types,
					balance_payments_enabled,
					hidden_services,
					ng_shop_service_ids,
					allow_cart_before_login,
					force_narrow_container,
					simple_registration_allowed,
					simple_registration_form_fields,
					no_public_login,
					cancelling_instructions,
					allow_course_cancellation_for_customers,
					disable_reservations_countdown_timer,
					course_reservation_show_payer_and_participant_fields,
					show_public_calendar_in_services,
					hide_participant_count_in_reservation_application,
					hide_admission_fee_in_reservation_application,
					hide_telecast_in_reservation_application,
					hide_business_activity_in_reservation_application,
					hide_single_application,
					hide_recurring_application,
					reservation_application_hide_invoicing_address_fields,
					reservation_application_recurring_reservation_type_id,
					allow_only_one_course_reservation_in_cart,
					payer_name_warning_label,
					separate_services_into_sections,
					hide_ssn_in_reservation_application,
				]
			] = values;

			const data: WebshopParameters = {
				filters_in_dropdown_elements_limit : filters_in_dropdown_elements_limit ? +filters_in_dropdown_elements_limit : 0,

				get_order_id_instead_of_payment_enabled               : !!get_order_id_instead_of_payment_enabled,
				balance_payments_enabled                              : !!balance_payments_enabled,
				invoicing_enabled                                     : !!invoicing_enabled,
				printed_giftcard_payments_enabled                     : !!printed_giftcard_payments_enabled,
				show_description_for_all_resources                    : !!show_description_for_all_resources,
				allow_cart_before_login                               : !!allow_cart_before_login,
				force_narrow_container                                : !!force_narrow_container,
				simple_registration_allowed                           : !!simple_registration_allowed,
				simple_registration_form_fields                       : simple_registration_form_fields,
				can_select_account_for_product                        : !!can_select_account_for_product,
				children_max_age_group_value                          : children_max_age_group_value,
				customer_login_allowed                                : !no_public_login || simple_registration_allowed,
				cancelling_instructions                               : cancelling_instructions,
				allow_course_cancellation_for_customers               : !!allow_course_cancellation_for_customers,
				disable_reservations_countdown_timer                  : !!disable_reservations_countdown_timer,
				course_reservation_show_payer_and_participant_fields  : !!course_reservation_show_payer_and_participant_fields,
				show_public_calendar_in_services                      : !!show_public_calendar_in_services,
				hide_participant_count_in_reservation_application     : !!hide_participant_count_in_reservation_application,
				hide_admission_fee_in_reservation_application         : !!hide_admission_fee_in_reservation_application,
				hide_telecast_in_reservation_application              : !!hide_telecast_in_reservation_application,
				hide_business_activity_in_reservation_application     : !!hide_business_activity_in_reservation_application,
				hide_single_application                               : !!hide_single_application,
				hide_recurring_application                            : !!hide_recurring_application,
				reservation_application_hide_invoicing_address_fields : !!reservation_application_hide_invoicing_address_fields,
				reservation_application_recurring_reservation_type_id : reservation_application_recurring_reservation_type_id,
				allow_only_one_course_reservation_in_cart             : !!allow_only_one_course_reservation_in_cart,
				payer_name_warning_label                              : payer_name_warning_label,
				separate_services_into_sections                       : separate_services_into_sections,
				hide_ssn_in_reservation_application					      : hide_ssn_in_reservation_application,

				terms_and_condition_links : [],
				hide_payment_types        : Helpers.split(hide_payment_types),
				hidden_services           : Helpers.split(hidden_services),
				ng_shop_service_ids       : Helpers.split(ng_shop_service_ids),
				pay_later_service_ids     : Helpers.split(pay_later_service_ids)
			};

			const terms_and_condition_links = Helpers.split(Enkora.tt(terms_and_condition_links_string), ',');
			each(terms_and_condition_links, links => {
				const parts = Helpers.split(links, '|');

				data.terms_and_condition_links.push({
					title : parts?.[1] ?? 'View usage terms',
					link  : parts[0]
				});
			});

			this.data = data;

			return data;
		}));
	}
}
