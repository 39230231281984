import { Injectable } from '@angular/core';
import { each, filter } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CallParameter, CallService, EnkoraFetcher, LocationService, Logger } from 'shared';

import { AccessArea } from '../../../shared';

@Injectable({
	providedIn : 'root'
})
export class TaAccessAreaListService extends EnkoraFetcher<AccessArea[]> {

	protected params: CallParameter[] = [{ name : 'acs/getServicesAtAreas' }];
	private last_location_id = null;
	private machine_access_area_ids = [];

	constructor(call: CallService,
	            private location: LocationService)
	{
		super(call);

		this.location.getLocationId().subscribe(
			location_id => {
				this.get(true, { location_id });
			},
			() => {
				Logger.log('Error in getting of the location information', location);
			}
		);
	}

	preProcess(options: { location_id?: string } = {}): null
	{
		const location_id = options.location_id || this.last_location_id;
		if (!location_id) return null;

		Logger.log(`Site status update: location_id= ${location_id}`);

		this.params = [{
			name    : 'acs/getServicesAtAreas',
			content : [{ location_id }]
		}];
	}

	postProcess(reply: any[]): AccessArea[]
	{
		const result: AccessArea[] = [];
		each(reply, row => {
			const is_machine = !!row.is_machine;
			let name = row.access_area;
			if (is_machine) {
				name = `${row.name}(${name})`;
				this.machine_access_area_ids.push(row.access_area_id);
			}

			result.push({
				service_at_area_id : row.service_at_area_id,
				service_id         : row.service_id,
				is_machine,
				name,
				access_area_id     : row.access_area_id
			});
		});

		return result;
	}

	getMachines(): Observable<AccessArea[]>
	{
		return this.getLazy().pipe(
			map(access_areas => filter(access_areas, aa => aa.is_machine))
		);
	}

	getAccessAreas(): Observable<AccessArea[]>
	{
		return this.getLazy().pipe(
			map(access_areas => filter(access_areas, aa => !aa.is_machine
				&& !this.machine_access_area_ids.includes(aa.access_area_id)))
		);
	}
}
