import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { Route, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { MatDialogModule } from '@angular/material/dialog';

import {
	AuthenticationService,
	CallService,
	ConfigService,
	CustomQueryEncoderHelper,
	EnkoraMessageService,
	ErrorPipeService,
	GlobalClickNotifierService,
	GuidService,
	LocationService,
	ParameterService,
	StringNgbFormatter,
	SystemLanguageService,
	SystemMenuService,
	TickService
} from 'shared';

import { BaseImportModule } from './base-import.module';
import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { Error404Component } from './common-views/errors/error-404.component';
import { CacheCleanedComponent } from './common-views/cache-cleaned/cache-cleaned.component';
import { ctaRoutes } from './cta/cta.routes';
import { UserAuthenticationGuard } from './cta/services';
import { ServiceModuleService } from './cta/ramismart/shared';
import { PendingInterceptorServiceInterceptor } from './reservation/services';
import { InitService } from './shop/services';

const routes: Route[] = [
	...ctaRoutes,
	{
		path         : 'reservation',
		loadChildren : () => import('./reservation/reservation.module').then(m => m.ReservationModule)
	},
	{
		path         : 'monitor',
		loadChildren : () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
	},
	{
		path         : 'shop',
		loadChildren : () => import('./shop/shop.module').then(m => m.ShopModule)
	},
	{
		path         : 'station',
		loadChildren : () => import('./station/station.module').then(m => m.StationModule)
	},
	{
		path         : 'mobile-validator-app',
		loadChildren : () => import('./mobile-validator-app/validator.module').then(m => m.ValidatorModule)
	},
	{
		path         : 'lockers',
		loadChildren : () => import('./lockers/lockers.module').then(m => m.LockersModule)
	},
	{
		path         : 'access-monitor',
		loadChildren : () => import('./access-monitor/access-monitor.module').then(m => m.AccessMonitorModule)
	},
	{
		path         : 'lockers-management',
		loadChildren : () => import('./lockers-management/lockers-management.module').then(m => m.LockersManagementModule)
	},
	{
		path         : 'maps',
		loadChildren : () => import('./maps/maps.module').then(m => m.MapsModule)
	},
	{
		path         : 'home',
		loadChildren : () => import('./home/home.module').then(m => m.HomeModule)
	},
	{
		path         : 'user',
		loadChildren : () => import('./instructor/instructor.module').then(m => m.InstructorModule)
	},
	{ path : 'cache-cleaned', component : CacheCleanedComponent },
	{ path : '**', component : Error404Component }
];

export function initApp(initService: InitService) {
	return () => initService.initApp();
}

@NgModule({
	declarations : [
		AppComponent,
		Error404Component,
		CacheCleanedComponent,
		TestComponent,
	],
	imports : [
		RouterModule.forRoot(routes),
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		BaseImportModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		ToastModule,
		NgbModule,
		ButtonsModule.forRoot(),
		BsDropdownModule.forRoot(),
		MultiSelectModule,
		MatDialogModule,
	],
	providers    : [
		ConfigService,
		CallService,
		ParameterService,
		GuidService,
		UserAuthenticationGuard,
		ErrorPipeService,
		AuthenticationService,
		TickService,
		EnkoraMessageService,
		SystemLanguageService,
		SystemMenuService,
		LocationService,
		ServiceModuleService,
		MessageService,
		{ provide : NgbDateParserFormatter, useClass : StringNgbFormatter },
		PendingInterceptorServiceInterceptor,
		GlobalClickNotifierService,
		CustomQueryEncoderHelper,
		InitService,
		{
			provide: APP_INITIALIZER,
			useFactory: initApp,
			deps: [InitService],
			multi: true
		}
	],
	bootstrap    : [AppComponent]
})
export class AppModule {
}
