import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FormPaymentLineDataService {
	get data() : any {
		return this._data;
	}

	set data(value : any) {
		this._data = value;
		this.data$.next(value);
	}

	private _data : {
		id                                                         ? : any,
		is_closed                                                  ? : any,
		payments                                                   ? : any,
		selected_agreements                                        ? : any,
		show_invoice_field                                         ? : any,
		paid_at_cashier_option                                     ? : any,
		invoice_id                                                 ? : any,
		do_not_check_if_invoicing_is_allowed_for_reservation_maker ? : any
	} = {};

	public data$ = new BehaviorSubject<any>(this._data);

	constructor() {
	}
}
