import { Injectable } from '@angular/core';
import { AuthenticationService, SystemLanguageService, TranslationsService } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

declare const window : { EnkoraInjectData: { languages: any } };

export enum URL_PARAMS {
	LANG = 'lang',
}

@Injectable({
	providedIn: 'root'
})
export class InitService {
	constructor(
		private translations: TranslationsService,
		private languageService: SystemLanguageService,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private router: Router,
		private auth: AuthenticationService,
	) { }

	initApp(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (this.auth.isAuthenticated()) {
				resolve(true);
			} else {
				this.initLanguage().then(resolve).catch(reject);
			}
		});
	}

	private initLanguage(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			if (!window.EnkoraInjectData?.languages) {
				resolve(false);
				return;
			}

			const url = this.location.path();
			const urlTree = this.router.parseUrl(url);
			const params = urlTree.queryParams;

			const languages = Object.entries(window.EnkoraInjectData.languages).map(lang => {
				return { id: lang[0], name: lang[1] };
			});

			const selected_lang = languages.find(lang => lang.name === params[URL_PARAMS.LANG]);
			if (!selected_lang) {
				resolve(false);
				return;
			}

			this.languageService.changeLanguage(selected_lang.id).subscribe({
				next: () => {
					this.translations.get(true).subscribe({
						next: (tl) => {
							this.languageService.selected = {
								is_active: true,
								language_id: null,
								name: selected_lang.name as string,
								short_name: selected_lang.name as string
							};

							window['translations'] = tl;
							window['language'] = this.languageService.selected_name.toUpperCase();
							resolve(true);
						},
						error: () => {
							resolve(false);
						}
					});
				},
				error: () => {
					resolve(false);
				}
			});
		});
	}
}
