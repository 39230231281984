<!-- Company info -->
<ng-container *ngIf="inputForm">
	<div [formGroup]="inputForm">
		<ng-template #rt let-value="result" let-t="term">
			<div style="font-size:18px">{{value.name}} <span *ngIf="value.business_id">({{ value.business_id }})</span>
			</div>
			<div style="font-size:18px" *ngIf="value.address">{{ value.address.street + ' ' + value.address.city }}</div>
		</ng-template>

		<div class="form-group row">
			<label for="company_name" class="col-md-3 col-form-label text-input-label">
				{{ (isCommissioner ? 'Name' : 'Company name') | tt }}
			</label>
			<div class="col-md-9">

				<div class="input-group">
					<input id="company_name"
					       type="text"
					       class="form-control"
					       formControlName="name"
					       [ngbTypeahead]="searchName"
					       [readonly]="readonly || !can_edit_name"
					       [resultTemplate]="rt"
					       (selectItem)="onSelect($event)">

					<div class="input-group-text" *ngIf="!can_edit_name && !readonly && !modification_forbidden">
						<button class="btn btn-outline-secondary"
						        type="button"
						        (click)="can_edit_name = true"
						        >
							<i class="fa fa-edit"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group row" [hidden]="!show_business_id">
			<label for="business_id" class="col-md-3 col-form-label text-input-label">{{home_country_name + 'Business ID' | tt}}</label>
			<div class="col-md-9">
				<div class="input-group">
					<input id="business_id"
					       type="text"
					       class="form-control"
					       formControlName="business_id"
					       [ngbTypeahead]="searchBusinessId"
					       [readonly]="readonly || organization_id"
					       [resultTemplate]="rt"
					       (selectItem)="onSelect($event)">
					<div class="input-group-text" *ngIf="organization_id && !readonly && !modification_forbidden">
						<button class="btn btn-outline-danger"
						        type="button"
						        (click)="clear()"
						        >
							<i class="fa fa-times"></i>
						</button>
					</div>
				</div>
			</div>
		</div>

		<cta-ta-form-country [inputForm]="abstractControlToFormGroup(inputForm.get('country'))"
		                     [readonly]="readonly || (organization_id && modification_forbidden)"
		                     [disableCountry]="organization_id">
		</cta-ta-form-country>
		<div [hidden]="!show_foreign_business_id">
			<ng-container>
				<div class="form-group row">
					<label for="foreign_business_id" class="col-md-3 col-form-label text-input-label">
						{{ (isCommissioner ? 'Commissioner identification number' : 'Foreign business Id') | tt }}
					</label>
					<div class="col-md-9">
						<div class="input-group">
							<input id="foreign_business_id"
							       type="text"
							       class="form-control"
							       [readonly]="readonly || organization_id"
							       formControlName="foreign_business_id">
						</div>
					</div>
				</div>

				<div class="form-group row">
					<label for="foreign_business_id_type" class="col-md-3 col-form-label text-input-label">
						{{ (isCommissioner ? 'Commissioner Id type' : 'Foreign business Id type') | tt }}
					</label>
					<div class="col-md-9">
						<ng-select type="text"
						           id="foreign_business_id_type"
						           [placeholder]="'Select type...' | tt"
						           formControlName="foreign_business_id_type"
						           [readonly]="readonly || organization_id">

							<ng-option *ngFor="let type of foreign_business_id_types" [value]="type.id">
								{{type.name | tt}}
							</ng-option>
						</ng-select>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="form-group row" *ngIf="!isCommissioner">
			<label for="employer_role_id" class="col-md-3 col-form-label text-input-label" tt>
				Employer Role
			</label>
			<div class="col-md-9">
				<ng-select type="text"
				           [placeholder]="'Select role' | tt"
				           id="employer_role_id"
				           formControlName="employer_role_id"
				           [readonly]="readonly || (organization_id && modification_forbidden)">
					<ng-option *ngFor="let role of employer_roles" [value]="role.id">
						{{role.name | tt}}
					</ng-option>
				</ng-select>
			</div>
		</div>
	</div>
</ng-container>
