<div class="modal-header">
	<h3>{{(simple_registration ? 'Contact details' : 'Register') | tt}}</h3>
	<button type="button" class="close" data-dismiss="modal" aria-hidden="true"
	        (click)="activeModal.dismiss()">&times;
	</button>
</div>

<div class="modal-body">
	<p class="lead" *ngIf="registerState == RegisterState.Input">
		{{(simple_registration ?
		'Please fill in the fields below to continue.' :
		'New customers, please create a new account by supplying the following information.') | tt}}

		<ng-container *ngIf="simple_registration && !simple_skip_email_check && !no_public_login">
			<span tt>Already have an account?</span>
			<a href="javascript:void(0)" (click)="back()" class="openLoginButton" tt>Login</a>
		</ng-container>
	</p>

	<shop-alert-message [alert]="alert"></shop-alert-message>

	<div *ngIf="fields?.rootForm">

		<shop-fields [shop_fields]="fields"
		             (fieldMessage)="isEmailFieldMessage($event) && onEmailReminder($event)">
		</shop-fields>
	</div>
</div>

<div class="modal-footer">
	<button class="btn closeButton" (click)="back()" tt>Close</button>

	<button type="submit"
	        class="btn btn-primary submit"
	        *ngIf="registerState != RegisterState.Message"
	        (click)="register()"
	        [disabled]="!fields?.rootForm?.valid || registerState == RegisterState.Processing">

		{{(simple_registration ? 'Continue' : 'Create account') | tt}}
	</button>
</div>
