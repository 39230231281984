<ng-container *ngIf="inputForm && type">
	<div class="form-group row" [formGroup]="inputForm">

		<label [for]="'contact_type' + type.contact_type_id" class="col-md-3 col-form-label text-input-label">
			{{ type.contact_type_name | tt }}
		</label>
		<div class="input-group col-md-9 modal-ng-select">
			<ng-select [items]="contactList"
			           [labelForId]="'contact_type' + type.contact_type_id"
			           (change)="changeContact($event)"
			           bindLabel="name"
			           bindValue="user_id"
			           [hideSelected]="true"
			           [searchable]="!readonly"
			           [clearable]="!readonly"
			           [loading]="findService.fetching"
			           [typeahead]="contactInput$"
			           [typeToSearchText]="'Type to search' | tt"
			           [notFoundText]="'No items found' | tt"
			           [loadingText]="'Loading' | tt"
			           formControlName="user_id">
			</ng-select>
		</div>
	</div>
</ng-container>
