<div class="modal-header">
	<h2 class="no-margin-bottom" *ngIf="!is_visitor; else visitor_header">
		{{(dataHolder.is_add_new_employee ? 'Add a new employee' : 'Edit employee') | tt}}
	</h2>
	<ng-template #visitor_header>
		<h2 class="no-margin-bottom" tt>Add a visitor information</h2>
	</ng-template>

	<button type="button" class="btn-modal-close close" aria-label="Close" (click)="activeModal.dismiss()">
		<cta-icon-close></cta-icon-close>
	</button>
</div>
<div class="modal-body">
	<cta-ta-add-employee-progressbar *ngIf="!standalone"
	                                 [currentStep]="1">
	</cta-ta-add-employee-progressbar>

	<ng-container *ngIf="!is_visitor; else visitor_body">
		<p [className]="card_error.length ? 'mb-2' : 'mb-5'" tt>
			Time and attendance personal info modal description
		</p>

		<div class="alert alert-danger" *ngIf="card_error.length">
				<span tt>{{card_error}}</span>
		</div>

		<h4>{{
			(dataHolder.is_add_new_employee ? 'Add employee personal information' : 'Edit employee personal information') | tt
			}}
		</h4>

		<cta-ta-form-personal-info [is_visitor]="is_visitor" [inputForm]="personalForm">
		</cta-ta-form-personal-info>
	</ng-container>

	<ng-template #visitor_body>
		<p class="mb-5" tt>
			Time and attendance visitor modal description
		</p>
		<cta-ta-form-personal-info [is_visitor]="is_visitor"
		                           [inputForm]="personalForm">
		</cta-ta-form-personal-info>
	</ng-template>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" tt>Close</button>

	<button type="button"
	        class="btn btn-primary-custom"
	        *ngIf="save_message || !standalone"
	        [disabled]="!is_valid"
	        (click)="confirm()">
		{{save_message + (standalone ? 'close' : 'continue') | titlecase | tt}}
	</button>
</div>
