import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { each, isNil } from 'lodash';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

import {
	AuthenticationService,
	CallService,
	Constants,
	Helpers,
	ParameterService,
	User,
	WebshopValidators
} from 'shared';

import {
	FieldFormsGeneratorService, ShopCustomField,
	ShopFieldInput,
	ShopFields, ShopParametersService,
	ShopTagGroupsService
} from '../../services';
import { AlertCode, AlertType } from '../../alerts';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
	selector    : 'shop-modify-modal',
	templateUrl : './modify.modal.html',
	styleUrls   : ['./modify.modal.scss']
})
export class ShopUserModifyModal implements OnInit {

	public alert: AlertType = null;

	public modifyForm: ShopFields = null;
	default_form_fields: ShopFieldInput[] = [];
	default_simple_registration_form_fields: ShopFieldInput[] = [];

	public no_public_login = false;
	public_tag_group_ids: string[] = [];
	ssn_check = false;
	is_ssn_shown = false;

	public is_modifying = false;
	public simple_registration = false;
	public custom_title = '';
	public hide_cancel_button = false;

	constructor(public activeModal: NgbActiveModal,
	            private parameterService: ParameterService,
	            private shopParametersService: ShopParametersService,
	            public auth: AuthenticationService,
	            public call: CallService,
	            private tags: ShopTagGroupsService,
	            private formGenerator: FieldFormsGeneratorService
	) {
		this.default_form_fields =
			[
				'email',
				'first_name',
				'last_name',
				'password',
				'passwordConfirm',
				'phone_number',
				'address_street',
				'address_postcode',
				'address_city',
				'date_of_birth',
				'account',
				'is_email_allowed',
				'usageterms'
			]
			.map(name => ({ name, id : name }));

		this.default_simple_registration_form_fields =
			['email', 'emailConfirm', 'first_name', 'last_name', 'phone_number', 'is_email_allowed', 'usageterms']
			.map(name => ({ name, id : name }));

		combineLatest([
			this.parameterService.getBooleanValue('webshop check ssn at subscription purchase', Constants.NM_EN_WEBSHOP),
			this.parameterService.getValue('publicly visible tag group ids', Constants.NM_EN_RESERVATIONS)
		]).subscribe((params) => {
			const [
				ssn_check,
				public_tag_group_ids
			] = params;

			this.ssn_check = !!ssn_check;
			this.public_tag_group_ids = Helpers.split(public_tag_group_ids);
		});
	}

	private _user: User = null;

	get user(): User
	{
		return this._user;
	}

	@Input() set user(value: User)
	{
		this._user = value;
		if (!value) return;

		combineLatest([
			this.shopParametersService.get(true),
			this.call.make('common/getfields', ['reservations 2', { name_as_key : true }])
		]).pipe(first()).subscribe(([params, fields]) => {
			const shop_fields = fields as { [key: string]: ShopCustomField };
			if (params.simple_registration_allowed && params.simple_registration_form_fields) {
				const simple_fields: ShopCustomField[] = params.simple_registration_form_fields.split(',').map(field => {
					return {
						data_type            : 'text',
						description          : 'string',
						field_name           : field,
						is_customer_editable : 1,
						is_required          : 1,
						ui_order             : null,
						validation_regex     : null,
					}
				});
				this.initCustomForm(simple_fields);
			} else {
				if (shop_fields) {
					this.initCustomForm(Object.values(shop_fields));
				} else {
					this.initForm();
				}
			}
		});
	}

	@Input() set customTitle(title: string)
	{
		this.custom_title = title;
	}

	@Input() set hideCancelButton(val: boolean)
	{
		this.hide_cancel_button = val;
	}

	ngOnInit(): void
	{
	}

	initForm(): void
	{
		const field_names: string[] = [];

		if (this.user.first_name && this.user.last_name) field_names.push('first_name', 'last_name');
		else field_names.push('name');

		if (this.is_ssn_shown) field_names.push('social_security_number');
		field_names.push('phone_number', 'address_street', 'address_postcode',
			'address_city', 'email', 'password', 'passwordConfirm');
		this.user.password = '********';
		this.user.passwordConfirm = '********';

		if (!this.ssn_check) field_names.push('date_of_birth');
		field_names.push('gender_id', 'notes_customer');

		const fields: ShopFieldInput[] = field_names.map(name => ({
			name,
			id    : name,
			value : this.user[name] || this.user.account[name]
		}));

		const tags: ShopFieldInput[] = this.tags.getTagFields(
			this.public_tag_group_ids,
			this.user && this.user.account.account_tags
		);

		this.modifyForm = this.formGenerator.generateForm(fields.concat(tags));
	}

	initCustomForm(fields: ShopCustomField[]): void
	{
		const shop_fields: ShopFieldInput[] = [];
		this.user.password = '********';
		this.user.passwordConfirm = '********';

		for (const field of fields) {
			const trimmed = field.field_name.trim();
			shop_fields.push({
				name        : trimmed,
				id          : trimmed,
				value       : this.user[trimmed] || this.user.account[trimmed],
				is_required : !isNil(field.is_required) ? !!+field.is_required : null,
				is_editable : !isNil(field.is_customer_editable) ? !!+field.is_customer_editable : null,
			});
		}

		const tags: ShopFieldInput[] = this.tags.getTagFields(
			this.public_tag_group_ids,
			this.user && this.user.account.account_tags
		);

		this.modifyForm = this.formGenerator.generateForm(shop_fields.concat(tags));

		const email_control = this.modifyForm.rootForm.get('email');
		this.addValidator(email_control, Validators.pattern(WebshopValidators.EMAIL));

		const phone_control = this.modifyForm.rootForm.get('phone_number');
		this.addValidator(phone_control, Validators.pattern(WebshopValidators.PHONE));

		const postal_code = this.modifyForm.rootForm.get('address_postcode');
		this.addValidator(postal_code, Validators.pattern(WebshopValidators.POSTAL_CODE));
	}

	private addValidator(control: AbstractControl, validator: ValidatorFn): void {
		if (control) {
			const currentValidators = control.validator ? [control.validator] : [];
			const newValidators = [...currentValidators, validator];
			control.setValidators(newValidators);
			control.updateValueAndValidity();
		}
	}

	public back(): void
	{
		this.activeModal.dismiss();
	}

	public modify(): void
	{
		const form_values: Record<string, any> = this.modifyForm.rootForm.getRawValue();
		const params: Record<string, any> = { user_id : this.user.user_id };

		each(this.modifyForm.fields, field => {
			if (field.id == 'date_of_birth' && field.value) {
				const value: moment.Moment = form_values[field.id];
				params['date_of_birth'] = value?.isValid() ? value.format(Helpers.serverDateFormat) : '';
			} else if (field.id == 'gender_id') {
				params['account'] = { gender_id : field.value };
			} else if (field.type == 'checkboxes') {
				const values = [];
				each(form_values[field.id], (checkbox, index) => {
					if (checkbox) values.push(field.possible_values[index]);
				});

				params[field.id] = values;
			} else params[field.id] = form_values[field.id];
		});

		this.is_modifying = true;
		this.call.make('reservation2/putR2Users', [params]).subscribe(
			() => {
				this.activeModal.close();
			},
			(error) => {
				this.is_modifying = false;
				this.alert = {
					code    : AlertCode.Error,
					title   : 'Error',
					message : error
				};
			}
		);
	}
}
