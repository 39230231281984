declare let window: { ng_debug_output: string, ng_debug_with_stack: boolean, EnkoraLogger: Logger };

window.ng_debug_output = window.ng_debug_output || '';
window.ng_debug_with_stack = window.ng_debug_with_stack || false;

interface LogExtra {
	traces?: any;
}

interface LogItem {
	extra: LogExtra;
	messages: any[];
}

export class Logger {
	private static log_storage: LogItem[] = [];
	private static _debug_enabled = false;
	private static _deep_log_enabled = false;

	static get deepDebugging(): boolean
	{
		return Logger._debug_enabled && Logger._deep_log_enabled;
	}

	public static outputLogs(): void
	{
		if (Logger.log_storage?.length) {
			for (const item of Logger.log_storage) {
				if (item) {
					if (item.extra) console.log(item.extra);
					console.log(...item.messages);
				}
			}
		}
	}

	public static logTrace(message: LogItem): void
	{
		console.groupCollapsed(message);
		console.log(message);
		console.trace(); // hidden in collapsed group
		console.groupEnd();
	}

	public static log(...messages: any[]): void
	{
		if (!window.ng_debug_output) return;

		let extra: LogExtra = null;
		if (window.ng_debug_with_stack) {
			extra = extra || {};
			extra.traces = this.stacktrace().replace('Error', 'Traces');
		}

		if (window.ng_debug_output == 'object' && Logger.log_storage) Logger.log_storage.push({ extra, messages });
		else if (window.ng_debug_output == 'console' && console && console.log) {
			if (extra) {
				console.groupCollapsed('Extra');
				if (extra.traces) console.log(extra.traces);
				console.groupEnd();
			}
			console.log(...messages);
		}
	}

	public static error(...messages: any[]): void
	{
		Logger.log(...messages);
	}

	private static stacktrace()
	{
		const err = new Error();
		return err.stack;
	}
}

window.EnkoraLogger = Logger;
