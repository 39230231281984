<div class="course-item">
	<a [href]="course_url" (click)="goToCourse(course_item, $event)" class="title">
		<h4 [attr.aria-label]="course_item?.title + ' ' + course_item?.location + ' '
			 + course_item?.date + ' ' + course_item?.time + ' ' + course_item?.description">
			{{ course_item?.title }}
		</h4>
	</a>
	<shop-alert-message *ngIf="alert.code"
	                    [alert]="alert">
	</shop-alert-message>
	<div class="body">
		<div class="price-container">
			<div class="tag">
				<ng-container [ngSwitch]="course_item?.accessibility">
					<ng-container *ngSwitchCase="accessibilityType.ReservableInFuture">
						<span class="opensTime">
							<span *ngIf="accessibilityLabel" class="label mb-1" [ngClass]="{
								'label-success': accessibilityLabel === accessibilityType.Free
									|| accessibilityLabel === accessibilityType.FreePlaces
									|| accessibilityLabel === accessibilityType.FreeWithCapacity
									|| accessibilityLabel === accessibilityType.ManyAvailable
									|| accessibilityLabel === accessibilityType.FewAvailable,
								'label-warning': accessibilityLabel === accessibilityType.FreeQueue
									|| accessibilityLabel === accessibilityType.QueueSpace,
								'label-important': accessibilityLabel === accessibilityType.Full
							}">
								<ng-container [ngSwitch]="accessibilityLabel">
									<span *ngSwitchCase="accessibilityType.Free">
										{{ course_item?.free }} <span tt>free</span>
									</span>
									<span *ngSwitchCase="accessibilityType.FreeWithCapacity">
										{{ course_item?.capacity }} <span tt>free</span>
									</span>
									<span *ngSwitchCase="accessibilityType.FreePlaces">
										{{ course_item?.free }} <span tt>free places</span>
									</span>
									<span *ngSwitchCase="accessibilityType.ManyAvailable" tt>Many places available</span>
									<span *ngSwitchCase="accessibilityType.FewAvailable" tt>A few places available</span>
									<span *ngSwitchCase="accessibilityType.FreeQueue">
										{{ course_item?.free_queue }} <span tt>queue places</span>
									</span>
									<span *ngSwitchCase="accessibilityType.QueueSpace" tt>Queue space</span>
									<span *ngSwitchCase="accessibilityType.Full" tt>Full</span>
								</ng-container>
							</span>
							<span class="label label-important">
								<span tt>Reservable</span> {{ course_item?.public_reservation_start_formatted }}
							</span><br>
						</span>
						<span *ngIf="!disable_reservations_countdown_timer
							&& time_until_start_duration
							&& time_until_start_duration?.days() <= 0
							&& time_until_start_duration?.hours() < 1"
						      class="future-time label label-info">
									<span class="future-time-span">
										<ng-container *ngIf="time_until_start_duration?.minutes() > 0">
											{{ time_until_start_duration?.minutes() }}min
										</ng-container>
										{{ time_until_start_duration?.seconds() }}s
									</span>
						</span>
					</ng-container>

					<ng-container *ngIf="!hide_label">
						<ng-container *ngSwitchCase="accessibilityType.NotReservable">
							<span class="label label-important" tt>Not reservable</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.Free">
							<span class="label label-success">{{ course_item?.free }} <span tt>free</span></span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.FreeWithCapacity">
							<span class="label label-success">
								{{ course_item?.capacity }} <span tt>free</span>
							</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.FreePlaces">
							<span class="label label-success free-places">
								{{ course_item?.free }} <span tt>free places</span>
							</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.ManyAvailable">
							<span class="label label-success" tt>Many places available</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.FewAvailable">
							<span class="label label-success" tt>A few places available</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.FreeQueue">
							<span class="label label-warning">
								{{ course_item?.free_queue }} <span tt>queue places</span>
							</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.QueueSpace">
							<span class="label label-warning" tt>Queue space</span>
						</ng-container>
						<ng-container *ngSwitchCase="accessibilityType.Full">
							<span class="label label-important" tt>Full</span>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
			<ng-container *ngIf="course_item?.price">
				<div *ngIf="course_item?.original_price > course_item?.price" class="originalPrice">
					{{ course_item?.original_price | emptyor:'0' | fastcents }}
				</div>
				<div class="price" tabindex="0">
					<ng-container *ngIf="course_item?.price_range[0] === course_item?.price_range[1]; else priceRange">
						{{ course_item?.price | emptyor:'0' | fastcents }}
					</ng-container>
					<ng-template #priceRange>
						{{ course_item?.price_range[0] | emptyor:'0' | fastcents:false }} -
						{{ course_item?.price_range[1] | emptyor:'0' | fastcents }}
					</ng-template>
				</div>
				<div *ngIf="!text_only" class="button buy-tooltip">
					<button *ngIf="course_item?.accessibility !== accessibilityType.Full
								&& course_item?.accessibility !== accessibilityType.NotReservable
								&& course_item?.accessibility !== accessibilityType.ReservableInFuture
								&& course_item?.accessibility !== accessibilityType.FreeQueue
								&& course_item?.accessibility !== accessibilityType.QueueSpace"
					        [disabled]="disable_buy_button"
					        (click)="buy()"
					        class="btn btn-block btn-success buy">
						<i class="fa fa-shopping-cart"></i>
						<span class="ps-1" tt>Buy</span>
					</button>
					<button *ngIf="course_item?.accessibility === accessibilityType.FreeQueue
								|| course_item?.accessibility === accessibilityType.QueueSpace"
					        [disabled]="disable_buy_button"
					        (click)="buy()"
					        class="btn btn-block btn-warning buy">
						<i class="fa fa-shopping-cart"></i>
						<span class="ps-1" tt>Queue</span>
					</button>
					<span *ngIf="disable_buy_button" class="buy-tooltip-text" tt>Complete the previous payment before buying again</span>
				</div>
			</ng-container>
		</div>
		<div class="location-container">
			<div class="location">
				<i class="fa fa-home"></i>
				{{ course_item?.location }}
				<span *ngFor="let tag of course_item.tags" class="badge bg-success ms-1">{{ tag.name }}</span>
			</div>
			<div class="date">
				<i class="fa fa-calendar"></i>
				<span tabindex="0" class="timetable" (click)="openTimetableModal()">
					<span role="button" [attr.aria-label]="'Show schedule' | tt"></span>
					<span aria-hidden="true">
						<ng-container [ngSwitch]="date_presentation">
							<ng-container *ngSwitchCase="presentation.SAME_DAY_RECURRING">
								{{ course_item?.date }}
							</ng-container>
							<ng-container *ngSwitchCase="presentation.MIXED_DAYS_SAME_TIME">
								{{ course_item?.date }}
							</ng-container>
							<ng-container *ngSwitchCase="presentation.MIXED_DAYS">
								{{ course_item?.date | slice: 0:13 }} (<span tt>Show schedule</span>)
							</ng-container>
							<ng-container *ngSwitchCase="presentation.ONE_DAY">
								<span class="ps-1" tt>{{ course_item?.start | date:'EEEE'}}</span>
								{{ course_item?.date | slice:0:5 }}
							</ng-container>
						</ng-container>
					</span>
				</span>
			</div>
			<div *ngIf="date_presentation !== presentation.MIXED_DAYS" class="time">
				<i class="fa fa-clock-o"></i>
				<ng-container *ngIf="date_presentation === presentation.SAME_DAY_RECURRING">
					<span class="ps-1" tt>{{ course_item?.start | date:'EEEE'}}</span>
				</ng-container>
				{{ course_item?.time }}
			</div>
		</div>
		<div *ngIf="course_item?.reservations && !text_only" class="reservation-details">
			<table class="table">
				<thead>
				<tr>
					<th tt>Status</th>
					<th tt>Participant's name</th>
					<th tt>Company</th>
					<th tt>ID</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
					<tr *ngFor="let reservation of course_item?.reservations" [ngClass]="{
							'success': reservation.reservation_status_id === constants.RES_RESERVED,
							'danger': reservation.reservation_status_id === constants.RES_UNCONFIRMED,
							'warning': (reservation.reservation_status_id === constants.RES_UNCONFIRMED_QUEUE
										  || reservation.reservation_status_id === constants.RES_QUEUE)
						}">
						<td>
							<b *ngIf="reservation.reservation_status_id === constants.RES_RESERVED" tt>Reserved</b>
							<b *ngIf="reservation.reservation_status_id === constants.RES_UNCONFIRMED" tt>Unconfirmed</b>
							<b *ngIf="reservation.reservation_status_id === constants.RES_UNCONFIRMED_QUEUE" tt>Unconfirmed Queue</b>
							<b *ngIf="reservation.reservation_status_id === constants.RES_QUEUE" tt>Queue</b>
						</td>
						<td>{{ reservation.reservation_account_name}}</td>
						<td></td>
						<td>{{ reservation.reservation_id }}</td>
						<td>
							<ng-container *ngIf="!cancel_confirm && isCancellationAllowed(reservation)">
								<button
									(click)="toggleCancelConfirm()" class="btn btn-mini btn-danger cancelReservation" tt>
									Cancel
								</button>
							</ng-container>
							<ng-container *ngIf="cancel_confirm">
								<div tabindex="0" tt>Cancel the prereservation?</div>
								<div>
									<button
										(click)="toggleCancelConfirm()" class="btn btn-mini btn-primary cancelReservationConfirm me-1" tt>
										No
									</button>
									<button
										(click)="cancelReservation(reservation)" class="btn btn-mini btn-danger cancelReservationConfirm" tt>
										Yes
									</button>
								</div>
							</ng-container>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p class="description">
			<span>{{ course_item.description }}</span>
		</p>
		<p *ngIf="append_long_description" class="long-description">
			<span>{{ course_item.description_long }}</span>
		</p>
		<div class="description-long" *ngIf="description_long && !text_only">
			(<span (click)="showLongDescription()">{{ description_long_title }}</span>)
		</div>
	</div>
</div>
