<div class="modal-header">
	<span class="aria-focus" tabindex="0"></span>
	<h3 tabindex="0" tt>Shopping cart</h3>
	<button type="button" class="close" data-dismiss="modal"
	        [attr.aria-label]="'Close' | tt"
	        (click)="activeModal.dismiss()">&times;
	</button>
</div>

<div class="modal-body cart-modal">

	<div *appLoading="loading">
		<ng-container *ngIf="cart && !show_cancel_confirm && !show_empty_cart_confirm">
			<ng-container *ngIf="!cart.items || !cart.items.length; else has_items">
				<p class="query" tabindex="0" tt>No items in the cart</p>
			</ng-container>
			<ng-template #has_items>
				<table id="cartItems" class="table table-striped">
					<thead>
					<tr>
						<th tabindex="0" tt>Product</th>
						<th tabindex="0" tt>VAT</th>
						<th tabindex="0" tt>Price</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let item of cart.items">
						<td tabindex="0">{{ item.title }}</td>
						<td tabindex="0">{{ item.vat_amount | emptyor:'0' | fastcents }}</td>
						<td tabindex="0">{{ item.price | emptyor:'0' | fastcents }}</td>

						<td>
							<button class="btn btn-danger btn-mini remove"
							        (click)="deleteItemConfirm(item)" tt>
								Delete
							</button>
						</td>
					</tr>
					</tbody>
				</table>

				<dl class="dl-horizontal pull-right priceItems">
					<dt tabindex="0" tt>Non-VAT price</dt>
					<dd tabindex="0" style="text-align: right;">{{ cart.total_no_vat | emptyor:'0' | fastcents }}</dd>

					<dt tabindex="0" tt>Total VAT</dt>
					<dd tabindex="0" style="text-align: right;">{{ cart.total_vat | emptyor:'0' | fastcents }}</dd>

					<dt tabindex="0" style="font-size: 1.5em" tt>Total price</dt>
					<dd tabindex="0" style="font-size: 1.5em;">{{ cart.total_price | emptyor:'0' | fastcents }}</dd>
				</dl>

				<div class="clearfix"></div>
				<!-- Giftcarts functional -->
				<ng-container
					*ngIf="shop_params?.balance_payments_enabled && shop_params?.printed_giftcard_payments_enabled">
					<shop-giftcart (balanceAdded)="updateUserBalance()">
					</shop-giftcart>
				</ng-container>
				<!-- Balance payments -->
				<div class="pull-right well"
				     *ngIf="allow_balance && balance && cart.total_price"
				     style="max-width: 350px;">
					<h4 tabindex="0" tt>Balance</h4>
					<p>
						<span tabindex="0" tt>Your account balance is</span>
						<span tabindex="0" class="label label-success ms-1">{{ balance | emptyor:'0' | fastcents }}</span>
					</p>

					<p tabindex="0" tt>Use the slider to select amount of saldo you want to use for payment.</p>

					<div class="row-fluid">
						<div class="span2" style="text-align: center;">
							<b tabindex="0" tt>Balance</b><br>
							<span tabindex="0" class="label label-default" id="payByBalance">
								{{ pay_by_balance | emptyor:'0' | fastcents}}
							</span>
						</div>

						<div class="span8">
							<input id="balancePayAmount"
							       type="range"
							       min="0"
							       [max]="(balance > cart.total_price ? cart.total_price : balance)/100"
							       step="0.5"
							       [(ngModel)]="pay_by_balance_fraction"
							       (ngModelChange)="updateBalancePayment()"
							       style="width: 100%;">
						</div>

						<div class="span2" style="text-align: center;">
							<b tabindex="0" tt>EUR</b><br>
							<span tabindex="0" class="label label-default" id="payByCash">
								{{ pay_by_cash | emptyor:'0' | fastcents}}
							</span>
						</div>
					</div>
				</div>
				<div class="clearfix"></div>

				<label class="pull-left terms_ad_conditions" *ngIf="shop_params?.terms_and_condition_links?.length">
					<div class="pb-2">
						<input id="usageTermsCheckbox" type="checkbox" [(ngModel)]="usage_terms">
						<span tabindex="0" tt>Accept usage terms</span>
					</div>
					<div class="accept-terms ps-4">
						(<a *ngFor="let terms of shop_params.terms_and_condition_links; let i=index" [href]="terms.link"
						    target="_blank">
						<span *ngIf="i!=0">, </span>{{ terms.title }}
					</a>)
					</div>
				</label>

				<div class="clearfix"></div>

				<div class="alerts"></div>

				<div style="display: none;" id="selectPaymentMethod">
					<div id="paymentFormContainer" [innerHTML]="payment_form | safeHtml"></div>
				</div>

				<div class="clearfix"></div>
			</ng-template>
		</ng-container>
		<ng-container *ngIf="show_cancel_confirm">
			<span class="query" tabindex="0" tt>Delete item from the cart?</span>
			<div class="confirm-dialog-buttons pt-2">
				<button [disabled]="is_cancelling" (click)="toggleCancelConfirm()"
				        type="submit" class="btn btn-info" tt>
					No
				</button>
				<button [disabled]="is_cancelling" (click)="deleteItem(selected_item_to_cancel)"
				        type="submit" class="ms-2 btn btn-danger" tt>
					Yes
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="show_empty_cart_confirm">
			<span class="query" tabindex="0" tt>Empty the cart?</span>
			<div class="confirm-dialog-buttons pt-2">
				<button [disabled]="loading_empty_cart" (click)="toggleEmptyCartConfirm()"
				        type="submit" class="btn btn-info" tt>
					No
				</button>
				<button [disabled]="loading_empty_cart" (click)="emptyCart()"
				        type="submit" class="ms-2 btn btn-danger" tt>
					Yes
				</button>
			</div>
		</ng-container>
	</div>
	<shop-alert-message [alert]="alert"></shop-alert-message>
</div>

<div *ngIf="!show_empty_cart_confirm && !show_cancel_confirm" class="modal-footer">
	<button class="btn btn-tiny btn-danger emptyCart"
	        *ngIf="cart?.items && cart?.items.length"
	        [disabled]="loading_empty_cart"
	        (click)="toggleEmptyCartConfirm()">
		<span *ngIf="!loading_empty_cart" tt>Empty cart</span>
		<span *ngIf="loading_empty_cart" tt>Loading</span>
	</button>

	<button class="btn btn-tiny continueShopping"
	        *ngIf="!hideContinueShoppingButton()"
	        (click)="activeModal.dismiss()"
	        [disabled]="is_payment_started"
	        tt>
		Continue Shopping
	</button>

	<ng-container *ngIf="cart?.items && cart?.items.length">
		<ng-container *ngIf="!auth.isAuthenticated(); else authed">
			<button class="btn btn-tiny btn-primary loginAndPay"
			        (click)="openLogin()" tt>
				Login and pay
			</button>
		</ng-container>
		<ng-template #authed>
			<button *ngIf="shop_params?.invoicing_enabled"
			        class="btn btn-tiny btn-primary invoiceCart"
			        [disabled]="is_payment_started"
			        tabindex="0"
			        tt>
				Invoice me
			</button>
			<button *ngIf="shop_params?.get_order_id_instead_of_payment_enabled"
			        class="btn btn-tiny btn-primary payLaterCart"
			        [disabled]="is_payment_started"
			        tabindex="0"
			        tt>
				Order (pay later)
			</button>
			<ng-container *ngIf="payment_types.length > 0">
				<ng-container *ngIf="payment_types.length == 1; else multiple_payment_types">
					<button class="btn btn-tiny btn-primary buyCart"
					        [disabled]="is_payment_started"
					        (click)="buyProducts(payment_types[0].payment_type_id)"
					        tt>
						Pay selected
					</button>
				</ng-container>
			</ng-container>

			<ng-template #multiple_payment_types>
				<ng-container *ngIf="payment_types.length < 5; else multiple_payment_types_dropdown">
					<div class="btn-group mt-2">
						<ng-container *ngFor="let payment_type of payment_types">
							<button class="btn btn-tiny btn-primary buyCart"
							        [disabled]="is_payment_started || payment_type.is_disabled"
							        (click)="buyProducts(payment_type.payment_type_id)"
							        tt>
								{{ payment_type.name}}
							</button>
						</ng-container>
					</div>
				</ng-container>
			</ng-template>

			<ng-template #multiple_payment_types_dropdown>
				<ng-container>
					<!--TODO-->
				</ng-container>
			</ng-template>

		</ng-template>
	</ng-container>
</div>
