import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GuidService } from '../../../../../shared';

@Component({
	selector    : 'cta-order-form-product-details',
	templateUrl : './product-details.form.html',
	styleUrls   : ['./product-details.form.scss']
})
export class ProductDetailsForm implements OnInit {

	@Input() inputForm: FormGroup;
	public id;

	constructor(guid: GuidService)
	{
		this.id = guid.Next;
	}

	ngOnInit(): void
	{
	}

	resetForm()
	{
	}
}
